import React from 'react';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Tarifa/Filter';
import { ModalAgregarTarifa } from '../../../components/specific/Tarifa/Modales/agregarTarifa';
import SubirTarifa from '../../../components/specific/Tarifa/Modales/SubirTarifa';
import TableTarifas from '../../../components/specific/Tarifa/Table';
import { store } from '../../../store';
import { setSelTarifaInit } from '../../../actions';
import PageHeading from '../../../components/global/PageHeading';
import { ButtonTable } from '../../../components/global/Styles/buttons';
import ListaError from '../../../components/specific/Tarifa/Modales/ListaError';
import EliminarTarifa from '../../../components/specific/Tarifa/Modales/EliminarTarifa';
import { TitlesTables } from '../../../components/global/Styles/titles';

console.timeEnd();
const ViewTarifa = (props) => {
  const { modalAgregarTarifa, setModalModalAgregarTarifa, uploadFile, setUploadFile, listaError, setListaError, modalEliminarTarifa, setModalEliminarTarifa } =
    props.modal;
  const { loadingTables, getTarifaTodas } = props.actions;
  const { getTarifa, deleteTarifaContrato } = props.endPoint;
  const { getRoles } = props;

  const getTarifasExcel = () => {
    window.open('./formats/FormatoTarifas.xlsx');
  };

  const handleClick = () => {
    store.dispatch(setSelTarifaInit());
    setModalModalAgregarTarifa(true);
  };

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'bg-gray-500 p-2 rounded-md text-white font-bold text-sm ',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex">
            <TitlesTables>TARIFAS</TitlesTables>
            <div className="flex-auto"></div>

            <ButtonTable onClick={handleClick}>Agregar</ButtonTable>
            <ButtonTable
              className="mx-2"
              onClick={() => setUploadFile(true)}>
              Importar
            </ButtonTable>
            <ButtonTable
              className="mx-2"
              onClick={() => setModalEliminarTarifa(true)}>
              Eliminar
            </ButtonTable>
            <ButtonTable
              className="mx-2"
              onClick={() => getTarifaTodas()}>
              Todas
            </ButtonTable>
            <ButtonTable
              onClick={() => getTarifasExcel()}
              className="mx-2">
              Formato
            </ButtonTable>
          </div>

          <Filter
            setModalModalAgregarTarifa={setModalModalAgregarTarifa}
            setUploadFile={setUploadFile}
            endPoint={props.endPoint}
            data={props.data}
          />

          {loadingTables && <LoadingTables />}
          <TableTarifas
            actions={props.actions}
            data={{ loadingTables }}
          />
        </div>
      </div>

      {modalAgregarTarifa ? (
        <ModalAgregarTarifa
          endPoint={props.endPoint}
          setModalModalAgregarTarifa={setModalModalAgregarTarifa}
          getRoles={getRoles}
          data={props.data}
        />
      ) : null}
      {uploadFile ? (
        <SubirTarifa
          actions={{ setUploadFile, setListaError, getTarifa }}
          data={props.data}
        />
      ) : null}
      {listaError ? (
        <ListaError
          setListaError={setListaError}
          data={props.data}
        />
      ) : null}
      {modalEliminarTarifa ? (
        <EliminarTarifa
          actions={{ setModalEliminarTarifa, deleteTarifaContrato }}
          data={props.data}
        />
      ) : null}
    </div>
  );
};

export default ViewTarifa;
