import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { ReactComponent as IconOn } from '../../../assets/svg/switch-on.svg';
import { ReactComponent as IconOff } from '../../../assets/svg/switch-off.svg';

const TbodyTableUnidadNegocio = (props) => {
  const infoUnidadNegocio = props.UnidadNegocio.UnidadNegocio;
  const { handleDeleteUnidadNegocio, handleEdit } = props.actions;

  return (
    <tbody className="text-gray-700">
      {infoUnidadNegocio?.map((obj, index) => (
        <tr key={index}>
          <td className="w-1/3 px-4 py-3 text-left">{obj.NEGOCIO_ID}</td>
          <td className="w-1/3 px-4 py-3 text-left">{obj.NEGOCIO_NOMBRE}</td>
          <td className="w-1/3 px-4 py-3 text-left">
            {obj.NEGOCIO_ESTADO === 1 ? (
              <IconOn
                className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                // onClick={() => handleEstado(contrato)}
              />
            ) : (
              <IconOff
                className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                //  onClick={() => handleEstado(contrato)}
              />
            )}
          </td>
          <td className="flex space-x-2 px-4 py-3 text-left">
            <Edit
              className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleEdit(obj)}
            />
            <Trash
              className="hover:fill-blue-500 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleDeleteUnidadNegocio(obj.NEGOCIO_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { UnidadNegocio: state.UnidadNegocio };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableUnidadNegocio);
