import React from 'react';
import { connect } from 'react-redux';
import { ButtonTable } from '../../../global/Styles/buttons';
import PageHeading from '../../../../components/global/PageHeading';

const ListaError = (props) => {
  const errors = props.ImportarTarifa.errors;
  console.log('errors: ', errors);
  const { setListaError } = props;

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>

      <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
        <div className="">
          <div className="flex-auto justify-center p-5 text-left">
            <PageHeading title="Resultado">
              <ButtonTable onClick={() => setListaError(false)}>Cerrar</ButtonTable>
            </PageHeading>

            <div className="overflow-x-auto rounded border-b border-gray-200 shadow">
              <div>
                <table
                  cellPadding={5}
                  className="min-w-full bg-white">
                  <thead className="bg-gray-800 text-white">
                    <tr>
                      <th className="font-sans text-xs">Tarifa Consecutivo </th>
                      <th className="font-sans text-xs">Excel Fila </th>
                      <th className="font-sans text-xs">Error</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    {errors.map((obj, index) => (
                      <tr>
                        <td className="font-sans text-xs">{obj.fila.TARIFA_CONSECUTIVO}</td>
                        <td className="font-sans text-xs">{obj.fila.EXCEL_FILA}</td>
                        <td className="font-sans text-xs">
                          <ul>
                            {obj.errores.map((objError, indexError) => (
                              <li>{objError}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr></hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    ImportarTarifa: state.ImportarTarifa,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ListaError);
