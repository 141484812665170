import React, { useState, useEffect } from 'react';

import ViewTercero from './ViewTercero';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setSelTercero, setSelTerceroInit, setTercero, setTipoDocumento, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';
const Tercero = (props) => {
  const { get, post, put, DELETE } = useApiClient();
  const [isModalAgregarTerceroVisible, setIsModalAgregarTerceroVisible] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    props.tercero.pagination === null || props.tercero.pagination === undefined ? 1 : props.tercero.pagination.currentPage
  );
  const [filters, setFilters] = useState('');

  const handleNextPage = (page) => {
    const totalPages = props.tercero.pagination.lastPage;
    if (currentPage === totalPages) return;
    setCurrentPage(page ? page : currentPage + 1);
    getTerceros(page ? page : currentPage + 1);
  };
  const handlePreviusPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
    getTerceros(currentPage - 1);
  };

  const handleSearch = (form) => {
    const filter = form.SRC === undefined ? '' : `&SRC=${encodeURIComponent(form.SRC)}`;
    getTerceros(1, filter);
  };

  const handleClearClick = () => {
    setFilters('');
  };
  const getTerceros = async (pageInd = currentPage, filtersParam = filters) => {
    setLoadingTables(true);
    if (filters !== filtersParam) setFilters(filtersParam);
    try {
      const result = await get(`/tercero?page=${pageInd}${filtersParam}`);
      store.dispatch(setTercero(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const handleEdit = (obj) => {
    store.dispatch(setSelTercero(obj));
    handleShowAddNewModal();
  };
  // const getTipoDocumento = async () => {
  //   try {
  //     const result = await get(`/documentoTipo`);
  //     store.dispatch(setTipoDocumento(result));
  //   } catch (error) {}
  // };
  const envioContraseña = async (id) => {
    try {
      const result = await get(`/tercero/${id}?password=1`);
      OkToast({ message: result.message });
    } catch (error) {}
  };
  const deleteTercero = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/tercero/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Usuario eliminado!' });
        getTerceros();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };
  function handleDelete(rowID) {
    toast((t) => (
      <span>
        <b>Are you sure to delete task?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteTercero(rowID);
          }}>
          Yes
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }

  const handleShowAddNewModal = (show = true) => {
    if (!show) store.dispatch(setSelTerceroInit());
    setIsModalAgregarTerceroVisible(show);
  };

  useEffect(() => {
    getTerceros();
    // getTipoDocumento();
  }, []);
  return (
    <ViewTercero
      modal={{
        isModalAgregarTerceroVisible,
      }}
      endPoint={{ envioContraseña, getTerceros }}
      data={{ setFilters, auth: props.auth, loadingTables, tercero: props.tercero }}
      pager={{ handleNextPage, handlePreviusPage }}
      actions={{ handleEdit, handleDelete, loadingTables, handleSearch, handleClearClick, handleShowAddNewModal }}
    />
  );
};
const mapStateToProps = function (state) {
  return {
    tercero: state.tercero,
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Tercero);
