import React from 'react';
import { connect } from 'react-redux';

import { fixOnlyDateForTable } from '../../../../utils/format';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

const TbodyTableZona = (props) => {
  const info = props.zona.zona;
  const { handleEdit, handleDelete } = props.actions;

  return (
    <tbody className="text-gray-700">
      {info.map((obj, index) => (
        <tr key={index}>
          <td className="px-1 text-left">{obj.ZONA_ID}</td>
          <td className="px-1 text-left">{obj.ZONA_DESCRIPCION}</td>
          <td className="px-1 text-left">{obj.ZONA_PREFIJO}</td>
          <td className="px-1 text-left">{obj.ZONA_CONSECUTIVO}</td>
          <td className="px-1 text-left">{obj.TERCERO_NOMBRE_BUSQUEDA}</td>
          <td className="px-1 text-left">{obj.CONTRATO_NUMERO}</td>
          <td className="px-1 text-left hover:text-blue-500">{fixOnlyDateForTable(obj.UPDATED_AT)}</td>

          <td className=" ">
            <PencilIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleEdit(obj)}
            />
          </td>
          <td className=" ">
            <TrashIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleDelete(obj.ZONA_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { zona: state.zona };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableZona);
