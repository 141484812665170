const initialState = {
  isData: false,
  tarifa: [],
  selTarifa: null,
  pagination: null,
};

const initialTarifaState = {
  selTarifa: null,
};

export const tarifa = (state = initialState, action) => {
  switch (action.type) {
    case 'setTarifa': {
      if (action.value.status === 200) {
        const stateData = {
          tarifa: action.value.data,
          isData: true,
          pagination: action.value.pagination,
        };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setTarifaInit': {
      return initialState;
    }
    case 'setSelTarifa': {
      const obj = {
        ...action.value,
      };

      const stateData = { selTarifa: obj };

      return { ...state, ...stateData };
    }
    case 'setSelTarifaInit': {
      return { ...state, ...initialTarifaState };
    }

    default:
      //
      return state;
  }
};
