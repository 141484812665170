import React from 'react';

import { CheckParameter } from '../../../../utils/security';

const ServiciosThead = (props) => {
  return (
    <thead className="  sticky top-0 z-10 bg-gray-100  text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className="w-2 "></th>
        <th
          scope="col"
          className=" whitespace-nowrap  text-left ">
          C. Int
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap  px-4 text-left">
          C. Cli
        </th>
        <th
          scope="col"
          className="whitespace-nowrap   text-left">
          C. Costo
        </th>
        <th
          scope="col"
          className="  text-left">
          Tipo de servicio
        </th>
        <th
          scope="col"
          className="   text-left">
          Tipo de vehículo
        </th>
        <th
          scope="col"
          className="   text-left">
          Ruta
        </th>
        {/* <th
          scope="col"
          className="   text-left">
          Destino
        </th> */}
        <th
          scope="col"
          className="   text-left">
          Fecha servicio
        </th>
        <th
          scope="col"
          className="  whitespace-nowrap  px-4 text-left">
          Fecha Reg.
        </th>
        <th
          scope="col"
          className="  whitespace-nowrap  text-left">
          Cont. Zona
        </th>
        {CheckParameter('PARAMETRO_ID', 2) && (
          <th
            scope="col"
            className="   text-left">
            Aprobador
          </th>
        )}
        <th
          scope="col"
          className="   text-center">
          Nota
        </th>
        <th
          scope="col"
          className="   text-center"></th>
        <th
          scope="col"
          className="   text-center"></th>
      </tr>
    </thead>
  );
};

//export default ViewProjects;

export default ServiciosThead;
