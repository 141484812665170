const initialState = {
  isData: false,
  data: [],
  selBono: null,
  pagination: null,
};

const initialBonoState = {
  selBono: null,
};

export const Bono = (state = initialState, action) => {
  switch (action.type) {
    case 'setBono': {
      if (action.value.status === 200) {
        const stateData = {
          data: action.value.data,
          isData: true,
          pagination: action.value.pagination,
        };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setBonoInit': {
      return initialState;
    }
    case 'setSelBono': {
      const obj = {
        ...action.value,
      };

      const stateData = { selBono: obj };

      return { ...state, ...stateData };
    }
    case 'setSelBonoInit': {
      return { ...state, ...initialBonoState };
    }

    default:
      //
      return state;
  }
};
