import React from 'react';
import TbodyTipoVehiculo from './tbody';
import TheadTipoVehiculo from './thead';
const TableTipoVehiculo = (props) => {
  const { loadingTables } = props.data;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTipoVehiculo />
          <TbodyTipoVehiculo actions={props.actions} />
        </table>
      )}
    </div>
  );
};
export default TableTipoVehiculo;
