import React, { useEffect, useState } from 'react';

import { TableBono } from './TableBono';
import ModalAsignarBono from '../ModalAsignarBono';

import { CustomToast, ErrorToast, OkToast } from '../../../Toast';
import { validateSuccess2xx, useApiClient } from '../../../../../clients/apiAuth';
import toast from 'react-hot-toast';

export const PanelVehiculoBonos = ({ handleEditBonoAsignado, data }) => {
  const [isModalAsignarBonoVisible, setIsModalAsignarBonoVisible] = React.useState(false);

  const { selSolicitudVehiculoTipo } = data;

  const { get, post, put, DELETE } = useApiClient();

  const [terceroBonos, setTerceroBonos] = useState([]);

  const handleShowBonoModal = (isVisible = true) => {
    setIsModalAsignarBonoVisible(isVisible);
  };

  const getTerceroBonos = async () => {
    try {
      const result = await get(`/solicitudBono?SOLICITUD_VEHICULO_TIPO_ID=${selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID}`);

      if (!validateSuccess2xx(result)) return; // CustomToast({ result });

      setTerceroBonos(result.data);
      // store.dispatch(setAsignarSelBonoInit());
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  useEffect(() => {
    getTerceroBonos();
  }, [data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID]);

  const createBono = async (form) => {
    try {
      const result = await post(
        '/solicitudBono',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      OkToast({ result, message: 'Asignación de bono realizada!' });
      handleShowBonoModal(false);
      getTerceroBonos();
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const updateBono = async (id, form) => {
    try {
      const result = await put(
        `/solicitudBono/${id}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      OkToast({ result, message: 'Asignación de bono actualizada!' });
      handleShowBonoModal(false);
      getTerceroBonos();
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const deleteBonoAsignado = async (form) => {
    try {
      const result = await DELETE(`/solicitudBono/${form.SOLICITUD_BONO_ID}`);
      if (!validateSuccess2xx(result)) {
        return CustomToast({ result });
      }

      OkToast({ result, message: 'Item eliminado!' });
      getTerceroBonos();
      // store.dispatch(setAsignarSelBonoInit());
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  function handleDeleteBonoAsignado(form) {
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el registro?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteBonoAsignado(form);
          }}>
          Si
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }

  return (
    <div>
      <TableBono
        data={terceroBonos}
        modals={{ handleShowBonoModal }}
        actions={{ handleEditBonoAsignado, handleDeleteBonoAsignado }}
      />
      {isModalAsignarBonoVisible ? (
        <ModalAsignarBono
          modals={{ handleShowBonoModal }}
          data={{
            vehiculoUsuarioOptions: data.vehiculoUsuarioOptions,
            selSolicitudVehiculoTipo: data.selSolicitudVehiculoTipo,
          }}
          solicitud={data.selSolicitud}
          endPoint={{ createBono, updateBono }}
        />
      ) : null}
    </div>
  );
};
