import React, { useEffect, useState } from 'react';
import { hideLoadingAnimation, setSelContratoInit, showLoadingAnimation } from '../../../../actions';
import { useApiClient } from '../../../../clients/apiAuth';
import { store } from '../../../../store';

import { Field, Form } from 'react-final-form';

import FormStateToRedux from '../../RFF/FormStateToRedux';
import { inputsFilterTrue } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const Filter = (props) => {
  const { get } = useApiClient();

  const [switchCurrent, setSwitchCurrent] = useState(true);

  const { getClientes } = props.endPoint;
  const { clienteOptions, setTerceroFilter } = props.data;
  const { setModalAgregarContrato } = props;

  const handleSubmmit = (form) => {};

  const terceroChange = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_ID'];
    field.change(args[0].target.value);

    state.formState.submitFailed = true;

    //CAMBIA EL FILTRO.
    setTerceroFilter(args[0].target.value);
  };

  const handleClick = () => {
    store.dispatch(setSelContratoInit());
    setModalAgregarContrato(true);
  };

  useEffect(() => {
    getClientes();
  }, []);

  const filterColors = (inputValue) => {
    return clienteOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  const getCubs = async (inputValue, callback) => {
    try {
      const response = await get(`/cub?search=${inputValue}`);

      const lst = response.data.map((row) => ({
        label: `${row.search}`,
        value: row.cub_id,
      }));

      callback(() => lst);
    } catch (error) {}
  };
  return (
    <Form
      onSubmit={handleSubmmit}
      initialValues={{}}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ terceroChange }}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <FormStateToRedux form="frmContratoFilters" />

          <div className="sm:flex-row my-2 flex w-1/2 flex-col justify-between">
            <p className=" text-sm text-gray-800"></p>
            {/* {clienteOptions.length > 0 ? (
              <Select
                //defaultValue={obj.find((i) => i.value === defaultRol?.value)}
                onChange={form.mutators.setROL_ID}
                options={clienteOptions}
                name="selectRol"
                className=""
              />
            ) : (
              <div className=" flex justify-center items-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            )} */}

            <AsyncSelect
              cacheOptions
              loadOptions={getCubs}
              defaultOptions
            />

            <Field name="ROL_ID">
              {({ input, meta }) => (
                <>
                  <input
                    {...input}
                    type="hidden"
                  />
                  {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                </>
              )}
            </Field>
          </div>
        </form>
      )}
    </Form>
  );
};
export default Filter;
