import { createStore, compose, combineReducers } from 'redux';
import { auth } from '../reducers/auth';
import { roles } from '../reducers/roles';
import { environment } from '../reducers/environment';
import finalFormReducer from '../components/specific/RFF/finalFormDuck';

// Redux Persist
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { contrato } from '../reducers/contrato';
import { marca } from '../reducers/marca';
import { tipoVehiculo } from '../reducers/tipoVehiculo';
import { vehiculo } from '../reducers/vehiculo';
import { zona } from '../reducers/zona';
import { tercero } from '../reducers/tercero';
import { trailer } from '../reducers/trailer';
import { tarifa } from '../reducers/tarifa';
import { tipoDocumento } from '../reducers/tipoDocumento';
import { Solicitud } from '../reducers/Solicitud';
import { Bono } from '../reducers/Bono';
import { Cliente } from '../reducers/Cliente';
import { AsignarBono } from '../reducers/asignarBono';
import { CentroOperacion } from '../reducers/centroOperacion';
import { UnidadNegocio } from '../reducers/unidadNegocio';
import { Regional } from '../reducers/regional';
import { lugar } from '../reducers/lugar';
import { ImportarTarifa } from '../reducers/importarTarifa';

export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ || compose;

export const rootReducer = combineReducers({
  auth: auth,
  finalForm: finalFormReducer,
  roles: roles,
  contrato: contrato,
  tercero: tercero,
  marca: marca,
  vehiculo: vehiculo,
  zona: zona,
  tipoVehiculo: tipoVehiculo,
  environment: environment,
  trailer: trailer,
  tarifa: tarifa,
  tipoDocumento: tipoDocumento,
  Solicitud: Solicitud,
  Bono: Bono,
  Cliente: Cliente,
  AsignarBono: AsignarBono,
  CentroOperacion: CentroOperacion,
  UnidadNegocio: UnidadNegocio,
  Regional: Regional,
  lugar: lugar,
  ImportarTarifa: ImportarTarifa,
});

const persistConfig = {
  key: 'root',
  storage,
  //blacklist: ['tools', 'companies']
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeEnhancers());

// export const initialState = {
//   auth: { loggedIn: false, usuario: {} },
//   selProject: {},
//   companies: { isData: false },
//   projects: { isData: false },
//   loadingAnimation: { show: false },

// };

//export const store = createStore(rootReducer, initialState, composeEnhancers());
//export let store = createStore(reducers, {}, composeEnhancers(applyMiddleware(promiseMiddleware())));
