//Solo para inputs

export const inputsSettingsTrue = 'bg-gray-200 border w-full mt-2 h-10 px-3 py-4 text-gray-800 focus:outline-none focus:border-blue-500 rounded';

export const inputsModales = 'bg-gray-200 border w-full mt-2 h-10 px-3 py-2 text-gray-800 focus:outline-none focus:border-blue-500 rounded';

export const inputsSettingsFalse =
  'bg-gray-200 border border-red-500 w-full mt-2 h-10 px-3 py-4 text-red-500 focus:outline-none focus:border-red-500 mb-3 rounded';

// TEXTAREA
export const inputTextAreaTrue = 'bg-gray-200 border w-full h-24 mt-2 h-12 px-3 py-4 text-gray-800 focus:outline-none focus:border-blue-500 rounded';
export const inputTextAreaFalse =
  'bg-gray-200 border border-red-500 w-full h-24 mt-2 h-12 px-3 py-4 text-red-500 focus:outline-none focus:border-red-500 mb-3 rounded';

//inputs Filter

export const inputsFilterTrue = 'border w-full h-10 px-3 py-2 text-gray-800 focus:outline-none focus:border-blue-500 mb-3 rounded';
export const inputsFilterFalse = 'border border-red-500 w-full h-10 px-3 py-2 text-gray-400 focus:outline-none focus:border-red-500 mb-3 rounded';

//selects

export const inputSelectOk = 'border rounded';
export const inputSelectError = 'border border-red-500 rounded';
