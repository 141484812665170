import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import { fixDateForBE } from '../../../../utils/format';
import Select from 'react-select';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { useEffect } from 'react';

export const ModalAgregarTrailer = (props) => {
  const { setModalAgregarTrailer } = props.modal;
  const { createTrailer, updateTrailer } = props.endPoint;
  const { validate } = props.actions;

  const { post, get, put } = useApiClient();
  const storeState = store.getState();

  //+++ ELEMENTOS PARA EL SELECT DE MARCAS +++
  const [marcaOptions, setMarcaOptions] = useState([]);

  const getMarcas = async () => {
    console.info('getMarcas Start!!!');
    try {
      const response = await get(`/marca`);
      console.info('*** getMarcas', response);

      const lst = response.data.map((row) => ({
        label: `${row.MARCA_DESCRIPCION}`,
        value: row.MARCA_ID,
      }));

      setMarcaOptions(lst);
    } catch (error) {
      console.info('error getMarcas', error);
    }
  };

  //--- ELEMENTOS PARA EL SELECT DE MARCAS ---

  // +++ MUTADORES +++
  const setMARCA_ID = (args, state, { setIn, changeValue }) => {
    console.info('args', args[0].value);

    const field = state.fields['MARCA_ID'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  // --- MUTADORES ---

  const editMode = storeState.trailer.selTrailer === null ? false : true;
  console.info('checkEditMode', editMode);
  console.info('checkEditMode', storeState.trailer.selTrailer);

  const proxyTask = async (form) => {
    if (editMode) updateTrailer(form);
    else createTrailer(form);
  };

  const getTrailerPropiofromState = (reg) => {
    const lstValues = [];

    if (reg === 1) lstValues.push('1');

    return lstValues;
  };

  const lstCheckTrailerPropio = getTrailerPropiofromState(storeState.trailer.selTrailer?.TRAILER_PROPIO);

  useEffect(() => {
    getMarcas();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={proxyTask}
        validate={validate}
        initialValues={editMode ? { ...storeState.trailer.selTrailer, TRAILER_PROPIO: lstCheckTrailerPropio } : {}}
        mutators={{
          setMARCA_ID,
        }}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <h2 className="py-4 text-xl ">{editMode ? 'Editar' : 'Nuevo'} trailer</h2>
                  <FormStateToRedux form="frmAgregarRol" />
                  {/* +++ SELECT DE MARCAS +++ */}
                  <div className="flex-auto justify-center text-left">
                    <p className="text-sm text-gray-800">Marca</p>
                    {marcaOptions.length > 0 ? (
                      <Select
                        defaultValue={marcaOptions.find((i) => i.value === storeState.trailer.selTrailer?.MARCA_ID)}
                        onChange={form.mutators.setMARCA_ID}
                        options={marcaOptions}
                        name="selectMarca"
                        className=""
                      />
                    ) : (
                      <div className=" flex items-center justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="MARCA_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  {/* --- SELECT DE MARCAS --- */}
                  <Field name="TRAILER_PLACA">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Placa</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="Placa"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>

                  <div>
                    <div className="mt-2 flex space-x-4">
                      <label>
                        <Field
                          name="TRAILER_PROPIO"
                          component="input"
                          type="checkbox"
                          value="1"
                        />{' '}
                        Trailer Propio
                      </label>
                    </div>
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalAgregarTrailer(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles };
};

//export default Projects;
export default connect(mapStateToProps)(ModalAgregarTrailer);
