import React, { useState, useEffect } from 'react';

import ViewCliente from './view';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setSelCliente, setCliente, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Cliente = (props) => {
  const { get, post, DELETE, put } = useApiClient();
  const [modalAgregarCliente, setModalAgregarCliente] = useState(false);
  const [modalAgregarUsuarios, setModalAgregarUsuarios] = useState(false);
  const [isLoadingTables, setIsLoadingTables] = useState(false);
  const [usuarioOptions, setUsuarioOptions] = useState([]);
  const [clienteUsuarios, setClienteUsuarios] = useState([]);

  const getCliente = async () => {
    setIsLoadingTables(true);
    try {
      const result = await get(`/rol/6`);

      store.dispatch(setCliente(result));
      setClienteUsuarios(result.data.tercero);
    } catch (error) {}
    setIsLoadingTables(false);
  };

  const getClienteUsuarios = async (clienteID) => {
    try {
      const result = await get(`/terceroCliente?CLIENTE_ID=${clienteID}`);

      setClienteUsuarios(result.data);
    } catch (error) {}
  };

  const getUsuarios = async () => {
    setIsLoadingTables(true);
    try {
      const result = await get(`/tercero`);

      const lst = result.data.map((row) => ({
        label: `${row.TERCERO_NOMBRE_BUSQUEDA}`,
        value: row.TERCERO_ID,
      }));

      setUsuarioOptions(lst);
    } catch (error) {}
    setIsLoadingTables(false);
  };

  const getClienteFilter = async (value) => {
    setIsLoadingTables(true);
    try {
      const result = await get(`/rol/${value}`);

      store.dispatch(setCliente(result));
    } catch (error) {}
    setIsLoadingTables(false);
  };

  const createCliente = async (form) => {
    try {
      const result = await post(
        '/parametroCliente',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarCliente(false);
        OkToast({ result, message: 'Cliente Agregado!' });
        getCliente();
      }
    } catch (error) {}
  };
  const addClienteUsuario = async (form) => {
    try {
      const result = await post(
        '/terceroCliente',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        //setModalAgregarCliente(false);
        OkToast({ result, message: 'Cliente Agregado!' });
        getClienteUsuarios(Cliente.selCliente.TERCERO_ID);
      }
    } catch (error) {}
  };

  const updateCliente = async (form, cliente_ID) => {
    try {
      //

      const result = await put(
        `/parametroCliente/${cliente_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarCliente(false);
        OkToast({ result, message: 'Cliente actualizado!' });
        getCliente();
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['MARCA_ID', 'Cliente_PLACA', 'Cliente_PROPIO'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  function handleEdit(obj) {
    store.dispatch(setSelCliente(obj));
    setModalAgregarCliente(true);
  }

  function handleAddUsers(obj) {
    store.dispatch(setSelCliente(obj));
    setModalAgregarUsuarios(true);
  }

  function handleDelete(rowID) {
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el Cliente?...</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteCliente(rowID);
          }}>
          Si
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }
  function handleDeleteUsuarioCliente(rowID) {
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el Usuario?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteUsuario(rowID);
          }}>
          Si
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }

  const deleteCliente = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await DELETE(`/Cliente/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Cliente eliminado!' });

        getCliente();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }

    store.dispatch(hideLoadingAnimation());
  };
  const deleteUsuario = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await DELETE(`/terceroCliente/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Usuario eliminado!' });

        getClienteUsuarios(Cliente.selCliente.TERCERO_ID);
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }

    store.dispatch(hideLoadingAnimation());
  };

  useEffect(() => {
    getCliente();
  }, []);

  const Cliente = props.Cliente;

  return (
    <ViewCliente
      modal={{ modalAgregarCliente, setModalAgregarCliente, modalAgregarUsuarios, setModalAgregarUsuarios }}
      endPoint={{ createCliente, updateCliente, getUsuarios, getClienteUsuarios, addClienteUsuario }}
      actions={{ handleEdit, handleDelete, handleDeleteUsuarioCliente, isLoadingTables, validate, getClienteFilter, handleAddUsers }}
      data={{ Cliente, usuarioOptions, clienteUsuarios }}
    />
  );
};

const mapStateToProps = function (state) {
  return { Cliente: state.Cliente };
};

//export default Projects;
export default connect(mapStateToProps)(Cliente);
