import React from 'react';
import TbodyTableCliente from './tbody';
import TheadTableCliente from './thead';

const TableCliente = ({ actions, data }) => {
  const { isLoadingTables } = actions;
  return (
    <div className="overflow-x-auto">
      {!isLoadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTableCliente />
          <TbodyTableCliente
            actions={actions}
            data={data}
          />
        </table>
      )}
    </div>
  );
};
export default TableCliente;
