import React from 'react';
import Nav from '../../../components/specific/Navigation';
import TableTipoVehiculo from '../../../components/specific/TipoVehiculo/Table';
import Filter from '../../../components/specific/TipoVehiculo/Filter';
import { ModalAgregarTipoVehiculo } from '../../../components/specific/TipoVehiculo/Modales/agregarTipoVehiculo';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import { ButtonTable } from '../../../components/global/Styles/buttons';
import { TitlesTables } from '../../../components/global/Styles/titles';
import { store } from '../../../store';
import { setSelTipoVehiculoInit } from '../../../actions';

const ViewTipoVehiculo = (props) => {
  const { modalAgregarTipoVehiculo, setModalAgregarTipoVehiculo, createTipoVehiculo, validate } = props.modal;
  const { getRoles } = props;
  const { loadingTables } = props.data;

  const handleClick = () => {
    store.dispatch(setSelTipoVehiculoInit());
    setModalAgregarTipoVehiculo(true);
  };

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>TIPOS DE VEHÍCULOS</TitlesTables>
            <div className="flex-auto"></div>

            <ButtonTable onClick={() => handleClick()}>Agregar</ButtonTable>
          </div>

          {loadingTables && <LoadingTables />}
          <TableTipoVehiculo
            actions={props.actions}
            data={props.data}
          />
        </div>
      </div>

      {modalAgregarTipoVehiculo && (
        <ModalAgregarTipoVehiculo
          endPoint={props.endPoint}
          setModalAgregarTipoVehiculo={setModalAgregarTipoVehiculo}
          getRoles={getRoles}
        />
      )}
    </div>
  );
};

export default ViewTipoVehiculo;
