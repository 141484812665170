import React from 'react';

import { ButtonTable } from '../../../global/Styles/buttons';

import { ModalTitle } from '../../../global/Styles/titles';

import { JSONTree } from 'react-json-tree';

export const ModalAnticipoSiesaLog = (props) => {
  const { setIsModalAnticipoSiesaLogVisible, data } = props;

  const { isSendFail, refrestVehiculoAnticipos, row } = data;

  const { resendSiesaAnticipo } = props.endPoint;

  const [isloading, setIsLoading] = React.useState(false);

  //

  const handleClickCerrar = () => {
    setIsModalAnticipoSiesaLogVisible(false);
  };

  const handleClickReenviar = async () => {
    setIsLoading(true);

    await resendSiesaAnticipo(row.SOLICITUD_ID);
    refrestVehiculoAnticipos();
    setIsModalAnticipoSiesaLogVisible(false);

    setIsLoading(false);
  };

  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>SIESA - MENSAJES - {row.SOLICITUD_EXT_FECHA}</ModalTitle>

        <div className="px-5">
          <JSONTree
            theme={theme}
            invertTheme={true}
            data={JSON.parse(row.SOLICITUD_EXT_RESPUESTA)}
          />
        </div>
        <hr className="mt-1" />
        <div className="w-full space-x-4">
          <div className="md:block mx-16 mt-2 space-x-4 text-center">
            {isSendFail && (
              <ButtonTable
                disabled={isloading}
                onClick={handleClickReenviar}>
                {isloading ? 'Reenviando...' : 'Reenviar'}
              </ButtonTable>
            )}
            <ButtonTable onClick={handleClickCerrar}>Cerrar</ButtonTable>
          </div>
        </div>
      </div>
    </div>
  );
};
