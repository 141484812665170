import { ArchiveBoxIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

export const TableVehiculoAnticipoThead = (props) => {
  return (
    <thead className="bg-gray-100 text-sm uppercase text-gray-700">
      <tr>
        <th
          scope="col"
          className=" whitespace-nowrap  px-2 text-left ">
          ID
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap  px-2 text-left">
          FECHA
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap px-2 text-left">
          Doc.
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap px-2 text-left">
          FASE
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap px-2 text-right">
          SOLICITADO
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap px-2 text-right">
          APROBADO
        </th>
        {/* <th
          scope="col"
          className=" whitespace-nowrap px-2 text-left ">
          ROL
        </th> */}
        <th
          scope="col"
          className=" whitespace-nowrap px-2 text-left">
          TERCERO
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap px-2 text-right">
          COSTO
        </th>
        {/* <th
          scope="col"
          className=" whitespace-nowrap px-2 text-left">
          Obs.
        </th> */}
        <th
          scope="col"
          className=" whitespace-nowrap px-2 text-left">
          Usuario
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
};
