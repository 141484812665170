import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const protectedMainFrame = ({ path, component: Component, exact, name, pageProps }, userMode, isOnboarded) => {
  let Comp = Component;
  if (!isOnboarded || userMode === 'deactivated') {
    Comp = () => <Redirect to="/get-started" />;
  }

  return (
    <Route
      key={name}
      {...{ path }}
      {...{ exact }}
      mainView
      component={Comp}
      {...{ pageProps }}
    />
  );
};

export const protectedNoFrame = ({ path, component: Component, exact, pageProps, name }) => (
  <Route
    key={name}
    {...{ path }}
    {...{ exact }}
    component={Component}
    {...{ pageProps }}
  />
);

export const openNoFrame = ({ name, path, component: Component, exact, pageProps }) => (
  <Route
    path={path}
    exact={exact}
    render={() => React.createElement(Component, pageProps)}
    key={name}
  />
);
