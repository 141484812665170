import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Zona/Filter';
import TableZona from '../../../components/specific/Zona/Table';
import { ModalAgregarZona } from '../../../components/specific/Zona/Modales/ModalAgregarZona';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

import { ButtonTable } from '../../../components/global/Styles/buttons';

import { TitlesTables } from '../../../components/global/Styles/titles';

const ViewZona = (props) => {
  const { isModalAgregarZonaVisible } = props.modal;
  const { handleShowAddNewModal } = props.actions;
  const { loadingTables } = props.data;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'bg-gray-500 p-2 rounded-md text-white font-bold text-sm ',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>ZONAS</TitlesTables>
            <div className="flex-auto"></div>
            <Filter
              endPoint={props.endPoint}
              data={props.data}
            />
            <ButtonTable onClick={handleShowAddNewModal}>Agregar</ButtonTable>
          </div>

          {loadingTables && <LoadingTables />}
          <TableZona
            data={props.data}
            actions={props.actions}
          />
        </div>
      </div>

      {isModalAgregarZonaVisible ? (
        <ModalAgregarZona
          handleShowAddNewModal={handleShowAddNewModal}
          data={props.data}
          actions={props.actions}
        />
      ) : null}
    </div>
  );
};

export default ViewZona;
