const initialState = {
  isData: false,
  ImportarTarifa: [],
  selImportarTarifa: null,
  pagination: null,
};

const initialImportarTarifaState = {
  selImportarTarifa: null,
};

export const ImportarTarifa = (state = initialState, action) => {
  switch (action.type) {
    case 'setImportarTarifa': {
      const stateData = {
        errors: action.value.errors,
        isData: true,
        message: action.value.message,
        success: action.value.success,
        deleted: action.value.deleted,
        imported: action.value.imported,
      };

      return { ...state, ...stateData };
    }
    case 'setImportarTarifaInit': {
      return initialState;
    }
    case 'setSelImportarTarifa': {
      const obj = {
        ...action.value,
      };

      const stateData = { selImportarTarifa: obj };

      return { ...state, ...stateData };
    }
    case 'setSelImportarTarifaInit': {
      return { ...state, ...initialImportarTarifaState };
    }

    default:
      //
      return state;
  }
};
