import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ViewRoles from './ViewRoles';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setRoles, setSelRoles, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';
const Roles = (props) => {
  const { get, post, DELETE } = useApiClient();
  const [modalAgregarRol, setModalAgregarRol] = useState(false);
  const [modalAgregarRolPersonas, setModalAgregarRolPersonas] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [tercerosRol, setTercerosRol] = useState([]);
  const getRoles = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/rol`);
      const lst = result.data.map((row) => ({
        label: `${row.ROL_NOMBRE}`,
        value: row.ROL_ID,
      }));
      setRolesOptions(lst);
      store.dispatch(setRoles(result));
    } catch (error) {}
    setLoadingTables(false);
  };
  const getCollaborators = async () => {
    store.dispatch(showLoadingAnimation());
    try {
      const response = await get(`/tercero?paginate=0`);
      const lst = response.data.map((row) => ({
        label: `${row.TERCERO_NOMBRE_BUSQUEDA}`,
        value: row.TERCERO_ID,
      }));
      setCollaboratorOptions(lst);
    } catch (error) {}
    store.dispatch(hideLoadingAnimation());
  };
  const getTeceroRol = async (id) => {
    store.dispatch(showLoadingAnimation());
    try {
      const response = await get(`/tercero?ROL_ID=${id}`);
      setTercerosRol(response);
    } catch (error) {}
    store.dispatch(hideLoadingAnimation());
  };
  const createRolinTercero = async (form) => {
    try {
      const result = await post(
        '/terceroRol',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Rol Agregado!' });
        getTeceroRol(props.roles.selRoles.ROL_ID);
      }
    } catch (error) {}
  };
  const deleteRol = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/rol/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Rol eliminado!' });
        getRoles();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };
  const deleteTerceroInRol = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/terceroRol/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Usuario eliminado del rol!' });
        getTeceroRol(props.roles.selRoles.ROL_ID);
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };
  function handleEdit(obj) {
    store.dispatch(setSelRoles(obj));
    setModalAgregarRol(true);
  }
  function handleDeleteRol(rowID) {
    toast((t) => (
      <span>
        <b>¿Está seguro de querer eliminar rol?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteRol(rowID);
          }}>
          Sí
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }
  function handleDeleteTerceroInRol(rowID) {
    toast((t) => (
      <span>
        <b>¿Está seguro de querer eliminar a el usuario del rol?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteTerceroInRol(rowID);
          }}>
          Sí
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }
  useEffect(() => {
    getRoles();
    getCollaborators();
  }, []);

  return (
    <ViewRoles
      getRoles={getRoles}
      endPoint={{ getTeceroRol }}
      createRolinTercero={createRolinTercero}
      modal={{ modalAgregarRol, setModalAgregarRol, modalAgregarRolPersonas, setModalAgregarRolPersonas }}
      actions={{ collaboratorOptions, rolesOptions, handleDeleteTerceroInRol, loadingTables, handleEdit, handleDeleteRol, setTercerosRol }}
      data={{ tercerosRol }}
    />
  );
};
const mapStateToProps = function (state) {
  return { roles: state.roles };
};
export default connect(mapStateToProps)(Roles);
