import React, { useEffect, useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';

import { store } from '../../../../store';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { ModalTitle } from '../../../global/Styles/titles';

import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';

export const ModalAgregarTipoVehiculo = (props) => {
  const { setModalAgregarTipoVehiculo } = props;

  const { createTipoVehiculo, updateTipoVehiculo } = props.endPoint;
  const storeState = store.getState();

  const editMode = storeState.tipoVehiculo.selTipoVehiculo === null ? false : true;
  const [claseOptions, setClaseOptions] = useState([]);

  const { get } = useApiClient();

  const getClaseOptions = async () => {
    try {
      const response = await get(`/clase`);

      const lst = response.data.map((row) => ({
        label: `${row.CLASE_DESCRIPCION}`,
        value: row.CLASE_ID,
      }));

      setClaseOptions(lst);
    } catch (error) {}
  };

  const setCLASE_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CLASE_ID'];

    field.change(args[0].value);
  };

  const selClaseOptionsItem = claseOptions.find((i) => i.value === storeState.tipoVehiculo.selTipoVehiculo?.CLASE_ID);

  useEffect(() => {
    getClaseOptions();
  }, []);

  const validate = (values) => {
    const errors = {};

    const requiredFields = ['CLASE_ID', 'VEHICULO_TIPO_BUS_GENERICO', 'VEHICULO_TIPO_BUS_GRUPO', 'VEHICULO_TIPO_DESCRIPCION'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  const proxyTask = async (form) => {
    console.log('proxyTask: ', form);
    if (editMode) updateTipoVehiculo(form);
    else createTipoVehiculo(form);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-2xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>{editMode ? 'EDITAR' : 'NUEVO'} TIPO VEHÍCULO</ModalTitle>
        <Form
          onSubmit={proxyTask}
          validate={validate}
          initialValues={editMode ? storeState.tipoVehiculo.selTipoVehiculo : {}}
          subscription={{ submitting: true, pristine: true }}
          mutators={{
            setCLASE_ID,
          }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <FormStateToRedux form="frmAgregarTipoVehiculo" />
                  <div className="mt-1 flex  flex-col">
                    <p className="text-sm text-gray-800">Clase Vehículo</p>
                    {claseOptions.length > 0 ? (
                      <Field name="CLASE_ID">
                        {({ input, meta }) => (
                          <>
                            <Select
                              options={claseOptions}
                              defaultValue={selClaseOptionsItem}
                              name="selectClaseTipoOptions"
                              onChange={form.mutators.setCLASE_ID}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  marginTop: 6,
                                  borderColor: meta.error && meta.touched ? 'red' : null,
                                }),
                              }}
                            />

                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    ) : (
                      <div className="mt-1 flex-grow pb-1">
                        <Skeleton
                          height={34}
                          width="100%"
                        />
                      </div>
                    )}
                  </div>
                  <Field name="VEHICULO_TIPO_DESCRIPCION">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Nombre</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="nombre"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  <Field name="VEHICULO_TIPO_BUS_GENERICO">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Búsqueda generico</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="nombre"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  <Field name="VEHICULO_TIPO_BUS_GRUPO">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Búsqueda grupo</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="nombre"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalAgregarTipoVehiculo(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ModalAgregarTipoVehiculo;
