import React from 'react';
const TableHeader = () => {
  return (
    <thead className="sticky bg-gray-800 text-white">
      <tr>
        <th className="w-1/3 rounded-tl-lg px-4 py-3 text-left text-sm font-semibold uppercase">Nombre</th>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Documento</th>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Razón social</th>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Correo</th>
        <th className="w-1/3 rounded-tr-lg px-4 py-3 text-left text-sm font-semibold uppercase">{''}</th>
      </tr>
    </thead>
  );
};
export default TableHeader;
