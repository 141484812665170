import { ToolTipWrap } from './ToolTipWrap';
import { WrenchScrewdriverIcon, ClipboardDocumentListIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';
import { solicitudEstados } from './solicitudEstados';

export const ReporteFinalIcon = ({ itemData, solicitudEstado, solicitud, callback }) => {
  if (solicitudEstado < solicitudEstados['Finalizada']) return;

  return (
    <ToolTipWrap
      itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
      componentPrefix={'tooltipReporteTrabajoFinal'}
      message={'Ver Prefactura...'}>
      <DocumentCheckIcon
        data-tip
        data-for={`tooltipReporteTrabajoFinal${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
        className="hover:fill-blue-500 h-5 w-5 transform cursor-pointer text-green-500 transition  duration-200 hover:scale-110"
        onClick={() => callback(itemData, solicitud)}
      />
    </ToolTipWrap>
  );
};
