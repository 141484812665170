import React from 'react';
import { connect } from 'react-redux';
import { moneyFormat } from '../../../../../utils/format';

const getSolicitudTipo = (row) => {
  if (row.AUDIT_TYPE === 1) {
    return 'Creación';
  }
  if (row.AUDIT_TYPE === 2) {
    if (row.SOLICITUD_APROBADA === 1) {
      return 'Aprobada';
    }
    if (row.SOLICITUD_APROBADA === 2) {
      return 'Cancelada';
    }
    if (row.SOLICITUD_APROBADA === 3) {
      return 'Rechazada';
    }
    if (row.SOLICITUD_APROBADA === 4) {
      return 'Confirmada';
    }
    if (row.SOLICITUD_APROBADA === 5) {
      return 'Ejecución';
    }
    if (row.SOLICITUD_APROBADA === 6) {
      return 'Finalizada';
    }
    console.log('row: ', row);
  }
  return 'N/A';
};

const getSolicitudDetalle = (row) => {
  if (row.AUDIT_TYPE === 2) {
    if (row.SOLICITUD_APROBADA === 4) {
      return (
        <>
          <table>
            {row.vehiculo_tipo.map((item) => (
              <>
                <tr>
                  <th className=" pr-2 pt-2 text-left">Tipo de Vehículo</th>
                  <th className=" pr-2 pt-2 text-left">
                    {item.VEHICULO_TIPO_DESCRIPCION}{' '}
                    {item.pivot.SOLICITUD_VEHICULO_MANIFIESTO_ESTADO === 1 ? <span className="text-orange-700"> [Manifiesto]</span> : ''}
                    {item.pivot.SOLICITUD_VEHICULO_REPORTE_ESTADO === 1 ? <span className="text-orange-700"> [Reporte]</span> : ''}
                  </th>
                </tr>
                <tr>
                  <td>Vehículo</td>
                  <td>{item.pivot.VEHICULO_PLACA}</td>
                </tr>
                {item.pivot.TRAILER_PLACA && (
                  <tr>
                    <td>Trailer</td>
                    <td>{item.pivot.TRAILER_PLACA} </td>
                  </tr>
                )}
                <tr>
                  <td>Peso</td>
                  <td>{item.pivot.SOLICITUD_PESO}</td>
                </tr>
                {item.pivot.CONDUCTOR_NOMBRE && (
                  <tr>
                    <td>Conductor</td>
                    <td>{item.pivot.CONDUCTOR_NOMBRE + ' C.C. ' + item.pivot.CONDUCTOR_DOC + ' Tel: ' + item.pivot.CONDUCTOR_CEL}</td>
                  </tr>
                )}
                {item.pivot.CONDUCTOR_VALOR && (
                  <tr>
                    <td></td>
                    <td>{' Valor Total ' + item.pivot.CONDUCTOR_VALOR + ' Anticipo: ' + item.pivot.CONDUCTOR_ANTICIPO}</td>
                  </tr>
                )}
                {item.pivot.PROVEEDOR_NOMBRE && (
                  <tr>
                    <td>Proveedor</td>
                    <td>{item.pivot.PROVEEDOR_NOMBRE + ' C.C. ' + item.pivot.PROVEEDOR_DOC + ' Tel: ' + item.pivot.PROVEEDOR_CEL}</td>
                  </tr>
                )}
                {item.pivot.PROVEEDOR_VALOR && (
                  <tr>
                    <td></td>
                    <td>{' Valor Total ' + item.pivot.PROVEEDOR_VALOR + ' Anticipo: ' + item.pivot.PROVEEDOR_ANTICIPO}</td>
                  </tr>
                )}

                {item.pivot.APAREJADOR_NOMBRE && (
                  <tr>
                    <td>Aparejador</td>
                    <td>{item.pivot.APAREJADOR_NOMBRE + ' C.C. ' + item.pivot.APAREJADOR_DOC + ' Tel: ' + item.pivot.APAREJADOR_CEL}</td>
                  </tr>
                )}
                {item.pivot.APAREJADOR_VALOR && (
                  <tr>
                    <td></td>
                    <td>{' Valor Total ' + item.pivot.APAREJADOR_VALOR + ' Anticipo: ' + item.pivot.APAREJADOR_ANTICIPO}</td>
                  </tr>
                )}
              </>
            ))}
          </table>
          <table>
            <tr>
              <th className="text-left ">CONSEC</th>
              <th className="text-left ">ITEM</th>
              <th className="text-left ">TARIFA</th>
              <th className="text-right ">CANTIDAD</th>
              <th className="text-right ">VALOR</th>
              <th className="text-right ">TOTAL</th>
            </tr>
            {row.detalles.map((item) => (
              <>
                <tr>
                  <td className="px-2 text-left ">{item.TARIFA_CONSECUTIVO}</td>
                  <td className="px-2 text-left ">{item.TARIFA_ITEM}</td>
                  <td className="px-2 text-left ">{`${item.TARIFA_GRUPO} ${item.TARIFA_NOMBRE}`}</td>
                  <td className="px-2 text-right ">{item.SOLICITUD_DETALLE_CANTIDAD}</td>
                  <td className="px-2 text-right ">{moneyFormat(item.SOLICITUD_DETALLE_VALOR)}</td>
                  <td className="px-2 text-right ">{moneyFormat(item.SOLICITUD_DETALLE_TOTAL)}</td>
                </tr>
              </>
            ))}
          </table>
        </>
      );
    }
    if (row.SOLICITUD_APROBADA === 5) {
      return (
        <>
          <table>
            <tr>
              {row.SOLICITUD_EST_FEC1 && row.SOLICITUD_EST_FEC2 === null && <th className="text-left ">Alistamiento</th>}
              {row.SOLICITUD_EST_FEC2 && row.SOLICITUD_EST_FEC3 === null && <th className="text-left ">Camino al cliente</th>}
              {row.SOLICITUD_EST_FEC3 && row.SOLICITUD_EST_FEC4 === null && <th className="text-left ">Base cliente</th>}
              {row.SOLICITUD_EST_FEC4 && row.SOLICITUD_EST_FEC5 === null && <th className="text-left ">En cargue</th>}
              {row.SOLICITUD_EST_FEC5 && row.SOLICITUD_EST_FEC6 === null && <th className="text-left ">En viaje</th>}
              {row.SOLICITUD_EST_FEC6 && row.SOLICITUD_EST_FEC7 === null && <th className="text-left ">Llegó destino</th>}
              {row.SOLICITUD_EST_FEC7 && <th className="text-left ">Decargue</th>}
            </tr>
          </table>
          {row.SOLICITUD_EST_FEC1 === null && (
            <table>
              <tr>
                <th className="text-left ">Fecha</th>
                <th className="text-left ">Novedades</th>
              </tr>
              {row.novedades.map((item) => (
                <>
                  <tr>
                    <td className="px-2 text-left ">{item.SOLICITUD_NOVEDAD_FECHA}</td>
                    <td className="px-2 text-left ">{item.SOLICITUD_NOVEDAD_OBSERVACION}</td>
                  </tr>
                </>
              ))}
            </table>
          )}
        </>
      );
    }

    if (row.SOLICITUD_APROBADA === 6) {
      return (
        <>
          {row.EVAL_AT !== null && (
            <table>
              <tr>
                <th className="px-2 text-left ">EVALUACIÓN</th>
                <th className="px-2 text-left ">{row.EVAL_AT}</th>
              </tr>
              <tr>
                <th className="px-2 text-left ">Logística y Coordinación del Servicio</th>
                <th className="px-2 text-left ">{row.p1}</th>
              </tr>
              <tr>
                <th className="px-2 text-left ">Tiempo de Respuesta</th>
                <th className="px-2 text-left ">{row.p2}</th>
              </tr>
              <tr>
                <th className="px-2 text-left ">Desempeño en HSEQ</th>
                <th className="px-2 text-left ">{row.p3}</th>
              </tr>
              <tr>
                <th className="px-2 text-left ">Calidad del Servicio</th>
                <th className="px-2 text-left ">{row.p4}</th>
              </tr>
            </table>
          )}
        </>
      );
    }
  }
  return row.SOLICITUD_APROBADA_OBS;
};

const TbodyTableSolicitudLogs = (props) => {
  const data = props.Solicitud.selSolicitudLog;

  return (
    data && (
      <tbody className="rounded-lg text-gray-700">
        {data.map((row) => {
          return (
            <tr>
              <td className=" px-4 py-3 text-left">{row?.AUDIT_AT}</td>
              <td className=" px-4 py-3 text-left">{getSolicitudTipo(row)}</td>
              <td className=" px-4 py-3 text-left">{getSolicitudDetalle(row)}</td>
            </tr>
          );
        })}
      </tbody>
    )
  );
};

const mapStateToProps = function (state) {
  return {
    Solicitud: state.Solicitud,
  };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableSolicitudLogs);
