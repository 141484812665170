import React from 'react';
const TheadTableParametroCliente = () => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Parámetro</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Valor</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase"></th>
      </tr>
    </thead>
  );
};
export default TheadTableParametroCliente;
