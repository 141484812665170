import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import { fixDateForBE } from '../../../../utils/format';
import Select from 'react-select';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { hideLoadingAnimation, showLoadingAnimation } from '../../../../actions';

export const ModalAgregarTercero = (props) => {
  const { handleShowAddNewModal } = props.actions;
  const { tercero } = props.data;
  const { getTerceros } = props.endPoint;

  const { get, post, put } = useApiClient();
  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);
  const [municipioOptions, setMunicipioOptions] = useState([]);
  const storeState = store.getState();

  const getTipoDocumento = async () => {
    try {
      const response = await get(`/documentoTipo`);

      const lst = response.data.map((row) => ({
        label: `${row.DOCUMENTO_TIPO_DESCRIPCION}`,
        value: row.DOCUMENTO_TIPO_ID,
      }));

      setTipoDocumentoOptions(lst);
    } catch (error) {}
  };

  const getMunicipio = async () => {
    try {
      const response = await get(`/municipio`);

      const lst = response.data.map((row) => ({
        label: `${row.MUNICIPIO_DESCRIPCION}`,
        value: row.MUNICIPIO_CODIGO,
      }));

      setMunicipioOptions(lst);
    } catch (error) {}
  };

  const updateTercero = async (form) => {
    const result = await put(
      `/tercero/${tercero.selTercero.TERCERO_ID}`,
      {
        json: JSON.stringify(form),
      },
      'application/json'
    );
    if (String(result.status).substr(0, 1) !== '2') {
      CustomToast({ result });
    } else {
      handleShowAddNewModal(false);
      OkToast({ result, message: 'Usuario actualizado!' });
      getTerceros();
    }
  };

  const createTercero = async (form) => {
    try {
      const result = await post(
        '/tercero',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        getTerceros();
        handleShowAddNewModal(false);

        OkToast({ result, message: 'Tercero Agregado!' });
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['TERCERO_DOCUMENTO', 'TERCERO_CEL', 'TERCERO_MAIL'];
    const requiredFieldsCC = ['TERCERO_NOMBRE1', 'TERCERO_APELLIDO1'];
    const requiredFieldsNit = ['TERCERO_RAZON_SOCIAL'];

    const checkFields = values.DOCUMENTO_TIPO_ID === '31' ? [...requiredFields, ...requiredFieldsNit] : [...requiredFields, ...requiredFieldsCC];

    checkFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = '*';
      }
    });

    return errors;
  };

  //+++ ELEMENTOS PARA EL SELECT DE TIPOS DE VEHICULOS +++
  const [rolOptions, setRolOptions] = useState([]);

  const getRol = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/rol`);

      const lst = response.data.map((row) => ({
        label: `${row.ROL_NOMBRE}`,
        value: row.ROL_ID,
      }));

      //
      setRolOptions(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  //--- ELEMENTOS PARA EL SELECT DE TIPOS DE VEHICULOS ---

  // +++ MUTADORES +++
  const setTERCERO_ROL = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_ROL'];

    field.change(args[0]);
    state.formState.submitFailed = true;
  };

  const setDOCUMENTO_TIPO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['DOCUMENTO_TIPO_ID'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setMUNICIPIO_CODIGO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['MUNICIPIO_CODIGO'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  // --- MUTADORES ---
  const editMode = storeState.tercero.selTercero === null ? false : true;

  const proxyTask = async (form) => {
    if (editMode) {
      updateTercero(form);
    } else {
      createTercero(form);
    }
  };

  // --- PARA EL MULTI SELECT VALORES DEL REGISTRO.
  const lstValues = editMode
    ? storeState.tercero.selTercero.rol.map((row) => ({
        label: `${row.ROL_NOMBRE}`,
        value: row.ROL_ID,
      }))
    : null;

  useEffect(() => {
    getTipoDocumento();
    getMunicipio();
    getRol();
  }, []);

  return (
    <div
      className="min-w-screen animated  fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={proxyTask}
        validate={validate}
        subscription={{ submitting: true, pristine: true }}
        initialValues={editMode ? { ...storeState.tercero.selTercero, TERCERO_ROL: lstValues } : {}}
        mutators={{
          setTERCERO_ROL,
          setDOCUMENTO_TIPO_ID,
          setMUNICIPIO_CODIGO,
        }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <FormStateToRedux form="frmNewTercero" />
            <div className="relative mx-auto w-full max-w-6xl rounded-xl bg-white p-10  shadow-lg ">
              <div className="">
                <h2 className="py-4 text-xl ">Nuevo Usuario</h2>
                <div className="w-full">
                  <div className="w-1/2"></div>
                </div>
                <div className="flex space-x-2">
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Primer Nombre</p>
                    <Field name="TERCERO_NOMBRE1">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Primer Nombre"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Segundo Nombre</p>
                    <Field name="TERCERO_NOMBRE2">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Segundo nombre"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Primer Apellido</p>
                    <Field name="TERCERO_APELLIDO1">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Primer apellido"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Segundo Apellido</p>
                    <Field name="TERCERO_APELLIDO2">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Segundo apellido"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex space-x-2"></div>

                <div className="flex space-x-2">
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Tipo Identificación</p>
                    {tipoDocumentoOptions.length > 0 ? (
                      <Select
                        defaultValue={tipoDocumentoOptions.find((i) => i.value == storeState.tercero.selTercero?.DOCUMENTO_TIPO_ID)}
                        onChange={form.mutators.setDOCUMENTO_TIPO_ID}
                        options={tipoDocumentoOptions}
                        name="selectTipoId"
                        className="mt-2"
                      />
                    ) : (
                      <div className=" flex items-center justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="DOCUMENTO_TIPO_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800"># Documento</p>
                    <Field name="TERCERO_DOCUMENTO">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Documento"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="">
                  <div>
                    <p className="mt-3 text-sm text-gray-800">Razón social</p>
                    <Field name="TERCERO_RAZON_SOCIAL">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Razón social"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="flex space-x-2">
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Correo</p>
                    <Field name="TERCERO_MAIL">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Correo"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Teléfono</p>
                    <Field name="TERCERO_TEL">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="TELEFONO"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Célular</p>
                    <Field name="TERCERO_CEL">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Celular"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Municipio</p>
                    {municipioOptions.length > 0 ? (
                      <Select
                        defaultValue={municipioOptions.find((i) => i.value == storeState.tercero.selTercero?.MUNICIPIO_CODIGO)}
                        onChange={form.mutators.setMUNICIPIO_CODIGO}
                        options={municipioOptions}
                        name="selectTipoId"
                        className="mt-2"
                      />
                    ) : (
                      <div className=" flex items-center justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="MUNICIPIO_CODIGO">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Dirección</p>
                    <Field name="TERCERO_DIRECCION">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Dirección"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <div className="w-1/2">
                    <p className="mt-3 text-sm text-gray-800">Roles</p>

                    {/* +++ SELECT +++ */}
                    {rolOptions.length > 0 ? (
                      <Select
                        className="mt-2"
                        options={rolOptions}
                        isMulti={true}
                        defaultValue={lstValues}
                        name="selectRol"
                        onChange={form.mutators.setTERCERO_ROL}
                      />
                    ) : (
                      <div className=" flex items-center justify-center ">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="TERCERO_ROL">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                    {/* --- SELECT --- */}
                  </div>
                  <div className="w-1/2">
                    <p className="mt-2 text-sm text-gray-800">Unidad de negocio</p>
                    <Field name="TERCERO_UNI_NEGOCIO">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder=""
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="md:block mx-16 -mb-10 mt-4 space-x-4 text-center">
                  <ButtonTable onClick={() => handleShowAddNewModal(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default ModalAgregarTercero;
