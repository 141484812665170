import React from 'react';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import './styles.css';
import { TitlesNav } from '../../global/Styles/titles';

import { FaUserCircle } from 'react-icons/fa';
import MenuMaker from '../Menu/MenuMaker';
import UserTagComponent from './UserTagComponent';

const Nav = (props) => {
  let history = useHistory();
  return (
    <header className="h-14 bg-gray-800">
      <nav className="navbar w-full  pr-6">
        <div className="xl:px-4 flex w-full items-center py-2">
          <div className="ml-2 flex ">
            {/* <img
              className="w-18 h-14 object-scale-down"
              src={Logo}
              alt="background"
            /> */}
            <div
              onClick={() => history.push('/dashboard')}
              className="font-heading ml-1  cursor-pointer text-xl font-bold text-gray-200">
              Meneses Ramirez S.A.S.
            </div>
          </div>
          <UserTagComponent />
          <div
            className="menu"
            id="menu">
            <ul className="menu-inner">
              {/* <li
                className="sec-center"
                onClick={() => history.push('/servicios')}>
                <TitlesNav>Servicios</TitlesNav>
              </li>
              <li
                className="sec-center"
                onClick={() => history.push('/anticipos')}>
                <TitlesNav>Anticipos</TitlesNav>
              </li> */}

              <li className="sec-center">
                <TitlesNav>
                  <MenuMaker
                    text={'Operaciones'}
                    data={[
                      {
                        name: 'Servicios',
                        url: '/servicios',
                        onClick: undefined,
                      },

                      {
                        name: 'Anticipos',
                        url: '/anticipos',
                        onClick: undefined,
                      },

                      {
                        name: 'Cotizaciones',
                        url: '/cotizacion',
                        onClick: undefined,
                      },
                    ]}
                  />
                </TitlesNav>
              </li>
              <li className="sec-center">
                <TitlesNav>
                  <MenuMaker
                    text={'Parametros'}
                    data={[
                      {
                        name: 'Marcas',
                        url: '/marca',
                        onClick: undefined,
                      },
                      {
                        name: 'Tipos de vehículo',
                        url: '/tipoVehiculo',
                        onClick: undefined,
                      },
                      {
                        name: 'Vehículos',
                        url: '/vehiculo',
                        onClick: undefined,
                      },
                      {
                        name: 'Zonas',
                        url: '/zona',
                        onClick: undefined,
                      },

                      {
                        name: 'Contratos',
                        url: '/contrato',
                        onClick: undefined,
                      },

                      {
                        name: 'Tarifas',
                        url: '/tarifa',
                        onClick: undefined,
                      },
                      {
                        name: 'Bonos',
                        url: '/bono',
                        onClick: undefined,
                      },
                      {
                        name: 'Lugar',
                        url: '/lugar',
                        onClick: undefined,
                      },
                      {
                        name: 'Generales',
                        url: '/parametroGeneral',
                        onClick: undefined,
                      },
                    ]}
                  />
                </TitlesNav>
              </li>
              <li className="sec-center">
                <TitlesNav>
                  <MenuMaker
                    text={'Seguridad'}
                    data={[
                      {
                        name: 'Roles',
                        url: '/roles',
                        onClick: undefined,
                      },

                      {
                        name: 'Usuarios',
                        url: '/usuario',
                        onClick: undefined,
                      },

                      {
                        name: 'Clientes',
                        url: '/cliente',
                        onClick: undefined,
                      },
                    ]}
                  />
                </TitlesNav>
              </li>
              <li className="sec-center">
                <TitlesNav>
                  <MenuMaker
                    text={'Integración'}
                    data={[
                      {
                        name: 'Regionales',
                        url: '/regional',
                        onClick: undefined,
                      },
                      {
                        name: 'Centros de operaciones',
                        url: '/centroOperacion',
                        onClick: undefined,
                      },
                      {
                        name: 'Grupos de unidad de negocio',
                        url: '/unidadNegocio',
                        onClick: undefined,
                      },
                    ]}
                  />
                </TitlesNav>
              </li>
              {/* <li className="sec-center">
                <TitlesNav>Herramientas</TitlesNav>
              </li> */}
              {/*USER*/}
              <li className="">
                <TitlesNav>
                  <MenuMaker
                    text={
                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        {props.auth.data === undefined ? '' : props.auth.data.user.TERCERO_NOMBRE1}{' '}
                        <span style={{ marginLeft: '15px', marginTop: '4px' }}>
                          <FaUserCircle></FaUserCircle>
                        </span>
                      </span>
                    }
                    data={[
                      {
                        name: 'Configuración',
                        url: '/dashboard',
                        onClick: undefined,
                      },
                      {
                        name: 'Cerrar Sesión',
                        url: '',
                        onClick: undefined,
                      },
                    ]}
                  />
                </TitlesNav>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Nav);
