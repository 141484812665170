import { fixOnlyDateForTable, moneyFormat } from '../../../../../utils/format';
import { InformationCircleIcon, CodeBracketSquareIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { ToolTipWrap } from '../../Table/ToolTipWrap';
import { useApiClient } from '../../../../../clients/apiAuth';

const TerceroInfo = ({ row }) => {
  const ConductorLabel = ({ data }) => {
    const employeeData = {
      isEmployee: data.CONDUCTOR_ES_EMPLEADO === 1,
      isEmployeeMsg: data.CONDUCTOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
    };
    return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
  };

  const AparejadorLabel = ({ data }) => {
    const employeeData = {
      isEmployee: data.APAREJADOR_ES_EMPLEADO === 1,
      isEmployeeMsg: data.APAREJADOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
    };
    return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
  };

  if (row.SOLICITUD_ANTICIPO_TIPO === 'C') {
    return (
      <>
        {/* <td className="whitespace-nowrap px-2   text-left">C</td> */}
        <td className="whitespace-nowrap px-2   text-left">
          C.&nbsp;
          {row.CONDUCTOR_NOMBRE?.substring(0, 40)} <ConductorLabel data={row} />
        </td>
        <td className="whitespace-nowrap px-2   text-right">{moneyFormat(row.CONDUCTOR_VALOR)}</td>
      </>
    );
  }

  return (
    <>
      {/* <td className="whitespace-nowrap px-2   text-left">A</td> */}
      <td className="whitespace-nowrap px-2   text-left">
        A.&nbsp;
        {row.APAREJADOR_NOMBRE?.substring(0, 40)} <AparejadorLabel data={row} />
      </td>
      <td className="whitespace-nowrap px-2   text-right">{moneyFormat(row.APAREJADOR_VALOR)}</td>
    </>
  );
};

const SiesaLogIcon = ({ row, handleShowSiesaLog, refrestVehiculoAnticipos }) => {
  if (!moment(row.SOLICITUD_EXT_FECHA).isValid()) {
    return null;
  }

  //
  const responseCode = row.SOLICITUD_EXT_RESPUESTA && JSON.parse(row.SOLICITUD_EXT_RESPUESTA).codigo;

  const color = responseCode === 0 ? 'text-green-500' : 'text-red-500';

  return (
    <ToolTipWrap
      itemID={row.SOLICITUD_ANTICIPO_ID}
      componentPrefix={'tooltipSiesaLog'}
      message={row.responseCode}>
      <CodeBracketSquareIcon
        onClick={() => handleClick(row, responseCode !== 0, handleShowSiesaLog, refrestVehiculoAnticipos)}
        // data-tip
        // data-for={`tooltipSiesaLog${row.SOLICITUD_ANTICIPO_ID}`}
        className={`hover:fill-blue-500 h-5 w-5 transform cursor-pointer ${color} transition duration-300 hover:scale-110`}
      />
    </ToolTipWrap>
  );
};
const NoteIcon = ({ row }) => {
  const color = '';

  return (
    <ToolTipWrap
      itemID={row.SOLICITUD_ANTICIPO_ID}
      componentPrefix={'tooltipNoteIcon'}
      message={row.SOLICITUD_ANTICIPO_OBSERVACION}>
      <InformationCircleIcon
        data-tip
        data-for={`tooltipNoteIcon${row.SOLICITUD_ANTICIPO_ID}`}
        className={`hover:fill-blue-500 h-5 w-5 transform cursor-pointer ${color} transition duration-300 hover:scale-110`}
      />
    </ToolTipWrap>
  );
};

const handleClick = (row, isSendFail, handleShowSiesaLog, refrestVehiculoAnticipos) => {
  handleShowSiesaLog(row, isSendFail, refrestVehiculoAnticipos);
  // if (isSendFail) {
  //   resendSiesaAnticipo(row.SOLICITUD_ID);
  //   refrestVehiculoAnticipos();
  // }
};

export const TableVehiculoAnticipoTbody = ({ data, endPoint, actions }) => {
  const fases = ['', 'Financiera', 'Gerencia'];

  // const { resendSiesaAnticipo } = endPoint;
  const { refrestVehiculoAnticipos, handleShowSiesaLog } = actions;

  return (
    <tbody className="">
      {data?.map((obj) => (
        <tr
          key={obj.SOLICITUD_ANTICIPO_ID}
          className="border-b text-xs">
          <td className="px-2 text-green-400">{obj.SOLICITUD_ANTICIPO_ID}</td>
          <td className="whitespace-nowrap px-2   text-right">{fixOnlyDateForTable(obj.CREATED_AT)}</td>
          <td className="whitespace-nowrap px-2   text-left">{obj.SOLICITUD_ANTICIPO_CONSECUTIVO}</td>
          <td className="whitespace-nowrap px-2   text-left">{fases[obj.SOLICITUD_ANTICIPO_FASE].substring(0, 3)}</td>
          <td className="whitespace-nowrap px-2   text-right">{moneyFormat(obj.SOLICITUD_ANTICIPO_TOTAL)}</td>
          <td className="whitespace-nowrap px-2   text-right">{moneyFormat(obj.SOLICITUD_ANTICIPO_VALOR)}</td>

          <TerceroInfo row={obj} />

          {/* <td className="px-2text-left">{`${obj.TARIFA_GRUPO} ${obj.TARIFA_NOMBRE}`}</td> */}
          <td className="whitespace-nowrap px-2   text-left">{obj.TERCERO_NOMBRE_BUSQUEDA?.substring(0, 40)}</td>
          <td className=" px-2   text-left">
            <NoteIcon row={obj} />
          </td>

          <td className="whitespace-nowrap px-2   text-left">
            <SiesaLogIcon
              row={obj}
              // resendSiesaAnticipo={resendSiesaAnticipo}
              refrestVehiculoAnticipos={refrestVehiculoAnticipos}
              handleShowSiesaLog={handleShowSiesaLog}
            />
          </td>
          {/* <td
            className="cursor-pointer px-4 py-3 text-left hover:text-gray-400"
            onClick={() => handleClick(item.SOLICITUD_DETALLE_ID)}>
            <Trash />
          </td> */}
        </tr>
      ))}
    </tbody>
  );
};
