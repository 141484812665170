import { NoSymbolIcon, ArrowTrendingUpIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

import { ToolTipWrap } from './ToolTipWrap';
import { solicitudEstados } from './solicitudEstados';

export const AnticipoIcon = ({ itemData, solicitudEstado, solicitud, handleConfirmarTipoVehiculo }) => {
  if (solicitudEstado !== solicitudEstados['Confirmada']) return;
  if (solicitud.SOLICITUD_ANTICIPO_ESTADO === 2) return;

  const isFase1 = Number(itemData.APAREJADOR_VALOR_ESTADO) === 1 && Number(itemData.CONDUCTOR_VALOR_ESTADO) === 1;
  const isFase2 = Number(itemData.APAREJADOR_VALOR_ESTADO) === 2 && Number(itemData.CONDUCTOR_VALOR_ESTADO) === 2;

  if (isFase2)
    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'rttAnticipo'}
        message={'Anticipo Aprobado!'}>
        <CheckCircleIcon
          data-tip
          data-for={`rttAnticipo${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
          className="h-5 w-5 transform cursor-pointer text-green-500 "
          // onClick={() => handleConfirmarTipoVehiculo(itemData, solicitud)}
        />
      </ToolTipWrap>
    );

  if (isFase1)
    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'rttAnticipo'}
        message={'Anticipo Pendiente Gerencia!'}>
        <ArrowTrendingUpIcon
          data-tip
          data-for={`rttAnticipo${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
          className="h-5 w-5 transform cursor-pointer text-yellow-500 "
          // onClick={() => handleConfirmarTipoVehiculo(itemData, solicitud)}
        />
      </ToolTipWrap>
    );

  return (
    <ToolTipWrap
      itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
      componentPrefix={'rttAnticipo'}
      message={'Anticipo Pendiente Financiera'}>
      <NoSymbolIcon
        data-tip
        data-for={`rttAnticipo${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
        className="h-5 w-5 transform cursor-pointer text-red-500 "
        // onClick={() => handleConfirmarTipoVehiculo(itemData, solicitud)}
      />
    </ToolTipWrap>
  );
};
