import React, { useState } from 'react';
import { FaUserTag } from 'react-icons/fa';
import { connect } from 'react-redux';
import ModalSelectClient from './Modal';

const UserTagComponent = (props) => {
  const auth = props.auth;
  const [modalSelectClient, setModalSelectClient] = useState(false);
  const [cliente, setCliente] = useState(auth.data.selCliente);

  return (
    <>
      <h2
        onClick={() => setModalSelectClient(true)}
        className="flex-auto cursor-pointer pl-4 text-lg font-semibold  text-yellow-300 hover:text-yellow-200">
        <span className="inline-block flex-auto">
          <FaUserTag></FaUserTag>
        </span>
        <p
          title={cliente.TERCERO_RAZON_SOCIAL}
          className="inline-block flex-auto pl-1 ">
          {cliente.TERCERO_RAZON_SOCIAL.length > 35 ? cliente.TERCERO_RAZON_SOCIAL.slice(0, 35) + '...' : cliente.TERCERO_RAZON_SOCIAL}
        </p>
      </h2>
      {modalSelectClient ? (
        <ModalSelectClient
          setModalSelectClient={setModalSelectClient}
          setCliente={setCliente}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(UserTagComponent);
