import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Bono/Filter';
import TableBono from '../../../components/specific/Bono/Table';
import { ModalAgregarBono } from '../../../components/specific/Bono/Modal/agregarBono';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

const ViewBono = (props) => {
  const { modalAgregarBono } = props.modal; //setModalAgregarBono, createTrailer, updateTrailer, validate
  const { isLoadingTables } = props.actions;

  return (
    <div>
      <Nav />
      <Toaster />
      <div className="sm:px-6 lg:px-4 mx-6 py-12">
        <div>
          <label className="text-grey-400 text-2xl font-semibold leading-tight">Bonos</label>
        </div>
        <Filter modal={props.modal} />
        <div className="overflow-hidden rounded border-b border-gray-200 shadow">
          {isLoadingTables ? (
            <LoadingTables />
          ) : (
            <TableBono
              actions={props.actions}
              data={props.data}
            />
          )}
        </div>
      </div>
      {modalAgregarBono ? (
        <ModalAgregarBono
          modal={props.modal}
          actions={props.actions}
          endPoint={props.endPoint}
        />
      ) : null}
    </div>
  );
};

export default ViewBono;
