import React from 'react';
const TheadTableLugar = () => {
  return (
    <thead className="sticky top-0 z-10 bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className=" px-1 text-left text-sm font-semibold uppercase">Id.</th>
        <th className=" px-1 text-left text-sm font-semibold uppercase">Lugar</th>
        <th className=" px-1 text-left text-sm font-semibold uppercase">Alias</th>
        <th className=" px-1 text-left text-sm font-semibold uppercase">Estado</th>
      </tr>
    </thead>
  );
};
export default TheadTableLugar;
