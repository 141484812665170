import React from 'react';
const TheadTableCliente = () => {
  return (
    <thead className="  sticky top-0 z-10   bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className="px-1 text-left text-sm font-semibold uppercase">Cod. Int.</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">C.C. o Nit:</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Nombres</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Apellidos</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Razón Social</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Correo</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Teléfono </th>
        <th className="px-1 text-left text-sm font-semibold uppercase"></th>
      </tr>
    </thead>
  );
};
export default TheadTableCliente;
