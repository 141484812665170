import React from 'react';
import TheadTableSolicitudLogs from './TheadTableSolicitudLogs';
import TbodyTableSolicitudLogs from './TbodyTableSolicitudLogs';
const TableSolicitudLogs = (props) => {
  return (
    <table className="min-w-full bg-white ">
      <TheadTableSolicitudLogs />
      <TbodyTableSolicitudLogs />
    </table>
  );
};
export default TableSolicitudLogs;
