import React from 'react';
import TbodyTableLugar from './tbody';
import TheadTableLugar from './thead';
const TableLugar = (props) => {
  const { loadingTables } = props.data;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="z-0 w-full table-auto text-sm">
          <TheadTableLugar />
          <TbodyTableLugar actions={props.actions} />
        </table>
      )}
    </div>
  );
};
export default TableLugar;
