import { authLogout } from '../actions';
import { store } from '../store';

export const CheckUnauthenticated = (resp) => {
  //unauthenticated verify
  if (resp.error?.code === 'unauthenticated') {
    store.dispatch(authLogout());
  }

  return;
};

export const CheckPermission = (permission) => {
  const userPermissions = store.getState().auth.data?.user.rol;

  const isUserPermission = userPermissions?.filter((obj) => obj.ROL_NOMBRE === permission).length > 0;

  if (isUserPermission) {
    return true;
  }
  return false;
};

export const CheckParameter = (key = '', value = 0) => {
  const parameter = store.getState().auth.data.selCliente.parametro;
  //find no retorna [] retorna undefined
  const result = parameter.find((b) => b[key] === value && b.PARAMETRO_CLI_VALUE === '1');

  //para los siguientes valores regresa falso => (null,NaN,undefined,'',0,false)
  return !!result;
};
