import React from 'react';
import Nav from '../../../components/specific/Navigation';

import { useHistory } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { TitlesTables } from '../../../components/global/Styles/titles';
import { ButtonTable } from '../../../components/global/Styles/buttons';

import TableServicios from '../../../components/specific/Servicios/Table/TableServicios';
import { ModalNuevaSolicitud } from '../../../components/specific/Servicios/Modales/ModalNuevaSolicitud';
import { ModalAddItem } from '../../../components/specific/Servicios/Modales/ModalAddItem';
import ModalInfoServicio from '../../../components/specific/Servicios/Modales/ModalInfoServicio';
import { ModalRechazar } from '../../../components/specific/Servicios/Modales/ModalRechazar';
import { store } from '../../../store';
import { setSelSolicitudInit } from '../../../actions';
import ModalManifiestOReporte from '../../../components/specific/Servicios/Modales/ModalManifiestOReporte';
import ModalManifiesto from '../../../components/specific/Servicios/Modales/ModalManifiesto';
import ModalReporte from '../../../components/specific/Servicios/Modales/ModalReporte';
import LoadingTables from '../../../components/global/LoadingTables';
import ModalConfirmar from '../../../components/specific/Servicios/Modales/ModalConfirmar';

import ModalEjecucion from '../../../components/specific/Servicios/Modales/ModalSeguimiento/ModalEjecucion';

import ModalLogs from '../../../components/specific/Servicios/Modales/ModalLogs';
import ModalEvaluacion from '../../../components/specific/Servicios/Modales/ModalEvaluacion';
import { CheckParameter, CheckPermission } from '../../../utils/security';
import ModalNuevoAnticipo from '../../../components/specific/Servicios/Modales/ModalNuevoAnticipo';
import { ModalSiesaLog } from '../../../components/specific/Servicios/Modales/ModalSiesaLog';
import Pager from '../../../components/global/Pager';

const selectedTab =
  'relative block border-b-2 border-l border-r border-t-2  bg-gray-100 border-gray-200   p-4  font-medium text-gray-800 cursor-pointer rounded-t-md';
const noSelectedTab =
  ' border-l border-b-2 border-r border-t-2 border-gray-200  block p-4  font-medium text-gray-500 hover:text-gray-800 cursor-pointer rounded-t-md';
const checkIsTabSelected = (key, estadoAprobado) => {
  if (key === estadoAprobado) {
    return selectedTab;
  }
  return noSelectedTab;
};

const ViewServicio = (props) => {
  const {
    setNuevoServicio,
    nuevoServicio,
    modaladdItmen,
    setModaladdItmen,
    modalInfoServicio,
    setModalInfoServicio,
    modalRechazarSolicitud,
    setModalRechazarSolicitud,
    modalSeleccionarManifestoOReporte,
    setModalSeleccionarManifestoOReporte,
    modalManifiesto,
    setModalManifiesto,
    modalReporte,
    setModalReporte,
    modalConfirmar,
    setModalConfirmar,
    modalEjecucion,
    setModalEjecucion,
    modalLogs,
    setModalLogs,
    isModalEvaluacionVisible,
    setIsModalEvaluacionVisible,
    isModalNuevoAnticipoVisible,
    setIsModalNuevoAnticipoVisible,
    isModalSiesaLogVisible,
    setIsModalSiesaLogVisible,
  } = props.modales;
  const history = useHistory();
  const {
    handleAprobado,
    handlePorAprobar,
    handleCancelado,
    estadoAprobado,
    handleRechazado,
    handleReject,
    handleEdit,
    handleChangeTarifaUnidad,
    handleAddTarifa,
    handleDeleteDetalle,
    loadingTables,
    handleConfirmadas,
    handleEjecucion,
    handleFinalizadas,

    createAsignarBono,
    updateAsignarBono,
    getAsignarBono,
    handleEditBonoAsignado,
    handleDeleteBonoAsignado,
    setBonoAsignado,

    setTipoSolicitud,
  } = props.actions;

  const { solicitud, auth, siesaModalData } = props.data;

  const handleClick = (tipo) => {
    store.dispatch(setSelSolicitudInit());
    setTipoSolicitud(tipo);
    setNuevoServicio(true);
  };

  const { handleNextPage, handlePreviusPage } = props.pager;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />
      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>SOLICITUDES Y SERVICIOS</TitlesTables>
            <div className="flex-auto"></div>
            {CheckParameter('PARAMETRO_ID', 2) && CheckPermission('Solicitudes') ? (
              <ButtonTable
                className="mr-2"
                onClick={() => handleClick(0)}>
                Nueva Solicitud
              </ButtonTable>
            ) : null}
            <ButtonTable onClick={() => handleClick(1)}>Nuevo Servicio</ButtonTable>
          </div>

          {/* TABS */}
          <ul className=" flex text-center">
            {CheckParameter('PARAMETRO_ID', 2) ? (
              <li className="flex-1">
                <div
                  className={checkIsTabSelected(0, estadoAprobado)}
                  onClick={() => handlePorAprobar()}>
                  Por aprobar
                </div>
              </li>
            ) : null}
            <li className="flex-1">
              <div
                className={checkIsTabSelected(1, estadoAprobado)}
                onClick={() => handleAprobado()}>
                Nuevas / Aprobadas
              </div>
            </li>
            <li className="flex-1">
              <div
                className={checkIsTabSelected(4, estadoAprobado)}
                onClick={() => handleConfirmadas()}>
                Confirmadas
              </div>
            </li>
            {CheckParameter('PARAMETRO_ID', 2) && (
              <li className="flex-1">
                <div
                  className={checkIsTabSelected(2, estadoAprobado)}
                  onClick={() => handleCancelado()}>
                  Canceladas
                </div>
              </li>
            )}
            <li className="flex-1">
              <div
                className={checkIsTabSelected(3, estadoAprobado)}
                onClick={() => handleRechazado()}>
                Rechazadas
              </div>
            </li>
            <li className="flex-1">
              <div
                className={checkIsTabSelected(5, estadoAprobado)}
                onClick={() => handleEjecucion()}>
                En ejecución
              </div>
            </li>
            <li className="flex-1">
              <div
                className={checkIsTabSelected(6, estadoAprobado)}
                onClick={() => handleFinalizadas()}>
                Finalizadas
              </div>
            </li>
          </ul>
          {/* </div> */}

          {loadingTables && <LoadingTables />}
          <TableServicios
            endPoint={props.endPoint}
            data={{ estadoAprobado, solicitud, auth, loadingTables }}
            actions={props.actions}
            modals={{
              setModaladdItmen,
              setModalInfoServicio,
              setModalSeleccionarManifestoOReporte,
              setModalConfirmar,
              setModalEjecucion,
              setModalLogs,
              setModalReporte,
              setModalManifiesto,
              setModalRechazarSolicitud,
              setIsModalEvaluacionVisible,
              setIsModalNuevoAnticipoVisible,
            }}
          />
          <Pager
            data={solicitud}
            pager={{ handleNextPage, handlePreviusPage }}
          />
        </div>
      </div>
      {nuevoServicio && (
        <ModalNuevaSolicitud
          data={{ auth, setTipoSolicitud }}
          endPoint={props.endPoint}
          modales={{ setNuevoServicio }}
        />
      )}
      {modaladdItmen && (
        <ModalAddItem
          endPoint={props.endPoint}
          modales={{ setModaladdItmen }}
        />
      )}
      {modalInfoServicio && (
        <ModalInfoServicio
          endPoint={props.endPoint}
          modales={{ setModalInfoServicio }}
        />
      )}
      {modalRechazarSolicitud && (
        <ModalRechazar
          endPoint={props.endPoint}
          modales={{ setModalRechazarSolicitud }}
          data={props.data}
        />
      )}
      {modalSeleccionarManifestoOReporte && (
        <ModalManifiestOReporte
          modals={{
            setModalManifiesto,
            setModalSeleccionarManifestoOReporte,
            setModalReporte,
          }}
        />
      )}
      {modalManifiesto && (
        <ModalManifiesto
          setModalManifiesto={setModalManifiesto}
          data={props.data}
          endPoint={props.endPoint}
        />
      )}
      {modalConfirmar && (
        <ModalConfirmar
          setModalConfirmar={setModalConfirmar}
          data={props.data}
          endPoint={props.endPoint}
        />
      )}
      {modalEjecucion && (
        <ModalEjecucion
          setModalEjecucion={setModalEjecucion}
          data={props.data}
          endPoint={{ createAsignarBono, updateAsignarBono, getAsignarBono }}
          actions={{ handleEditBonoAsignado, handleDeleteBonoAsignado, setBonoAsignado, handleChangeTarifaUnidad }}
        />
      )}
      {modalLogs && (
        <ModalLogs
          setModalLogs={setModalLogs}
          data={props.data}
          endPoint={props.endPoint}
        />
      )}
      {isModalEvaluacionVisible && (
        <ModalEvaluacion
          setIsModalEvaluacionVisible={setIsModalEvaluacionVisible}
          data={props.data}
          endPoint={props.endPoint}
        />
      )}
      {modalReporte && (
        <ModalReporte
          setModalReporte={setModalReporte}
          data={props.data}
          endPoint={props.endPoint}
          actions={{ handleChangeTarifaUnidad, handleAddTarifa, handleDeleteDetalle }}
        />
      )}
      {isModalNuevoAnticipoVisible && (
        <ModalNuevoAnticipo
          setIsModalNuevoAnticipoVisible={setIsModalNuevoAnticipoVisible}
          data={props.data}
          endPoint={props.endPoint}
          actions={{ handleChangeTarifaUnidad, handleAddTarifa, handleDeleteDetalle }}
        />
      )}
      {isModalSiesaLogVisible && (
        <ModalSiesaLog
          data={siesaModalData}
          setIsModalSiesaLogVisible={setIsModalSiesaLogVisible}
          endPoint={props.endPoint}
        />
      )}
    </div>
  );
};

export default ViewServicio;
