import { formatterPeso, roundWithDecimals } from '../../../../../utils/format';

export const TableBonoTbody = (props) => {
  const rowVehiculo = props.data;

  return (
    <tbody className="">
      {rowVehiculo?.map((obj) => (
        <tr
          key={obj.nombre}
          className="border-b text-xs">
          <td className=" whitespace-nowrap px-1 text-left">{obj.SOLICITUD_BONO_FECHA}</td>
          <td className=" whitespace-nowrap px-1 text-left">{obj.TERCERO_NOMBRE_BUSQUEDA}</td>
          <td className=" whitespace-nowrap px-1 text-left">{obj.BONO_NOMBRE}</td>
          <td className=" whitespace-nowrap px-1 text-left">{'No'}</td>
          <td className=" whitespace-nowrap px-1 text-right">{formatterPeso(obj.BONO_VALOR)}</td>
          <td className=" whitespace-nowrap px-1 text-right">{roundWithDecimals(obj.DIF_PORCEN, 0)}</td>
        </tr>
      ))}
    </tbody>
  );
};
