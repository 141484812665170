import { fixOnlyDate } from '../utils/format';

const initialState = {
  isData: false,
  contrato: [],
  selContrato: null,
  pagination: null,
};

const initialContratoState = {
  selContrato: null,
};

export const contrato = (state = initialState, action) => {
  switch (action.type) {
    case 'setContrato': {
      if (action.value.status === 200) {
        const stateData = { contrato: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setContratoInit': {
      return initialState;
    }
    case 'setSelContrato': {
      const obj = {
        ...action.value,
        CONTRATO_FECHA_INICIO: fixOnlyDate(action.value.CONTRATO_FECHA_INICIO),
        CONTRATO_FECHA_FINAL: fixOnlyDate(action.value.CONTRATO_FECHA_FINAL),
        CONTRATO_ESTADO: action.value.CONTRATO_ESTADO == null ? 0 : action.value.CONTRATO_ESTADO,
      };

      const stateData = { selContrato: obj };

      return { ...state, ...stateData };
    }
    case 'setSelContratoInit': {
      return { ...state, ...initialContratoState };
    }

    default:
      //
      return state;
  }
};
