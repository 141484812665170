import Contrato from '../pages/Contrato';
import Dashboard from '../pages/Dashboard';
import LandingPage from '../pages/LandingPage';
import Marca from '../pages/Marca';
import Roles from '../pages/Roles';
import Servicios from '../pages/Servicios';
import Tarifa from '../pages/Tarifa';
import Tercero from '../pages/Tercero';
import TipoVehiculo from '../pages/TipoVehiculo';
import Trailer from '../pages/Trailer';
import Vehiculo from '../pages/Vehiculo';
import Zona from '../pages/Zona';
import Bono from '../pages/Bono';
import Cliente from '../pages/Cliente';
import Test from '../pages/Test';
import CentroOperacion from '../pages/CentroOperacion';
import UnidadNegocio from '../pages/UnidadNegocio';
import Regional from '../pages/Regional';
import Lugar from '../pages/Lugar';
import Anticipos from '../pages/Anticipos';
import ParametroGeneral from '../pages/ParametroGeneral';
import Cotizacion from '../pages/Cotizacion';

export const pages = {
  LandingPage: {
    component: LandingPage,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Landing Page',
    exact: true,
    path: '/',
  },
  Dashboard: {
    component: Dashboard,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Dashboard',
    exact: true,
    path: '/dashboard',
  },
  Roles: {
    component: Roles,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Roles',
    exact: true,
    path: '/roles',
  },
  Marca: {
    component: Marca,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Marca',
    exact: true,
    path: '/marca',
  },
  Vehiculo: {
    component: Vehiculo,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Vehiculo',
    exact: true,
    path: '/vehiculo',
  },
  TipoVehiculo: {
    component: TipoVehiculo,
    Icon: new Error('this route is not for nav-ing'),
    name: 'TipoVehiculo',
    exact: true,
    path: '/tipoVehiculo',
  },
  Zona: {
    component: Zona,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Zona',
    exact: true,
    path: '/zona',
  },
  Tecero: {
    component: Tercero,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Tercero',
    exact: true,
    path: '/usuario',
  },
  Contrato: {
    component: Contrato,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Contrato',
    exact: true,
    path: '/contrato',
  },
  Test: {
    component: Test,
    Icon: new Error('this route is not for nav-ing'),
    name: 'test',
    exact: true,
    path: '/test',
  },
  Trailer: {
    component: Trailer,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Trailer',
    exact: true,
    path: '/trailer',
  },
  Tarifa: {
    component: Tarifa,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Tarifa',
    exact: true,
    path: '/tarifa',
  },
  Servicios: {
    component: Servicios,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Servicios',
    exact: true,
    path: '/servicios',
  },
  bono: {
    component: Bono,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Bonos',
    exact: true,
    path: '/bono',
  },
  cliente: {
    component: Cliente,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Clientes',
    exact: true,
    path: '/cliente',
  },
  CentroOperacion: {
    component: CentroOperacion,
    Icon: new Error('this route is not for nav-ing'),
    name: 'CentroOperacion',
    exact: true,
    path: '/centroOperacion',
  },
  UnidadNegocio: {
    component: UnidadNegocio,
    Icon: new Error('this route is not for nav-ing'),
    name: 'UnidadNegocio',
    exact: true,
    path: '/unidadNegocio',
  },
  Regional: {
    component: Regional,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Regional',
    exact: true,
    path: '/regional',
  },
  Lugar: {
    component: Lugar,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Lugar',
    exact: true,
    path: '/lugar',
  },
  Anticipos: {
    component: Anticipos,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Anticipos',
    exact: true,
    path: '/anticipos',
  },
  ParametroGeneral: {
    component: ParametroGeneral,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Parametros Generales',
    exact: true,
    path: '/parametroGeneral',
  },
  LandingPageFromMail: {
    component: LandingPage,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Landing Page From Mail',
    exact: true,
    path: '/mail/:tokenData',
  },
  Cotizacion: {
    component: Cotizacion,
    Icon: new Error('this route is not for nav-ing'),
    name: 'Cotizaciones',
    exact: true,
    path: '/cotizacion',
  },
};
