import React, { useState, useEffect } from 'react';

// import Map from '../../../../../img/Map.jpg';

import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast, ErrorToast, OkToast } from '../../../Toast';

import toast from 'react-hot-toast';

import 'react-loading-skeleton/dist/skeleton.css';
import { ModalTarifa } from './ModalTarifa';
import { store } from '../../../../../store';
import { setSelSolicitud, setSelTipoVehiculo } from '../../../../../actions';

import { TableTarifa } from '../ModalSeguimiento/TableTarifa';

export const PanelCotizacionTarifa = ({ handleAddTarifa, handleChangeTarifaUnidad, data, isReadOnly = false }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [isModalTarifaVisible, setIsModalTarifaVisible] = useState(false);

  const [solicitudDetalle, setSolicitudDetalle] = useState([]);

  const { get, post, put, DELETE } = useApiClient();

  const handleShowTarifaModal = (isVisible = true) => {
    setIsModalTarifaVisible(isVisible);
  };

  const getSolicitudDetalle = async () => {
    const result = await get(`/solicitudDetalle?SOLICITUD_VEHICULO_TIPO_ID=${data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID}`);

    if (!validateSuccess2xx(result)) return CustomToast({ result });
    setSolicitudDetalle(result.data);
  };

  const createSolicitudDetalle = async (form) => {
    try {
      const result = await post(
        '/solicitudDetalle',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      OkToast({ result, message: 'Item Agregado!' });

      store.dispatch(setSelTipoVehiculo(result.solicitudVehiculoTipo[0]));

      // store.dispatch(setSelSolicitud(objSolicitud));

      getSolicitudDetalle();
      handleShowTarifaModal(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const deleteSolicitudDetalle = async (rowID) => {
    try {
      const result = await DELETE(`/solicitudDetalle/${rowID}`);

      if (!validateSuccess2xx(result)) {
        return CustomToast({ result });
      }

      OkToast({ result, message: 'Item eliminado!' });

      store.dispatch(setSelTipoVehiculo(result.solicitudVehiculoTipo[0]));

      getSolicitudDetalle();
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const handleDeleteDetalle = (item) => {
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el registro?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteSolicitudDetalle(item.SOLICITUD_DETALLE_ID);
          }}>
          Si
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  };

  // useEffect(() => {
  //   getSolicitudDetalle();
  // }, [data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID]);

  return (
    <>
      <TableTarifa
        data={{ solicitudDetalle }}
        modals={{ handleShowTarifaModal }}
        actions={{ handleAddTarifa, handleChangeTarifaUnidad, handleDeleteDetalle }}
        isReadOnly={isReadOnly}></TableTarifa>

      {/* <TableTarifaOld
        data={data}
        actions={{ handleAddTarifa, handleChangeTarifaUnidad, handleDeleteDetalle }}></TableTarifaOld> */}
      {isModalTarifaVisible ? (
        <ModalTarifa
          modals={{ handleShowTarifaModal }}
          endPoints={{ createSolicitudDetalle }}
          solicitud={data.selSolicitud}
          data={{ selSolicitudVehiculoTipo: data.selSolicitudVehiculoTipo, tarifaUnidadOptions: data.tarifaUnidadOptions }}
        />
      ) : null}
    </>
  );
};
