import React from 'react';

import { store } from '../../../../store';
import { setSelSolicitud, setSelTipoVehiculo } from '../../../../actions';
import { CheckPermission, CheckParameter } from '../../../../utils/security';
import ReactTooltip from 'react-tooltip';

import '@szhsin/react-menu/dist/index.css';

import { WrenchScrewdriverIcon, ClipboardDocumentListIcon, DocumentIcon, CurrencyDollarIcon, BanknotesIcon, Bars4Icon } from '@heroicons/react/24/outline';

import { ErrorToast } from '../../Toast';
import { formatterPeso } from '../../../../utils/format';

const solicitudEstados = {
  Nueva: 0,
  Aprobada: 1,
  Cancelada: 2,
  Rechazada: 3,
  Confirmada: 4,
  Ejecución: 5,
  Finalizada: 6,
};

const ToolTipWrap = ({ itemID, componentPrefix, message, children }) => {
  return (
    <>
      <ReactTooltip
        className="margin-10"
        id={`${componentPrefix}${itemID}`}
        type="dark"
        effect="float">
        <p>{message}</p>
      </ReactTooltip>
      {children}
    </>
  );
};

const TableAnticipoTbody = (props) => {
  const { aprobarSolicitud, getServicioLogs } = props.endPoint;
  const { estadoAprobado } = props.data;
  const { setIsModalAnticipoVisible, setIsModalAnticipoConsultaVisible } = props.modals;
  const { handleEdit } = props;

  const { solicitud, auth } = props.data;

  const TERCERO_ID = auth.data.user.TERCERO_ID;

  const handleReporteTrabajoTipoVehiculo = (objTipoVehiculo, objSolicitud) => {
    if (estadoAprobado !== 4) return;

    store.dispatch(setSelTipoVehiculo(objTipoVehiculo));
    store.dispatch(setSelSolicitud(objSolicitud));
    // setModalReporte(true);
  };

  const ReporteTrabajoIcon = ({ itemData, solicitudEstado, solicitud }) => {
    if (solicitudEstado !== solicitudEstados['Confirmada']) return;

    if (itemData.SOLICITUD_VEHICULO_REPORTE_ESTADO === 0)
      return (
        <ToolTipWrap
          itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
          componentPrefix={'tooltipReporteTrabajo'}
          message={itemData.SOLICITUD_OT_ESTADO_DESCRIPCION}>
          <WrenchScrewdriverIcon
            data-tip
            data-for={`tooltipReporteTrabajo${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
            className="hover:fill-blue-500 h-5 w-5 transform cursor-pointer  text-yellow-500 transition  duration-300 hover:scale-110"
            onClick={() => handleReporteTrabajoTipoVehiculo(itemData, solicitud)}
          />
        </ToolTipWrap>
      );

    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'tooltipReporteTrabajo'}
        message={itemData.SOLICITUD_OT_ESTADO_DESCRIPCION}>
        <ClipboardDocumentListIcon
          className="h-5 w-5 text-green-500"
          // onClick={() => handleSelTipoVehiculo(itemData, solicitud)}
        />
      </ToolTipWrap>
    );
  };

  const handleAnticipoClick = (objTipoVehiculo, objSolicitud, objCargo) => {
    const withCargo = { ...objTipoVehiculo, anticipo_cargo: objCargo };

    store.dispatch(setSelTipoVehiculo(withCargo));
    store.dispatch(setSelSolicitud(objSolicitud));

    const isAprobadorEmp = CheckPermission('Aprobador Anticipos');

    const isAprobadorExt = CheckPermission('Aprobador Anticipos Ext');

    const isAprobadorFinal = CheckPermission('Aprobador Anticipos Final');

    const isAparejadorEmployee = objTipoVehiculo.APAREJADOR_ES_EMPLEADO === 1;

    const isConductorEmployee = objTipoVehiculo.CONDUCTOR_ES_EMPLEADO === 1;

    const isFase1ConductorAproved = objTipoVehiculo.CONDUCTOR_VALOR_ESTADO > 1;

    const isFase1AparejadorAproved = objTipoVehiculo.APAREJADOR_VALOR_ESTADO > 1;

    if ((isAprobadorEmp || isAprobadorExt) && isFase1ConductorAproved && objCargo === 'C') {
      // ErrorToast('Error: ', 'Conductor Ya fue aprobado...');
      setIsModalAnticipoConsultaVisible(true);
      return;
    }
    if ((isAprobadorEmp || isAprobadorExt) && isFase1AparejadorAproved && objCargo === 'A') {
      // ErrorToast('Error: ', 'Aparejador Ya fue aprobado...');
      setIsModalAnticipoConsultaVisible(true);
      return;
    }

    if (objCargo === 'C' && isConductorEmployee && isAprobadorEmp) {
      setIsModalAnticipoVisible(true);
      return;
    }

    if (objCargo === 'C' && !isConductorEmployee && isAprobadorExt) {
      setIsModalAnticipoVisible(true);
      return;
    }

    if (objCargo === 'A' && isAparejadorEmployee && isAprobadorEmp) {
      setIsModalAnticipoVisible(true);
      return;
    }

    if (objCargo === 'A' && !isAparejadorEmployee && isAprobadorExt) {
      setIsModalAnticipoVisible(true);
      return;
    }

    // if (isAprobadorFinal && isFase1ConductorAproved && isFase1AparejadorAproved) {
    //   setIsModalAnticipoConsultaVisible(true);
    //   return;
    // }

    ErrorToast('Error: ', 'No tiene permisos para realizar esta acción...');
  };

  const handleFinalConsulta = (objTipoVehiculo, objSolicitud, objCargo) => {
    const withCargo = { ...objTipoVehiculo, anticipo_cargo: objCargo };

    store.dispatch(setSelTipoVehiculo(withCargo));
    store.dispatch(setSelSolicitud(objSolicitud));

    const isAprobadorFinal = CheckPermission('Aprobador Anticipos Final');
    const isAdmin = CheckPermission('Coordinador') || CheckPermission('Administrador');

    const isFase1ConductorAproved = objTipoVehiculo.CONDUCTOR_VALOR_ESTADO === 1;

    const isFase1AparejadorAproved = objTipoVehiculo.APAREJADOR_VALOR_ESTADO === 1;

    if (isAprobadorFinal || isAdmin) {
      setIsModalAnticipoConsultaVisible(true);
      return;
    }

    ErrorToast('Error: ', 'No tiene permisos para realizar esta acción...');
  };

  const handleFinalAnticipoClick = (objTipoVehiculo, objSolicitud, objCargo) => {
    const withCargo = { ...objTipoVehiculo, anticipo_cargo: objCargo };

    store.dispatch(setSelTipoVehiculo(withCargo));
    store.dispatch(setSelSolicitud(objSolicitud));

    const isAprobadorFinal = CheckPermission('Aprobador Anticipos Final');

    const isFase1ConductorAproved = objTipoVehiculo.CONDUCTOR_VALOR_ESTADO === 1;

    const isFase1AparejadorAproved = objTipoVehiculo.APAREJADOR_VALOR_ESTADO === 1;

    if (isAprobadorFinal && isFase1ConductorAproved && objCargo === 'C') {
      setIsModalAnticipoVisible(true);
      return;
    }

    if (isAprobadorFinal && isFase1AparejadorAproved && objCargo === 'A') {
      setIsModalAnticipoVisible(true);
      return;
    }

    ErrorToast('Error: ', 'No tiene permisos para realizar esta acción...');
  };

  const getAnticipoConductorColor = (item) => {
    const isAprobadorEmp = CheckPermission('Aprobador Anticipos');

    const isAprobadorExt = CheckPermission('Aprobador Anticipos Ext');

    const isAprobadorFinal = CheckPermission('Aprobador Anticipos Final');

    if (isAprobadorEmp || isAprobadorExt) {
      if (Number(item.CONDUCTOR_VALOR_ESTADO) === 1) return 'text-green-500';
    }

    // if (isAprobadorFinal && Number(item.CONDUCTOR_VALOR_ESTADO) === 2) return 'text-green-500';
    if (Number(item.CONDUCTOR_VALOR_ESTADO) === 2) return 'text-green-500';

    return 'text-yellow-500';
  };
  const getAnticipoAparejadorColor = (item) => {
    const isAprobadorEmp = CheckPermission('Aprobador Anticipos');

    const isAprobadorExt = CheckPermission('Aprobador Anticipos Ext');

    const isAprobadorFinal = CheckPermission('Aprobador Anticipos Final');

    if (isAprobadorEmp || isAprobadorExt) {
      if (Number(item.APAREJADOR_VALOR_ESTADO) === 1) return 'text-green-500';
    }

    // if (isAprobadorFinal && Number(item.APAREJADOR_VALOR_ESTADO) === 2) return 'text-green-500';
    if (Number(item.APAREJADOR_VALOR_ESTADO) === 2) return 'text-green-500';

    return 'text-yellow-500';
  };

  const TipoVehiculoCell = ({ itemData: obj }) => {
    if (obj.solicitud_vehiculo_tipo === undefined) {
      return <td className={`px-2`}>...</td>;
    }
    const isAdmin = CheckPermission('Coordinador') || CheckPermission('Administrador');

    const isAprobadorFinal = CheckPermission('Aprobador Anticipos Final');

    return (
      <td className="whitespace-nowrap px-2">
        {obj.solicitud_vehiculo_tipo.map((tipo) => (
          <div
            className="flex flex-col border-b pb-2"
            key={tipo.SOLICITUD_VEHICULO_TIPO_ID}>
            <section className="flex gap-2">
              {tipo.VEHICULO_TIPO_DESCRIPCION} <p className=" text-green-500">{tipo.VEHICULO_PLACA}</p>
            </section>
            <section className="flex gap-2">
              C: {tipo.CONDUCTOR_NOMBRE?.substring(0, 40)} <p className="text-red-500">{tipo.CONDUCTOR_ES_EMPLEADO === 0 && '(Ext)'}</p>
              <p className=" flex-auto"></p> <p className=" text-yellow-500">{formatterPeso(tipo.CONDUCTOR_VALOR)}</p>{' '}
              <p className={`font-bold ${getAnticipoConductorColor(tipo)} `}>{formatterPeso(tipo.CONDUCTOR_ANTICIPO)}</p>
              {isAprobadorFinal ? (
                <>
                  <BanknotesIcon
                    className="h-5 w-5  cursor-pointer text-green-600"
                    onClick={() => handleFinalAnticipoClick(tipo, obj, 'C')}
                  />
                </>
              ) : (
                <CurrencyDollarIcon
                  className="h-5 w-5  cursor-pointer text-green-600"
                  onClick={() => handleAnticipoClick(tipo, obj, 'C')}
                />
              )}
              <Bars4Icon
                className="h-5 w-5  cursor-pointer text-green-600"
                onClick={() => handleFinalConsulta(tipo, obj, 'C')}
              />
            </section>
            {tipo.APAREJADOR_NOMBRE && (
              <section className="flex gap-2">
                A: {tipo.APAREJADOR_NOMBRE?.substring(0, 40)} <p className="text-red-500">{tipo.APAREJADOR_ES_EMPLEADO === 0 && '(Ext)'}</p>{' '}
                <p className=" flex-auto"></p>
                <p className="  text-yellow-500 ">{formatterPeso(tipo.APAREJADOR_VALOR)}</p>{' '}
                <p className={`font-bold ${getAnticipoAparejadorColor(tipo)} `}>{formatterPeso(tipo.APAREJADOR_ANTICIPO)}</p>
                {isAprobadorFinal ? (
                  <>
                    <BanknotesIcon
                      className="h-5 w-5 cursor-pointer text-green-600"
                      onClick={() => handleFinalAnticipoClick(tipo, obj, 'A')}
                    />
                  </>
                ) : (
                  <CurrencyDollarIcon
                    className="h-5 w-5 cursor-pointer text-green-600"
                    onClick={() => handleAnticipoClick(tipo, obj, 'A')}
                  />
                )}
                <Bars4Icon
                  className="h-5 w-5 cursor-pointer text-green-600"
                  onClick={() => handleFinalConsulta(tipo, obj, 'A')}
                />
              </section>
            )}
          </div>
        ))}
      </td>
    );
  };

  return (
    <tbody className="">
      {solicitud.data?.map((obj) => (
        <tr
          key={obj.SOLICITUD_ID}
          className="border-b even:bg-gray-50">
          <td className="w-2 "></td>
          <td className="px-2 text-left">{obj.CLIENTE_NOMBRE}</td>
          <td className="  whitespace-nowrap px-2 text-left">{obj.SOLICITUD_CONSECUTIVO_INT}</td>

          <td className=" px-2">{obj.unidad_negocio[0]?.NEGOCIO_NOMBRE}</td>
          <TipoVehiculoCell itemData={obj} />
          <td className=" px-2 text-right">{formatterPeso(obj.SOLICITUD_TOTAL)}</td>
          <td className=" ">
            {obj.ORIGEN} - {obj.DESTINO}
          </td>

          <td className=" whitespace-nowrap px-2">{obj.SOLICITUD_FECHA}</td>
          <td className="  whitespace-nowrap px-2">{obj.CREATED_AT}</td>
          <td className=" px-2">{obj.CONTRATO_ZONA}</td>
          {CheckParameter('PARAMETRO_ID', 2) ? <td className="px-2">{obj.APRUEBA}</td> : null}
          <td className=" px-2">
            <DocumentIcon
              data-tip
              data-for={`tooltipDescripcion${obj.SOLICITUD_ID}`}
              className="hover:fill-blue-500 h-5 w-5 transform  cursor-pointer transition  duration-300 hover:scale-110"
              // onClick={() => handleSelTipoVehiculo(obj, solicitud)}
            />
            <ReactTooltip
              className="margin-10"
              id={`tooltipDescripcion${obj.SOLICITUD_ID}`}
              type="dark">
              <div>
                <p>{obj.SOLICITUD_DESCRIPCION}</p>
              </div>
            </ReactTooltip>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableAnticipoTbody;
