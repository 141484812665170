import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/CentroOperacion/Filter';
import { ModalAgregarCentroOperacion } from '../../../components/specific/CentroOperacion/Modales/agregarCentroOperacion.js';
import TableCentroOperacions from '../../../components/specific/CentroOperacion/Table';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

const ViewCentroOperacion = (props) => {
  const { modalAgregarCentroOperacion, setModalModalAgregarCentroOperacion, createCentroOperacion, validate } = props.modal;
  const { loadingTables } = props.actions;

  const { getRoles } = props;
  return (
    <div>
      <Nav />
      <Toaster />
      <div className="md:px-20 w-full py-8">
        <div>
          <h2 className="... mx-8 text-2xl font-semibold leading-tight">Centro de Operaciones</h2>
        </div>
        <Filter setModalModalAgregarCentroOperacion={setModalModalAgregarCentroOperacion} />
        <div className="... mx-8 overflow-hidden rounded border-b border-gray-200 shadow">
          {loadingTables ? <LoadingTables /> : <TableCentroOperacions actions={props.actions} />}
        </div>
      </div>
      {modalAgregarCentroOperacion ? (
        <ModalAgregarCentroOperacion
          endPoint={props.endPoint}
          agregarCentroOperacion={{ createCentroOperacion, validate }}
          setModalModalAgregarCentroOperacion={setModalModalAgregarCentroOperacion}
          getRoles={getRoles}
        />
      ) : null}
    </div>
  );
};

export default ViewCentroOperacion;
