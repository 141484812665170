import React from 'react';
import ServiciosTbody from './ServiciosTbody';
import ServiciosThead from './ServiciosThead';

const TableServicios = (props) => {
  // const { handleEdit, handleReject } = props.actions;
  const { loadingTables } = props.data;

  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <ServiciosThead data={props.data} />
          <ServiciosTbody
            endPoint={props.endPoint}
            data={props.data}
            modals={props.modals}
            actions={props.actions}
            // handleEdit={handleEdit}
            // handleReject={handleReject}
          />
        </table>
      )}
    </div>
  );
};
export default TableServicios;
