import React, { useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { PanelVehiculoNovedades } from './PanelVehiculoNovedades';
import { PanelVehiculoBonos } from './PanelVehiculoBonos';
import { PanelVehiculoTarifa } from './PanelVehiculoTarifa';

const selectedTab =
  'relative block border-b-2 border-l border-r border-t-2  bg-gray-100 border-gray-200   p-1  font-medium text-gray-800 cursor-pointer rounded-t-md';
const noSelectedTab =
  'border-l border-b-2 border-r border-t-2 border-gray-200  block p-1  font-medium text-gray-500 hover:text-gray-800 cursor-pointer rounded-t-md';
const checkIsTabSelected = (key, estadoAprobado) => {
  if (key === estadoAprobado) {
    return selectedTab;
  }
  return noSelectedTab;
};

const panelVehiculoTabs = {
  1: 'Novedades',
  2: 'Bonos',
  3: 'Tarifa',
};

export const PanelVehiculo = ({
  handleAddTarifa,
  bonoAsignado,
  handleDeleteBonoAsignado,
  handleEditBonoAsignado,
  onSelectUser,
  usuario,

  setNOVEDAD_TIPO_ID,
  handleChangeTarifaUnidad,
  handleDeleteDetalle,
  data,
  modals,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  console.log('activeTab: ', activeTab);

  return (
    <div className="w-full">
      {/* <Skeleton count={5} /> */}
      <div>
        <div className="mt-2 bg-white">
          <ul className=" flex text-center">
            {Object.keys(panelVehiculoTabs).map((tabID) => (
              <li
                className="flex-1"
                key={tabID}>
                <div
                  className={checkIsTabSelected(tabID, activeTab)}
                  onClick={() => setActiveTab(tabID)}>
                  {panelVehiculoTabs[tabID]}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* {activeTab === 'mapa' && <img src={Map} />} */}

        {activeTab === '1' && (
          <PanelVehiculoNovedades
            setNOVEDAD_TIPO_ID={setNOVEDAD_TIPO_ID}
            modals={modals}
            data={data}
          />
        )}

        {activeTab === '2' && (
          <PanelVehiculoBonos
            modals={modals}
            data={data}
          />
        )}

        {activeTab === '3' && (
          <PanelVehiculoTarifa
            handleAddTarifa={handleAddTarifa}
            handleChangeTarifaUnidad={handleChangeTarifaUnidad}
            handleDeleteDetalle={handleDeleteDetalle}
            data={data}
            modals={modals}
          />
        )}
      </div>
    </div>
  );
};
