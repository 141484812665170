import React from 'react';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../../RFF/FormStateToRedux';
import { connect } from 'react-redux';

import Select from 'react-select';
import { ButtonTable } from '../../../../global/Styles/buttons';
import TableUsuariosEnRoles from './Table';
import { DivTableUserRoles } from './styles';

export const ModalUsuarios = (props) => {
  const { setModalAgregarRolPersonas } = props;
  const { createRolinTercero } = props;
  const { collaboratorOptions, rolesOptions, handleDeleteTerceroInRol } = props.actions;
  const { tercerosRol } = props.data;

  const setTERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const handleClose = () => {
    setModalAgregarRolPersonas(false);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative w-full max-w-4xl rounded-lg bg-white shadow-lg ">
        <div>
          <DivTableUserRoles>
            <TableUsuariosEnRoles
              tercerosRol={tercerosRol}
              handleDeleteTerceroInRol={handleDeleteTerceroInRol}
            />
          </DivTableUserRoles>
        </div>
        <Form
          onSubmit={createRolinTercero}
          mutators={{
            setTERCERO_ID,
          }}
          initialValues={{ ROL_ID: props.roles.selRoles.ROL_ID }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="-mt-4 flex-auto justify-center p-5 text-left">
                <FormStateToRedux form="frmAgregarRolEnTercero" />
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <p className="text-sm text-gray-800">Seleccione Usuario</p>
                    {/* +++ SELECT +++ */}
                    {collaboratorOptions.length > 0 ? (
                      <Select
                        options={collaboratorOptions}
                        name="selectOrigen"
                        onChange={form.mutators.setTERCERO_ID}
                      />
                    ) : (
                      <div className=" flex items-center justify-center ">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="TERCERO_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                    {/* --- SELECT --- */}
                  </div>
                  <div className="-mb-4 w-1/2">
                    <div className="mt-5 flex space-x-4">
                      <div className="w-1/2">
                        <ButtonTable
                          className="w-full"
                          onClick={handleClose}>
                          Cerrar
                        </ButtonTable>
                      </div>
                      <div className="w-1/2">
                        <ButtonTable
                          className="w-full"
                          type="submit"
                          disabled={submitting || pristine}>
                          Agregar
                        </ButtonTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles };
};

//export default Projects;
export default connect(mapStateToProps)(ModalUsuarios);
