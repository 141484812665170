import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Tercero/Filter';
import TableTercero from '../../../components/specific/Tercero/Table';
import Pager from '../../../components/global/Pager';
import { ModalAgregarTercero } from '../../../components/specific/Tercero/Modales/agregarTercero';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import { connect } from 'react-redux';

import { TitlesTables } from '../../../components/global/Styles/titles';
import { ButtonTable } from '../../../components/global/Styles/buttons';

const ViewTercero = (props) => {
  const { isModalAgregarTerceroVisible } = props.modal;
  const { getRoles } = props;
  const { handleShowAddNewModal } = props.actions;
  const { loadingTables, tercero } = props.data;

  const { handleNextPage, handlePreviusPage } = props.pager;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'bg-gray-500 p-2 rounded-md text-white font-bold text-sm ',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>USUARIOS</TitlesTables>
            <div className="flex-auto"></div>
            <Filter actions={props.actions} />
            <ButtonTable onClick={handleShowAddNewModal}>Agregar</ButtonTable>
          </div>

          {loadingTables && <LoadingTables />}
          <TableTercero
            actions={props.actions}
            endPoint={props.endPoint}
            data={props.data}
          />
          {/* START PAGER */}

          <Pager
            data={tercero}
            pager={{ handleNextPage, handlePreviusPage }}
          />

          {/* END PAGER */}
        </div>
      </div>

      {isModalAgregarTerceroVisible && (
        <ModalAgregarTercero
          endPoint={props.endPoint}
          actions={props.actions}
          // agregarTercero={{ createTercero, validate }}
          handleShowAddNewModal={handleShowAddNewModal}
          getRoles={getRoles}
          data={props.data}
        />
      )}
    </div>
  );
};

export default ViewTercero;
