import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { fixOnlyDateForTable } from '../../../../utils/format';
const TbodyTableMarcas = (props) => {
  const infoMarca = props.marca.marca;
  const { handleDeleteMarca, handleEdit } = props.actions;

  useEffect(() => {}, []);

  return (
    <tbody className="text-gray-700">
      {infoMarca.map((obj, index) => (
        <tr key={index}>
          <td className="w-1/3 px-4 py-3 text-left">{obj.MARCA_ID}</td>
          <td className="w-1/3 px-4 py-3 text-left">{obj.MARCA_DESCRIPCION}</td>
          <td className="px-4 py-3 text-left">
            <a className="hover:text-blue-500">{fixOnlyDateForTable(obj.UPDATED_AT)}</a>
          </td>
          <td className="px-4 py-3 text-left">{fixOnlyDateForTable(obj.CREATED_AT)}</td>
          <td className="flex space-x-2 px-4 py-3 text-left">
            <Edit
              className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleEdit(obj)}
            />
            <Trash
              className="hover:fill-blue-500 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleDeleteMarca(obj.MARCA_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { marca: state.marca };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableMarcas);
