import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useApiClient, validateSuccess2xx } from '../../clients/apiAuth';

import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import ViewServicio from './ViewServicios';
import {
  setSelSolicitud,
  setSelSolicitudLog,
  setSolicitud,
  setSolicitudInit,
  setSelAsignarBono,
  setAsignarSelBonoInit,
  setTipoVehiculoInit,
} from '../../actions';
import { store } from '../../store';

import { CheckParameter } from '../../utils/security';

// import toast from 'react-hot-toast';

const Servicios = (props) => {
  const [nuevoServicio, setNuevoServicio] = useState(false);
  const [modaladdItmen, setModaladdItmen] = useState(false);
  const [modalInfoServicio, setModalInfoServicio] = useState(false);
  const [modalRechazarSolicitud, setModalRechazarSolicitud] = useState(false);
  const [modalManifiesto, setModalManifiesto] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);
  const [modalEjecucion, setModalEjecucion] = useState(false);
  const [modalLogs, setModalLogs] = useState(false);
  const [isModalEvaluacionVisible, setIsModalEvaluacionVisible] = useState(false);
  const [isModalNuevoAnticipoVisible, setIsModalNuevoAnticipoVisible] = useState(false);

  const [modalReporte, setModalReporte] = useState(false);
  const [modalConfirmar, setModalConfirmar] = useState(false);
  const [modalSeleccionarManifestoOReporte, setModalSeleccionarManifestoOReporte] = useState(false);

  const [idSolicitud, setIdSolicitud] = useState('');
  const [estadoAprobado, setEstadoAprobado] = useState(CheckParameter(props.auth?.data.selCliente.parametro, 'PARAMETRO_ID', 2) ? 0 : 1);
  const [solicitudResult, setSolicitudResult] = useState([]);
  const [vehiculoTipoOptions, setVehiculoTipoOptions] = useState([]);
  // const [vehiculoOptions, setVehiculoOptions] = useState([]);
  const [trailerOptions, setTrailerOptions] = useState([]);
  const [conductorOptions, setConductorOptions] = useState([]);

  const [aparejadorOptions, setAparejadorOptions] = useState([]);

  const [modalAsignarBono, setModalAsignarBono] = useState(false);
  const [bonoAsignado, setBonoAsignado] = useState([]);

  const [tipoSolicitud, setTipoSolicitud] = useState(1);

  const [isModalSiesaLogVisible, setIsModalSiesaLogVisible] = useState(false);
  const [siesaModalData, setSiesaModalData] = useState({});

  const handleShowSiesaLog = (row, isSendFail) => {
    console.log('handleShowSiesaLog: ', row, isSendFail);

    setSiesaModalData({
      row,
      isSendFail,
      isRNDC: false,
    });
    setIsModalSiesaLogVisible(true);
  };

  const handleShowSiesaLogRNDC = (row, isSendFail) => {
    console.log('handleShowSiesaLogRNDC: ', row, isSendFail);

    setSiesaModalData({
      row,
      isSendFail,
      isRNDC: true,
    });
    setIsModalSiesaLogVisible(true);
  };

  const selSolicitudVehiculoTipoID = props.tipoVehiculo.selTipoVehiculo?.SOLICITUD_VEHICULO_TIPO_ID;

  const { get, post, put } = useApiClient();

  const [currentPage, setCurrentPage] = useState(
    props.Solicitud.pagination === null || props.Solicitud.pagination === undefined ? 1 : props.Solicitud.pagination.currentPage
  );

  const handleNextPage = (page) => {
    const totalPages = props.Solicitud.pagination?.totalPages || 0;

    if (totalPages === 0) {
      setCurrentPage(1);
      return;
    }

    if (currentPage === totalPages) return;

    setCurrentPage(page ? page : currentPage + 1);
    getSolicitud(estadoAprobado, page ? page : currentPage + 1);
  };
  const handlePreviusPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
    getSolicitud(estadoAprobado, currentPage - 1);
  };

  const getSolicitud = async (estado = estadoAprobado, pageInd = currentPage) => {
    setLoadingTables(true);
    try {
      const result = await get(
        `/solicitud?page=${pageInd}&TERCERO_ID=${props.auth.data.user.TERCERO_ID}&SOLICITUD_APROBADA=${estado}&CLIENTE_ID=${props.auth.data.selCliente.TERCERO_ID}`
      );
      setLoadingTables(false);

      // if (!validateSuccess2xx(result)) return CustomToast({ result });
      if (!validateSuccess2xx(result)) {
        store.dispatch(setSolicitudInit(result));
        return;
      }

      store.dispatch(setSolicitud(result));
    } catch (error) {
      // console.log('Error:', error);
      ErrorToast('Error:', error);
    }
  };
  const getDocumentoPdf = async (item) => {
    try {
      const result = await get(`/documento?SOLICITUD_ID=${item.SOLICITUD_ID}&format=pdf`, 'application/pdf');

      if (result.type !== 'application/pdf') throw new Error('Documento no disponible...');

      const file = new Blob([result], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      ErrorToast('Error:', error.message);
    }
  };
  const getDocumentoFinalPdf = async (item) => {
    try {
      const result = await get(`/documentoFinal?SOLICITUD_ID=${item.SOLICITUD_ID}&format=pdf`, 'application/pdf');

      if (result.type !== 'application/pdf') throw new Error('Documento no disponible...');

      const file = new Blob([result], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      ErrorToast('Error:', error.message);
    }
  };

  const getTipoVehículo = async () => {
    try {
      const result = await get(`/vehiculoTipo`);

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      const lst = result.data.map((row) => ({
        label: `${row.VEHICULO_TIPO_DESCRIPCION}`,
        value: row.VEHICULO_TIPO_ID,
      }));
      setVehiculoTipoOptions(lst);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const getTrailer = async () => {
    const result = await get(`/trailer`);

    if (!validateSuccess2xx(result)) return CustomToast({ result });

    const lst = result.data.map((row) => ({
      label: `${row.VEHICULO_PLACA}`,
      value: row.VEHICULO_ID,
      propietario: row.TERCERO_PROPIETARIO_NOMBRE,
      tenedor: row.TERCERO_TENEDOR_NOMBRE,
      propio: row.VEHICULO_PROPIO,
    }));

    const withSelectNone = [
      {
        label: 'Ninguno',
        value: '',
      },
      ...lst,
    ];

    setTrailerOptions(withSelectNone);
  };

  const getConductor = async () => {
    const result = await get(`/tercero?ROL_ID=7`);

    if (!validateSuccess2xx(result)) return CustomToast({ result });

    const lst = result.data.map((row) => ({
      label: `${row.TERCERO_NOMBRE_BUSQUEDA}`,
      value: row.TERCERO_ID,
      isEmployee: row.ES_EMPLEADO,
    }));

    setConductorOptions(lst);
  };

  const getAparejador = async () => {
    const result = await get(`/tercero?ROL_ID=14`);

    if (!validateSuccess2xx(result)) return CustomToast({ result });

    const lst = result.data.map((row) => ({
      label: `${row.TERCERO_NOMBRE_BUSQUEDA}`,
      value: row.TERCERO_ID,
      isEmployee: row.ES_EMPLEADO,
    }));

    const withSelectNone = [
      {
        label: 'Ninguno',
        value: '',
      },
      ...lst,
    ];

    setAparejadorOptions(withSelectNone);
  };

  const postEvaluacion = async (form) => {
    try {
      const result = await put(
        `/solicitud/${props.Solicitud?.selSolicitud?.SOLICITUD_ID}?EDITAR_SOLO_EVAL=1`,
        {
          json: JSON.stringify({
            P1: form.p1,
            P2: form.p2,
            P3: form.p3,
            P4: form.p4,
          }),
        },
        'application/json'
      );
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Evaluacion enviada...' });
        getSolicitud();
        setIsModalEvaluacionVisible(false);
      }
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const createServicio = async (form) => {
    try {
      const result = await post(
        '/solicitud',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        //getServicios();
        setNuevoServicio(false);
        getSolicitud();
        OkToast({ result, message: 'Servicio Agregado!' });
      }
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const createManifiesto = async (form) => {
    try {
      const result = await post(
        '/selSolicitudVehiculoTipoID',
        {
          json: JSON.stringify({
            TIPO: '1',
            SOLICITUD_VEHICULO_TIPO_ID: selSolicitudVehiculoTipoID,
            SOLICITUD_PESO: form.SOLICITUD_PESO,
            //VEHICULO_ID: form.TRAILER_ID,
            //CONDUCTOR_TERCERO_ID: form.CONDUCTOR_TERCERO_ID,
            CONDUCTOR_VALOR: form.CONDUCTOR_VALOR,
            CONDUCTOR_ANTICIPO: form.CONDUCTOR_ANTICIPO,
            //SOLICITUD_VEHICULO_TOTAL: form.CONDUCTOR_VALOR,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();
      setNuevoServicio(false);
      getSolicitud();
      OkToast({ result, message: 'Manifiesto Agregado!' });
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const createReporte = async (form) => {
    try {
      const result = await post(
        `/solicitudVehiculoTipo/${form.SOLICITUD_VEHICULO_TIPO_ID}/generarReporte`,
        {
          json: JSON.stringify({
            SOLICITUD_VEHICULO_TIPO_ID: form.SOLICITUD_VEHICULO_TIPO_ID,
            //SOLICITUD_PESO: form.SOLICITUD_PESO,
            // VEHICULO_ID: form.TRAILER_ID,
            // CONDUCTOR_TERCERO_ID: form.CONDUCTOR_TERCERO_ID,
            TERCERO_AUDITORIA: form.TERCERO_AUDITORIA,
            CONDUCTOR_VALOR: form.CONDUCTOR_VALOR,
            CONDUCTOR_ANTICIPO: form.CONDUCTOR_ANTICIPO,

            APAREJADOR_VALOR: form.APAREJADOR_VALOR,
            APAREJADOR_ANTICIPO: form.APAREJADOR_ANTICIPO,

            SOLICITUD_VEHICULO_P1: form.SOLICITUD_VEHICULO_P1,
            SOLICITUD_VEHICULO_P2: form.SOLICITUD_VEHICULO_P2,
            SOLICITUD_VEHICULO_P3: form.SOLICITUD_VEHICULO_P3,
            SOLICITUD_VEHICULO_P4: form.SOLICITUD_VEHICULO_P4,
            SOLICITUD_ID: form.SOLICITUD_ID,

            // SOLICITUD_VEHICULO_TOTAL: form.CONDUCTOR_VALOR,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();
      setNuevoServicio(false);
      getSolicitud();
      OkToast({ result, message: 'Reporte Generado!' });
      setModalReporte(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const createNuevoAnticipo = async (form) => {
    try {
      const result = await post(
        `/solicitudVehiculoTipo/${form.SOLICITUD_VEHICULO_TIPO_ID}/nuevoAnticipo`,
        {
          json: JSON.stringify({
            SOLICITUD_VEHICULO_TIPO_ID: form.SOLICITUD_VEHICULO_TIPO_ID,
            //SOLICITUD_PESO: form.SOLICITUD_PESO,
            // VEHICULO_ID: form.TRAILER_ID,
            // CONDUCTOR_TERCERO_ID: form.CONDUCTOR_TERCERO_ID,
            TERCERO_AUDITORIA: form.TERCERO_AUDITORIA,
            CONDUCTOR_VALOR: form.CONDUCTOR_VALOR,
            CONDUCTOR_ANTICIPO: form.CONDUCTOR_ANTICIPO,

            APAREJADOR_VALOR: form.APAREJADOR_VALOR,
            APAREJADOR_ANTICIPO: form.APAREJADOR_ANTICIPO,

            SOLICITUD_VEHICULO_P1: form.SOLICITUD_VEHICULO_P1,
            SOLICITUD_VEHICULO_P2: form.SOLICITUD_VEHICULO_P2,
            SOLICITUD_VEHICULO_P3: form.SOLICITUD_VEHICULO_P3,
            SOLICITUD_VEHICULO_P4: form.SOLICITUD_VEHICULO_P4,
            SOLICITUD_ID: form.SOLICITUD_ID,

            CONDUCTOR_VALOR_NOTA: form.CONDUCTOR_VALOR_NOTA,
            APAREJADOR_VALOR_NOTA: form.APAREJADOR_VALOR_NOTA,

            // SOLICITUD_VEHICULO_TOTAL: form.CONDUCTOR_VALOR,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();

      getSolicitud();
      OkToast({ result, message: 'Solicitud de Anticipo Generada!' });
      setIsModalNuevoAnticipoVisible(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const confirmarSolicitud = async (form) => {
    try {
      const result = await post(
        `/solicitudVehiculoTipo/${form.SOLICITUD_VEHICULO_TIPO_ID}/confirmar`,
        {
          json: JSON.stringify({
            TIPO: '3',
            SOLICITUD_VEHICULO_TIPO_ID: form.SOLICITUD_VEHICULO_TIPO_ID,
            SOLICITUD_PESO: form.SOLICITUD_PESO,
            VEHICULO_ID: form.VEHICULO_ID,
            CONDUCTOR_TERCERO_ID: form.CONDUCTOR_TERCERO_ID,
            TRAILER_ID: form.TRAILER_ID,
            PROVEEDOR_TERCERO_ID: '',
            APAREJADOR_TERCERO_ID: form.APAREJADOR_TERCERO_ID,
            SOLICITUD_ID: form.SOLICITUD_ID,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();

      setNuevoServicio(false);
      getSolicitud();
      OkToast({ result, message: 'Vehículo confirmado!' });

      setModalConfirmar(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const sinAnticipo = async (form) => {
    try {
      const result = await post(
        `/solicitud/${form.SOLICITUD_ID}/noAnticipo`,
        {
          json: JSON.stringify({
            TERCERO_ID: props.auth.data.user.TERCERO_ID,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();

      getSolicitud();
      OkToast({ result, message: 'Solicitud sin anticipo!' });
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const messageAprobarSolicitud = (state) => {
    switch (state) {
      case 1:
        return 'Solicitud Aprobada';
      case 2:
        return 'Solicitud Rechazada!';
      case 6:
        return 'Solicitud Finalizada!';
      default:
        return 'Solicitud en ejecución';
    }
  };
  const aprobarSolicitud = async (id, estado, obs = '') => {
    try {
      const result = await put(
        `/solicitud/${id}?EDITAR_SOLO_APROBAR=1`,
        {
          json: JSON.stringify({
            SOLICITUD_APROBADA: `${estado}`,
            SOLICITUD_APROBADA_OBS: `${obs}`,
            TERCERO_ID: auth.data.user.TERCERO_ID,
          }),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        getSolicitud();
        OkToast({
          result,
          message: messageAprobarSolicitud(estado),
        });
      }
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const resendSiesaFD = async (solicitudID) => {
    try {
      const result = await post(
        `/siesa/${solicitudID}/fd`,
        {
          json: JSON.stringify({
            TERCERO_ID: props.auth.data.user.TERCERO_ID,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();
      getSolicitud();

      OkToast({ result, message: 'Enviado...' });
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const resendSiesaRNDC = async (solicitudID) => {
    try {
      const result = await post(
        `/siesa/${solicitudID}/rndc`,
        {
          json: JSON.stringify({
            TERCERO_ID: props.auth.data.user.TERCERO_ID,
          }),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();
      getSolicitud();

      OkToast({ result, message: 'Enviado...' });
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const finalizarSolicitud = async (id, estado, obs = '') => {
    try {
      const result = await put(
        `/solicitud/${id}?EDITAR_SOLO_FINALIZAR=1`,
        {
          json: JSON.stringify({ SOLICITUD_APROBADA: `${estado}`, TERCERO_ID: auth.data.user.TERCERO_ID }),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({
          result,
          message: messageAprobarSolicitud(estado),
        });
      }
    } catch (error) {
      ErrorToast('Error:', error);
    }
    getSolicitud();
  };

  const notificarManifiesto = async (form) => {
    try {
      const result = await post(
        `/solicitudVehiculoTipo/${form.SOLICITUD_VEHICULO_TIPO_ID}/notificarManifiesto`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();
      setNuevoServicio(false);
      getSolicitud();
      OkToast({ result, message: 'Manifiesto guardado!' });
      setModalManifiesto(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const handlePorAprobar = () => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setEstadoAprobado(0);
  };

  const handleAprobado = () => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setEstadoAprobado(1);
  };

  const handleCancelado = () => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setEstadoAprobado(2);
  };

  const handleRechazado = () => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setEstadoAprobado(3);
  };

  const handleConfirmadas = () => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setEstadoAprobado(4);
  };

  const handleEjecucion = () => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setEstadoAprobado(5);
  };

  const handleFinalizadas = () => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setCurrentPage(1);
    setEstadoAprobado(6);
  };

  const getServicioLogs = async (solicitud) => {
    try {
      const result = await get(`/solicitudLog/${solicitud.SOLICITUD_ID}`);

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      store.dispatch(setSelSolicitudLog(result.data));
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const handleReject = (id) => {
    setIdSolicitud(id);
    setModalRechazarSolicitud(true);
  };

  const handleEdit = (obj) => {
    store.dispatch(setSelSolicitud(obj));

    setNuevoServicio(true);
  };

  function handleEditBonoAsignado(obj) {
    store.dispatch(setSelAsignarBono(obj));
    setModalAsignarBono(true);
  }

  useEffect(() => {
    getTipoVehículo();
    getTrailer();
    getConductor();
    getAparejador();
  }, []);

  useEffect(() => {
    getSolicitud();
  }, [estadoAprobado]);

  const solicitud = props.Solicitud;
  const auth = props.auth;

  return (
    <ViewServicio
      endPoint={{
        createServicio,
        solicitudResult,
        aprobarSolicitud,
        finalizarSolicitud,
        createManifiesto,
        confirmarSolicitud,
        createReporte,
        getServicioLogs,
        postEvaluacion,
        notificarManifiesto,
        getDocumentoPdf,
        getDocumentoFinalPdf,
        resendSiesaFD,
        resendSiesaRNDC,
        sinAnticipo,
        createNuevoAnticipo,
      }}
      modales={{
        setNuevoServicio,
        nuevoServicio,
        modaladdItmen,
        setModaladdItmen,
        modalInfoServicio,
        setModalInfoServicio,
        modalRechazarSolicitud,
        setModalRechazarSolicitud,
        modalSeleccionarManifestoOReporte,
        setModalSeleccionarManifestoOReporte,
        modalManifiesto,
        setModalManifiesto,
        modalReporte,
        setModalReporte,
        modalConfirmar,
        setModalConfirmar,
        modalEjecucion,
        setModalEjecucion,
        modalLogs,
        setModalLogs,
        isModalEvaluacionVisible,
        setIsModalEvaluacionVisible,
        modalAsignarBono,
        setModalAsignarBono,
        isModalNuevoAnticipoVisible,
        setIsModalNuevoAnticipoVisible,
        isModalSiesaLogVisible,
        setIsModalSiesaLogVisible,
      }}
      data={{
        idSolicitud,
        vehiculoTipoOptions,
        trailerOptions,
        conductorOptions,
        aparejadorOptions,
        solicitud,
        auth,
        bonoAsignado,
        tipoSolicitud,
        siesaModalData,
      }}
      actions={{
        handleAprobado,
        handlePorAprobar,
        handleCancelado,
        handleRechazado,
        estadoAprobado,
        handleEdit,
        handleReject,
        loadingTables,
        handleConfirmadas,
        handleEjecucion,
        handleFinalizadas,
        handleEditBonoAsignado,
        setBonoAsignado,
        setTipoSolicitud,
        handleShowSiesaLog,
        handleShowSiesaLogRNDC,
      }}
      pager={{ handleNextPage, handlePreviusPage }}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    tipoVehiculo: state.tipoVehiculo,
    Solicitud: state.Solicitud,
  };
};

export default connect(mapStateToProps)(Servicios);
