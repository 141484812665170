const initialState = {
  isData: false,
  marca: [],
  selMarca: null,
  pagination: null,
};

const initialMarcaState = {
  selMarca: null,
};

export const marca = (state = initialState, action) => {
  switch (action.type) {
    case 'setMarca': {
      if (action.value.status === 200) {
        const stateData = { marca: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setMarcaInit': {
      return initialState;
    }
    case 'setSelMarca': {
      const obj = {
        ...action.value,
      };

      const stateData = { selMarca: obj };

      return { ...state, ...stateData };
    }
    case 'setSelMarcaInit': {
      return { ...state, ...initialMarcaState };
    }

    default:
      //
      return state;
  }
};
