import React, { useState, useEffect } from 'react';

import ViewVehiculo from './ViewVehiculo';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setSelVehiculo, setVehiculo, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Vehiculo = (props) => {
  const { get, post, DELETE, put } = useApiClient();
  const [modalAgregarVehiculo, setModalAgregarVehiculo] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);

  const getVehiculo = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/vehiculo`);

      store.dispatch(setVehiculo(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const createVehiculo = async (form) => {
    try {
      const result = await post(
        '/vehiculo',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarVehiculo(false);
        OkToast({ result, message: 'Vehículo Agregado!' });
        getVehiculo();
      }
    } catch (error) {}
  };

  const updateVehiculo = async (form) => {
    try {
      const result = await put(
        `/vehiculo/${props.vehiculo.selVehiculo.VEHICULO_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarVehiculo(false);
        OkToast({ result, message: 'Vehículo actualizado!' });
        getVehiculo();
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['MARCA_ID', 'VEHICULO_PLACA', 'VEHICULO_PROPIO'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  function handleEdit(obj) {
    store.dispatch(setSelVehiculo(obj));
    setModalAgregarVehiculo(true);
  }

  function handleDelete(rowID) {
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el vehículo?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteVehiculo(rowID);
          }}>
          Sí
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }

  const deleteVehiculo = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await DELETE(`/vehiculo/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Vehículo eliminado!' });

        getVehiculo();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }

    store.dispatch(hideLoadingAnimation());
  };

  useEffect(() => {
    getVehiculo();
  }, []);

  return (
    <ViewVehiculo
      modal={{
        modalAgregarVehiculo,
        setModalAgregarVehiculo,
        createVehiculo,
        updateVehiculo,
        validate,
      }}
      endPoint={undefined}
      validation={undefined}
      data={{ loadingTables }}
      actions={{ handleEdit, handleDelete }}
    />
  );
};

const mapStateToProps = function (state) {
  return { vehiculo: state.vehiculo };
};

//export default Projects;
export default connect(mapStateToProps)(Vehiculo);
