import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/UnidadNegocio/Filter';
import { ModalAgregarUnidadNegocio } from '../../../components/specific/UnidadNegocio/Modales/agregarUnidadNegocio.js';
import TableUnidadNegocios from '../../../components/specific/UnidadNegocio/Table';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

const ViewUnidadNegocio = (props) => {
  const { modalAgregarUnidadNegocio, setModalModalAgregarUnidadNegocio, createUnidadNegocio, validate } = props.modal;
  const { loadingTables } = props.actions;

  const { getRoles } = props;
  return (
    <div>
      <Nav />
      <Toaster />
      <div className="md:px-20 w-full py-8">
        <div>
          <h2 className="... mx-8 text-2xl font-semibold leading-tight">GRUPOS DE UNIDADES DE NEGOCIOS</h2>
        </div>
        <Filter setModalModalAgregarUnidadNegocio={setModalModalAgregarUnidadNegocio} />
        <div className="... mx-8 overflow-hidden rounded border-b border-gray-200 shadow">
          {loadingTables ? <LoadingTables /> : <TableUnidadNegocios actions={props.actions} />}
        </div>
      </div>
      {modalAgregarUnidadNegocio ? (
        <ModalAgregarUnidadNegocio
          endPoint={props.endPoint}
          agregarUnidadNegocio={{ createUnidadNegocio, validate }}
          setModalModalAgregarUnidadNegocio={setModalModalAgregarUnidadNegocio}
          getRoles={getRoles}
        />
      ) : null}
    </div>
  );
};

export default ViewUnidadNegocio;
