import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { ReactComponent as Add } from '../../../../components/assets/svg/uploadFile.svg';
import 'react-dropzone-uploader/dist/styles.css';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { ButtonTable } from '../../../global/Styles/buttons';
import { checkAPI_URL } from '../../../../utils/environment';
import { store } from '../../../../store';
import { setImportarTarifa } from '../../../../actions';
import { ErrorToast, OkToast } from '../../../../components/specific/Toast';

const SubirTarifa = (props) => {
  const { contrato } = props.data;
  const { setUploadFile, setListaError, getTarifa } = props.actions;
  const optionSelect = contrato.contrato.map((row) => ({
    label: `${row.CONTRATO_NUMERO}`,
    value: row.CONTRATO_ID,
  }));
  const acceptedFileTypes = ['.xlsx', '.xls'];
  const [contratoId, setContratoId] = useState(optionSelect[0].value);

  const styleUpload = () => {
    return (
      <label
        style={{
          color: '#fff',
          cursor: 'pointer',
          padding: 10,
          borderRadius: 3,
          zIndex: -10,
        }}>
        <Add className="h-12 w-12" />
      </label>
    );
  };

  const proxyTask = async (form) => {};

  const handleSubmitFile = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());

    const result = files.map((file) => file.xhr.response);
    const json = JSON.parse(result);
    if (json.success) {
      OkToast({ message: `${json.imported.length} registros importados.` });
      getTarifa();
      setUploadFile(false);
      return;
    }

    store.dispatch(setImportarTarifa(json));
    setListaError(true);
  };

  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append('file', file);
    body.append('CONTRATO_ID', contratoId);
    return { url: `${checkAPI_URL()}/importarTarifas`, body };
  };

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (xhr) {
      if (status == 'error_upload') {
        ErrorToast({
          title: 'Error',
          message: 'Se ha producido un error. Por favor, asegúrese de haber seleccionado el contrato y de haber cargado el archivo XLS.',
        });
      }
    }
  };

  const setCONTRATO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONTRATO_ID'];
    setContratoId(args[0].value);
    field.change(args[0].value);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={proxyTask}
        mutators={{
          setCONTRATO_ID,
        }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <h2 className="py-4 text-xl ">Sube tu tarifa en formato xlsx</h2>

                  <FormStateToRedux form="frmAgregarTarifa" />
                  <div className="flex space-x-4">
                    <div className="flex-auto justify-center text-left">
                      <p className="text-sm text-gray-800">Contrato</p>
                      <Field name="CONTRATO_ID">
                        {({ input, meta }) => (
                          <>
                            {contrato.contrato.length > 0 ? (
                              <Select
                                defaultValue={optionSelect[0]}
                                onChange={form.mutators.setCONTRATO_ID}
                                options={optionSelect}
                                name="selectContrato"
                                className="mt-2"
                              />
                            ) : (
                              <div className=" flex items-center justify-center">
                                <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                              </div>
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="mt-2 flex space-x-4">
                    <Dropzone
                      getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      onSubmit={handleSubmitFile}
                      maxFiles={1}
                      multiple={false}
                      canCancel={true}
                      accept={acceptedFileTypes.join(',')}
                      inputContent={styleUpload}
                      styles={{
                        dropzone: {
                          minHeight: 200,
                          maxHeight: 250,
                          overflow: 'hidden',
                          opacity: 0.9,
                        },
                        dropzoneActive: {
                          borderColor: 'green',
                          height: 30,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setUploadFile(false)}>Cancelar</ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    Company: state.Company,
  };
};

//export default Projects;
export default connect(mapStateToProps)(SubirTarifa);
