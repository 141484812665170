import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

export function HeroCheckIcon({ enable, handleClick }) {
  if (enable) {
    return (
      <CheckCircleIcon
        className="h-6 w-6 cursor-pointer text-green-500"
        aria-hidden="true"
        onClick={handleClick}
      />
    );
  }
  return (
    <XCircleIcon
      className="h-6 w-6 cursor-pointer text-yellow-500"
      aria-hidden="true"
      onClick={handleClick}
    />
  );
}
