import styled from 'styled-components';

export const TitlesNav = styled.h1.attrs({
  className: 'hover:text-gray-500 text-gray-200 cursor-pointer',
})`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

export const TitleNavUserName = styled.h1.attrs({
  className: 'text-gray-600',
})`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

export const TitlesTables = styled.h1.attrs({
  className: 'font-heading ml-1 cursor-pointer text-xl font-bold text-gray-800',
})``;

export const ModalTitle = styled.h1.attrs({
  className: 'font-heading px-4 border-b text-xl font-bold text-gray-800',
})``;

export const TitlesProjectView = styled.h1.attrs({
  className: '',
})`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

export const SubTitlesProjectView = styled.h2.attrs({
  className: ' mb-8 mt-4',
})`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

export const ParagraphTools = styled.p.attrs({
  className: 'p-4',
})`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;
