import React from 'react';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
const TableClienteUsuarios = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TableHeader />
      <TableBody
        data={props.data}
        actions={props.actions}
      />
    </table>
  );
};
export default TableClienteUsuarios;
