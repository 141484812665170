import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { CheckParameter } from '../../../../../utils/security';

const TipoVehiculoCell = ({ itemData: obj }) => {
  console.log('obj: ', obj);
  if (obj)
    return (
      <td className="">
        <div
          className="flex gap-2"
          key={obj.SOLICITUD_VEHICULO_TIPO_ID}>
          <ul>
            <li className="flex gap-2">
              {obj.VEHICULO_TIPO_DESCRIPCION} <p className=" text-green-500">{obj.VEHICULO_PLACA}</p>{' '}
              <p className=" text-green-500">
                <strong>{obj.V_VEHICULO_PROPIO === 0 ? '(Ext)' : ''} </strong>
              </p>
            </li>
            <li className="flex gap-2">
              <p className=" ">
                <strong>{obj.V_VEHICULO_PROPIO === 0 ? obj.V_TERCERO_TENEDOR_NOMBRE + ' - ' + obj.V_TERCERO_TENEDOR_DOCUMENTO : ''} </strong>
              </p>
            </li>
          </ul>
        </div>
      </td>
    );
  return <td className="whitespace-nowrap">...</td>;
};

const TbodyTableManifiesto = (props) => {
  const row = props.Solicitud.selSolicitud;
  const rowTipoVehiculo = props.tipoVehiculo.selTipoVehiculo;

  return (
    <tbody className="rounded-lg text-gray-700">
      <tr>
        <td className="whitespace-nowrap px-2 text-left ">{row?.SOLICITUD_CONSECUTIVO_INT}</td>
        <td className="px-2 text-left">{row?.SOLICITUD_CONSECUTIVO}</td>
        {CheckParameter(props.auth?.data.selCliente.parametro, 'PARAMETRO_ID', 2) && <td className="px-2 text-left">{row?.SOLICITA}</td>}
        <td className="px-2 text-left">{row?.SOLICITUD_DESCRIPCION}</td>

        <td className=" px-2 text-left">{`${row?.unidad_negocio[0]?.NEGOCIO_NOMBRE}`}</td>
        <TipoVehiculoCell itemData={rowTipoVehiculo} />

        <td className="px-2 text-left">{row?.ORIGEN}</td>
        <td className="px-2 text-left">{row?.DESTINO}</td>
        <td className="whitespace-nowrap px-2 text-left">{row?.SOLICITUD_FECHA}</td>
      </tr>
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return {
    Solicitud: state.Solicitud,
    tipoVehiculo: state.tipoVehiculo,
  };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableManifiesto);
