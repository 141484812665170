const initialState = {
  isData: false,
  tercero: [],
  selTercero: null,
  pagination: null,
};

const initialTerceroState = {
  selTercero: null,
};

export const tercero = (state = initialState, action) => {
  switch (action.type) {
    case 'setTercero': {
      // const paginator = {
      //   perPage: action.value.pagination.perPage,
      //   currentPage: action.value.pagination.currentPage,
      //   lastPage: action.value.pagination.totalPages,
      //   total: action.value.pagination.totalRecords,
      // };

      if (action.value.status === 200) {
        const stateData = { tercero: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setTerceroInit': {
      return initialState;
    }
    case 'setSelTercero': {
      const obj = {
        ...action.value,
      };

      const stateData = { selTercero: obj };

      return { ...state, ...stateData };
    }
    case 'setSelTerceroInit': {
      return { ...state, ...initialTerceroState };
    }

    default:
      //
      return state;
  }
};
