import React from 'react';
const TheadTableSolicitudLogsResumen = () => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className=" rounded-tl-lg px-4 py-3 text-left  text-sm">Quien solicita</th>

        <th className=" px-4 py-3 text-left  text-sm">Aprobador</th>
        <th className=" px-4 py-3 text-left  text-sm">Descripción</th>
        <th className=" px-4 py-3 text-left  text-sm">Tipo servicio</th>
        <th className=" px-4 py-3 text-left  text-sm">Tipo Vehículo</th>
        <th className=" px-4 py-3 text-left  text-sm">Origen</th>
        <th className=" rounded-tr-lg px-4 py-3 text-left  text-sm">Destino</th>
      </tr>
    </thead>
  );
};
export default TheadTableSolicitudLogsResumen;
