import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as IconOn } from '../../../assets/svg/switch-on.svg';
import { ReactComponent as IconOff } from '../../../assets/svg/switch-off.svg';

const TbodyTableLugar = (props) => {
  const lugar = props.lugar.lugar;
  const { handleEstado } = props.actions;

  useEffect(() => {}, []);

  return (
    <tbody className="text-gray-700 ">
      {lugar?.length > 0 ? (
        lugar.map((obj) => (
          <tr
            key={obj.LUGAR_ID}
            className=" ">
            <td className=" px-1 text-left">{obj.LUGAR_ID}</td>
            <td className=" px-1 text-left">{obj.LUGAR_DESCRIPCION}</td>
            <td className=" px-1 text-left">{obj.LUGAR_OTRO_NOMBRE}</td>
            <td className="px-1 text-left">
              {obj.LUGAR_ESTADO === 1 ? (
                <IconOn
                  className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                  onClick={() => handleEstado(obj)}
                />
              ) : (
                <IconOff
                  className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                  onClick={() => handleEstado(obj)}
                />
              )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td
            colSpan={3}
            className=" px-1 text-center">
            No hay registros
          </td>
        </tr>
      )}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { lugar: state.lugar };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableLugar);
