import React from 'react';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { ButtonTable } from '../../../global/Styles/buttons';

export const ModalRechazar = (props) => {
  const { setModalRechazarSolicitud } = props.modales;
  const { idSolicitud } = props.data;
  console.log('idSolicitud: ', idSolicitud);
  const { aprobarSolicitud } = props.endPoint;

  const handleRecahazar = (form) => {
    aprobarSolicitud(idSolicitud.SOLICITUD_ID, 3, form.SOLICITUD_APROBADA_OBS);
    setModalRechazarSolicitud(false);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={handleRecahazar}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-5 py-4 text-left">
                  <h2 className="py-4 text-xl ">{`Rechazar solicitud: ${idSolicitud.SOLICITUD_CONSECUTIVO_INT}`}</h2>
                  <FormStateToRedux form="frmRechazarSolicitud" />
                  <div className="flex-auto justify-center space-y-2 text-left ">
                    <Field name="SOLICITUD_APROBADA_OBS">
                      {({ input, meta }) => (
                        <>
                          <p className="text-sm text-gray-800">Obervación</p>
                          <textarea
                            {...input}
                            type="text"
                            placeholder="Escribe tu observación"
                            className="h-32 w-full rounded-lg border p-1"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-right">
                  <ButtonTable onClick={() => setModalRechazarSolicitud(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Rechazar
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
