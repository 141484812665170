import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';

import { CustomToast, OkToast } from '../../Toast';
import Select from 'react-select';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';

import { ModalTitle } from '../../../global/Styles/titles';

export const ModalAgregarZona = (props) => {
  const { handleShowAddNewModal } = props;
  const { reload } = props.actions;

  const { post, get, put } = useApiClient();

  const { auth, zona } = props.data;

  const [contrato, setContrato] = useState([]);

  const getContratos = async () => {
    const response = await get(`/contrato?TERCERO_ID=${auth.data.selCliente.TERCERO_ID}`);

    const lst = response.data.map((row) => ({
      label: `${row.CONTRATO_NUMERO} - ${row.TERCERO_NOMBRE_BUSQUEDA}`,
      value: row.CONTRATO_ID,
    }));

    setContrato(lst);
  };

  const createZona = async (form) => {
    try {
      const result = await post(
        '/zona',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        handleShowAddNewModal(false);
        OkToast({ result, message: 'Zona Agregada!' });
        reload();
      }
    } catch (error) {}
  };

  const updateZona = async (form) => {
    try {
      const result = await put(
        `/zona/${zona.selZona.ZONA_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        handleShowAddNewModal(false);
        OkToast({ result, message: 'Zona Actualizada!' });
        reload();
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['ZONA_DESCRIPCION'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  const setCONTRATO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONTRATO_ID'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const editMode = zona.selZona === null ? false : true;

  const proxyTask = async (form) => {
    if (editMode) {
      updateZona(form);
    } else {
      createZona(form);
    }
  };

  useEffect(() => {
    getContratos();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>{editMode ? 'EDITAR' : 'NUEVA'} ZONA</ModalTitle>
        <Form
          onSubmit={proxyTask}
          validate={validate}
          initialValues={editMode ? zona.selZona : {}}
          mutators={{
            setCONTRATO_ID,
          }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <FormStateToRedux form="frmAgregarRol" />
                  <Field name="ZONA_DESCRIPCION">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Descripción</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="DESCRIPCION"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  <Field name="ZONA_PREFIJO">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Prefijo</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="Prefijo"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  <Field name="ZONA_CONSECUTIVO">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Consecutivo</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="Consecutivo"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  <div className="">
                    <p className="mt-3 text-sm text-gray-800">Contrato</p>
                    {contrato.length > 0 ? (
                      <Field name="CONTRATO_ID">
                        {({ input, meta }) => (
                          <>
                            {contrato.length > 0 && (
                              <Select
                                options={contrato}
                                defaultValue={contrato[0]}
                                name="selectNovedadTipoOptions"
                                onChange={form.mutators.setCONTRATO_ID}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    marginTop: 6,
                                    borderColor: meta.error && meta.touched ? 'red' : null,
                                  }),
                                }}
                              />
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    ) : (
                      <div className=" flex items-center justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => handleShowAddNewModal(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ModalAgregarZona;
