import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ViewContrato from './ViewContrato';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setContrato, setSelContrato, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Contrato = (props) => {
  const auth = props.auth;
  const { get, post, put, DELETE } = useApiClient();
  const [modalAgregarContrato, setModalAgregarContrato] = useState(false);
  const [clienteOptions, setClienteOptions] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);

  const [terceroFilter, setTerceroFilter] = useState('');

  const getContratos = async () => {
    try {
      const result = await get(`/contrato?TERCERO_ID=${auth.data.selCliente.TERCERO_ID}`);

      store.dispatch(setContrato(result));
    } catch (error) {}
  };

  const createContrato = async (form) => {
    try {
      form['TERCERO_ID'] = auth.data.selCliente.TERCERO_ID;

      const result = await post(
        '/contrato',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarContrato(false);
        OkToast({ result, message: 'Contrato Agregado!' });
        getContratos();
      }
    } catch (error) {}
  };

  const updateContrato = async (form) => {
    try {
      form['TERCERO_ID'] = auth.data.selCliente.TERCERO_ID;
      const result = await put(
        `/contrato/${props.contrato.selContrato.CONTRATO_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarContrato(false);
        OkToast({ result, message: 'Contrato actualizado!' });
        getContratos();
      }
    } catch (error) {}
  };

  const updateContratoEstado = async (contrato) => {
    try {
      const result = await put(`/contrato/${contrato.CONTRATO_ID}?SOLO_ESTADO=1`, { json: JSON.stringify(contrato) }, 'application/json');

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarContrato(false);
        OkToast({ result, message: 'Contrato actualizado!' });
        getContratos();
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['CONTRATO_NUMERO', 'CONTRATO_FECHA_INICIO', 'CONTRATO_FECHA_FINAL'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });

    return errors;
  };

  const getClientes = async () => {
    setLoadingTables(true);
    try {
      const response = await get(`/tercero?ROL_ID=6&paginate=0`);

      const lst = response.data.map((row) => ({
        label: `${row.TERCERO_NOMBRE_BUSQUEDA}`,
        value: row.TERCERO_ID,
      }));

      setClienteOptions(lst);
      setTerceroFilter(lst[0].value);

      getContratos();
    } catch (error) {}
    setLoadingTables(false);
  };

  const handleEdit = (obj) => {
    store.dispatch(setSelContrato(obj));
    setModalAgregarContrato(true);
  };

  function handleDelete(rowID) {
    toast((t) => (
      <span>
        <b>Esta seguro de eliminar el contrato?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteContrato(rowID);
          }}>
          Yes
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }

  const deleteContrato = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await DELETE(`/contrato/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Contrato eliminado!' });
        getContratos();
      }
    } catch (error) {
      ErrorToast('Error', error);
    }

    store.dispatch(hideLoadingAnimation());
  };

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    getContratos();
  }, [terceroFilter]);

  function handleEstado(obj) {
    updateContratoEstado(obj);
  }

  return (
    <ViewContrato
      modal={{
        modalAgregarContrato,
        setModalAgregarContrato,
      }}
      data={{ clienteOptions, setClienteOptions, terceroFilter, setTerceroFilter }}
      endPoint={{ getClientes, createContrato, validate, updateContrato }}
      actions={{ loadingTables, handleEdit, handleDelete, handleEstado }}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    contrato: state.contrato,
    auth: state.auth,
  };
};

//export default Projects;
export default connect(mapStateToProps)(Contrato);
