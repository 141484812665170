import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import Select from 'react-select';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import TableEjecucion from './TableEjecucion';
import TableManifiesto from './TableManifiesto';
import TableSolicitudLogs from './TableSolicitudLogs';
import TableSolicitudLogsResumen from './TableSolicitudLogsResumen';

export const ModalLogs = (props) => {
  const storeState = store.getState();
  const { setModalLogs } = props;

  const { vehiculoTipoOptions, vehiculoOptions, trailerOptions, conductorOptions } = props.data;

  const { post } = useApiClient();

  //const selTipoVehiculo = props.tipoVehiculo.selTipoVehiculo.pivot;

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <TableSolicitudLogsResumen />
        <TableSolicitudLogs />
        <div className="mt-6 flex justify-end">
          <ButtonTable onClick={() => setModalLogs(false)}>Cerrar</ButtonTable>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles, tipoVehiculo: state.tipoVehiculo, Solicitud: state.Solicitud };
};

export default connect(mapStateToProps)(ModalLogs);
