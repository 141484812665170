import React from 'react';
import Nav from '../../../components/specific/Navigation';
import TableTipoVehiculo from '../../../components/specific/TipoVehiculo/Table';
import Filter from '../../../components/specific/TipoVehiculo/Filter';
import { ModalAgregarTipoVehiculo } from '../../../components/specific/TipoVehiculo/Modales/agregarTipoVehiculo';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import { ButtonTable } from '../../../components/global/Styles/buttons';
import { TitlesTables } from '../../../components/global/Styles/titles';
import { store } from '../../../store';
import { setSelTipoVehiculoInit } from '../../../actions';
import TableParametroGeneral from '../../../components/specific/ParametroGeneral/Table';
import ModalAgregarParametroGeneral from '../../../components/specific/ParametroGeneral/Modales/ModalAgregarParametroGeneral';
const ViewParametroGeneral = (props) => {
  const { isModalAgregarParametroGeneralVisible, setIsModalAgregarParametroGeneralVisible } = props.modal;

  const { loadingTables } = props.data;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>PARAMETROS GENERALES</TitlesTables>
            <div className="flex-auto"></div>
          </div>

          {loadingTables && <LoadingTables />}
          <TableParametroGeneral
            actions={props.actions}
            data={props.data}
          />
        </div>
      </div>

      {isModalAgregarParametroGeneralVisible && (
        <ModalAgregarParametroGeneral
          endPoint={props.endPoint}
          data={props.data}
          setIsModalAgregarParametroGeneralVisible={setIsModalAgregarParametroGeneralVisible}
        />
      )}
    </div>
  );
};

export default ViewParametroGeneral;
