import React from 'react';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { ModalTitle } from '../../../global/Styles/titles';

export const ModalAgregarParametroGeneral = (props) => {
  const { setIsModalAgregarParametroGeneralVisible } = props;

  const { updateParametroGeneral } = props.endPoint;

  const proxyTask = async (form) => {
    console.log('proxyTask: ', form);
    updateParametroGeneral(form);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-2xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>{'EDITAR'} PARÁMETRO</ModalTitle>
        <Form
          onSubmit={proxyTask}
          initialValues={props.data.selectedParametroData}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <FormStateToRedux form="frmAgregarTipoVehiculo" />

                  <Field name="PARAMETRO_ID">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">ID</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="ID"
                          readOnly
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  <Field name="PARAMETRO_DESCRIPCION">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Descripción</p>
                        <input
                          {...input}
                          type="text"
                          readOnly
                          placeholder="Descripción"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  <Field name="PARAMETRO_VALOR">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Valor</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="Valor"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setIsModalAgregarParametroGeneralVisible(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ModalAgregarParametroGeneral;
