import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';

import Select from 'react-select';

import { ButtonTable } from '../../../global/Styles/buttons';
import { useEffect } from 'react';

export const ModalInfoServicio = (props) => {
  const [terceroOptions, setTerceroOptions] = useState([]);
  const { get } = useApiClient();
  const { setModalInfoServicio } = props.modales;

  const getTercero = async () => {
    console.info('getTercero Start!!!');
    try {
      const response = await get(`/tercero`);
      console.info('*** getTercero', response);

      const lst = response.data.map((row) => ({
        label: `${row.TERCERO_NOMBRE_BUSQUEDA} `,
        value: row.TERCERO_ID,
      }));
      setTerceroOptions(lst);
    } catch (error) {
      console.info('error getTercero', error);
    }
  };

  useEffect(() => {
    getTercero();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit="lol"
        validate=""
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="mt-2 flex-auto justify-center p-5 py-4 text-left">
                  <h2 className="py-4 text-xl ">Información deservicio</h2>

                  <FormStateToRedux form="frmAgregarRol" />

                  <div className="mt-2 flex-auto justify-center space-y-2 text-left ">
                    <p className="text-sm text-gray-800">Placa</p>

                    <Select
                      options={''}
                      name="selectMarca"
                      className=""
                    />
                    <Field name="MARCA_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="mt-2 flex-auto justify-center space-y-2 text-left ">
                    <p className="text-sm text-gray-800">Tráiler</p>
                    <Select
                      options={''}
                      name="selectMarca"
                      className=""
                    />
                    <Field name="MARCA_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="mt-2 flex-auto justify-center space-y-2 text-left ">
                    <p className="text-sm text-gray-800">Conductor</p>
                    {terceroOptions.length > 0 ? (
                      <Select
                        options={terceroOptions}
                        name="selectMarca"
                        className=""
                      />
                    ) : (
                      <div className=" mt-2 flex items-center justify-center   space-y-2">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="MARCA_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="mt-2 flex-auto justify-center space-y-2 text-left">
                    <p className="text-sm text-gray-800">Ayudante</p>
                    {terceroOptions.length > 0 ? (
                      <Select
                        options={terceroOptions}
                        name="selectMarca"
                        className=""
                      />
                    ) : (
                      <div className=" flex items-center justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="MARCA_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalInfoServicio(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles };
};

//export default Projects;
export default connect(mapStateToProps)(ModalInfoServicio);
