import React from 'react';
import TbodyTableEjecucion from './tbody';
import TheadTableEjecucion from './thead';
const TableEjecucion = (props) => {
  return (
    <table className="min-w-full bg-white ">
      <TheadTableEjecucion />
      <TbodyTableEjecucion />
    </table>
  );
};
export default TableEjecucion;
