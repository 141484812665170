import React from 'react';
import TbodyTableTrailer from './tbody';
import TheadTableTrailer from './thead';

const TableTrailer = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableTrailer />
      <TbodyTableTrailer actions={props.actions} />
    </table>
  );
};
export default TableTrailer;
