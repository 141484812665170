const initialState = {
  isData: false,
  zona: [],
  selZona: null,
  pagination: null,
};

const initialZonaState = {
  selZona: null,
};

export const zona = (state = initialState, action) => {
  switch (action.type) {
    case 'setZona': {
      if (action.value.status === 200) {
        const stateData = { zona: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setZonaInit': {
      return initialState;
    }
    case 'setSelZona': {
      const obj = {
        ...action.value,
      };

      const stateData = { selZona: obj };

      return { ...state, ...stateData };
    }
    case 'setSelZonaInit': {
      return { ...state, ...initialZonaState };
    }

    default:
      //
      return state;
  }
};
