import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';

import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';

import TableManifiesto from './TableManifiesto';
import { formatterPeso } from '../../../../utils/format';
import { ModalTitle } from '../../../global/Styles/titles';
import { PanelVehiculoTarifa } from './ModalSeguimiento/PanelVehiculoTarifa';

const VehiculoLabel = ({ data }) => {
  const onlyOwnerData = {
    propietario: data.V_TERCERO_PROPIETARIO_NOMBRE,
    tenedor: data.V_TERCERO_TENEDOR_NOMBRE,
    propio: data.V_VEHICULO_PROPIO,
  };

  const vehiculoData = {
    isOurAsset: onlyOwnerData.propio === 1,
    isOurAssetText: onlyOwnerData.propio === 1 ? 'Propio' : 'Ext.',
    propietario: onlyOwnerData.propietario,
    tenedor: onlyOwnerData.tenedor,
    externoText: onlyOwnerData.tenedor ? '(T)' : '(P)',
    externoName: onlyOwnerData.tenedor ? onlyOwnerData.tenedor : onlyOwnerData.propietario,
  };

  return (
    <span className={`${vehiculoData.isOurAsset ? 'text-green-600' : 'text-red-600'} `}>
      {`${vehiculoData.isOurAssetText || '...'} ${(!vehiculoData.isOurAsset && vehiculoData.externoText) || ''} ${
        (!vehiculoData.isOurAsset && vehiculoData.externoName) || ''
      }`}
    </span>
  );
};

const TrailerLabel = ({ data }) => {
  const onlyOwnerData = {
    propietario: data.T_TERCERO_PROPIETARIO_NOMBRE,
    tenedor: data.T_TERCERO_TENEDOR_NOMBRE,
    propio: data.T_VEHICULO_PROPIO,
  };

  const vehiculoData = {
    isOurAsset: onlyOwnerData.propio === 1,
    isOurAssetText: onlyOwnerData.propio === 1 ? 'Propio' : 'Ext.',
    propietario: onlyOwnerData.propietario,
    tenedor: onlyOwnerData.tenedor,
    externoText: onlyOwnerData.tenedor ? '(T)' : '(P)',
    externoName: onlyOwnerData.tenedor ? onlyOwnerData.tenedor : onlyOwnerData.propietario,
  };

  return (
    <span className={`${vehiculoData.isOurAsset ? 'text-green-600' : 'text-red-600'} `}>
      {`${vehiculoData.isOurAssetText || '...'} ${(!vehiculoData.isOurAsset && vehiculoData.externoText) || ''} ${
        (!vehiculoData.isOurAsset && vehiculoData.externoName) || ''
      }`}
    </span>
  );
};

const ConductorLabel = ({ data }) => {
  const employeeData = {
    isEmployee: data.CONDUCTOR_ES_EMPLEADO === 1,
    isEmployeeMsg: data.CONDUCTOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
  };
  return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
};

const AparejadorLabel = ({ data }) => {
  const employeeData = {
    isEmployee: data.APAREJADOR_ES_EMPLEADO === 1,
    isEmployeeMsg: data.APAREJADOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
  };
  return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
};

const unidadOptions = [
  { value: 'GLL', label: 'Galones' },
  { value: 'KGM', label: 'Kilogramos' },
];

const tipoServicioOptions = [
  { value: '0', label: 'Servicio adicional' },
  { value: '1', label: 'Remesa de transp. Registrada en el RNDC' },
];

export const ModalManifiesto = (props) => {
  const { setModalManifiesto } = props;

  const selTipoVehiculo = props.tipoVehiculo.selTipoVehiculo;

  const manifiestoEstado = selTipoVehiculo.SOLICITUD_VEHICULO_MANIFIESTO_ESTADO;
  const { notificarManifiesto } = props.endPoint;

  const conductorPercent = selTipoVehiculo.SOLICITUD_VEHICULO_P1;
  const conductorStartPercent = selTipoVehiculo.SOLICITUD_VEHICULO_P2;
  const aparejadorPercent = selTipoVehiculo.SOLICITUD_VEHICULO_P3;
  const aparejadorStartPercent = selTipoVehiculo.SOLICITUD_VEHICULO_P4;

  const setSOLICITUD_VEHICULO_TIPO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['SOLICITUD_VEHICULO_TIPO'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setVEHICULO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['VEHICULO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setTRAILER_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TRAILER_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setCONDUCTOR_TERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONDUCTOR_TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const hasAparejador = selTipoVehiculo.APAREJADOR_DOC ? true : false;
  const hasTrailer = selTipoVehiculo.TRAILER_PLACA ? true : false;

  const handleAddTarifa = (tarifa) => {};
  const handleChangeTarifaUnidad = (tarifa) => {};

  const proxyTask = async (form) => {
    console.log('proxyTask: ', form);
    const editMode = false;
    form['TERCERO_ID'] = props.auth.data.user.TERCERO_ID;
    // form['SOLICITUD_VEHICULO_TIPO_ID'] = selTipoVehiculo.SOLICITUD_VEHICULO_TIPO_ID;
    form['MANIFIESTO_ESTADO_ID'] = selTipoVehiculo.MANIFIESTO_ESTADO_ACC_FIN;
    form['MANIFIESTO_ESTADO_ACC_ID'] = selTipoVehiculo.MANIFIESTO_ESTADO_ACC_ID;

    if (editMode) {
    } else {
      notificarManifiesto(form);
    }
  };

  const onMANIFIESTO_UNIDAD = (args, state, { setIn, changeValue }) => {
    const field = state.fields['MANIFIESTO_UNIDAD'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const onMANIFIESTO_TIPO_SERVICIO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['MANIFIESTO_TIPO_SERVICIO'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const validate = (values) => {
    const errors = {};

    // const requiredFields = ['SOLICITUD_MANIFIESTO_OBSERVACION'];
    const requiredFields = [];

    if (manifiestoEstado === 1) {
      requiredFields.push('MANIFIESTO_RNDC');
      requiredFields.push('MANIFIESTO_CANTIDAD');
    }

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = '*';
      }
    });
    return errors;
  };

  const manifiestoAction = selTipoVehiculo.SOLICITUD_ESTADO_ID;
  console.log('manifiestoAction: ', manifiestoAction);

  const WindowTitle = () => {
    const state = props.tipoVehiculo.selTipoVehiculo.MANIFIESTO_ESTADO_ACC_ID;
    const description = props.tipoVehiculo.selTipoVehiculo.MANIFIESTO_ESTADO_ACC_DESC;

    return 'MANIFIESTO - ' + description.toUpperCase() + '. ';
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>
          <WindowTitle />
        </ModalTitle>
        <TableManifiesto />
        <div className="px-5">
          <hr className="mt-1" />
          <PanelVehiculoTarifa
            handleAddTarifa={handleAddTarifa}
            handleChangeTarifaUnidad={handleChangeTarifaUnidad}
            data={{ selSolicitudVehiculoTipo: props.tipoVehiculo.selTipoVehiculo }}
          />
        </div>
        <Form
          onSubmit={proxyTask}
          validate={validate}
          initialValues={{
            SOLICITUD_ID: props.Solicitud.selSolicitud.SOLICITUD_ID,
            SOLICITUD_VEHICULO_TIPO_ID: props.tipoVehiculo.selTipoVehiculo.SOLICITUD_VEHICULO_TIPO_ID,
            SOLICITUD_PESO: selTipoVehiculo.SOLICITUD_PESO,
            CONDUCTOR_VALOR: selTipoVehiculo.CONDUCTOR_VALOR,
            CONDUCTOR_ANTICIPO: selTipoVehiculo.CONDUCTOR_ANTICIPO,

            VEHICULO_PLACA: selTipoVehiculo.VEHICULO_PLACA,
            TRAILER_PLACA: selTipoVehiculo.TRAILER_PLACA,
            CONDUCTOR_NOMBRE: selTipoVehiculo.CONDUCTOR_NOMBRE,
            APAREJADOR_NOMBRE: selTipoVehiculo.APAREJADOR_NOMBRE,
            CONDUCTOR_VALOR: formatterPeso(selTipoVehiculo.CONDUCTOR_VALOR),
            CONDUCTOR_ANTICIPO: formatterPeso(selTipoVehiculo.CONDUCTOR_ANTICIPO),
            APAREJADOR_VALOR: formatterPeso(selTipoVehiculo.APAREJADOR_VALOR),
            APAREJADOR_ANTICIPO: formatterPeso(selTipoVehiculo.APAREJADOR_ANTICIPO),
            TERCERO_ID: props.auth.data.user.TERCERO_ID,
            SOLICITUD_ESTADO_ID: manifiestoAction,
            MANIFIESTO_CONSECUTIVO: selTipoVehiculo.MANIFIESTO_CONSECUTIVO_NOTIFICA,
            MANIFIESTO_REMESA: selTipoVehiculo.MANIFIESTO_REMESA_NOTIFICA,
            MANIFIESTO_RNDC: selTipoVehiculo.MANIFIESTO_RNDC,
            SOLICITUD_MANIFIESTO_OBSERVACION: selTipoVehiculo.SOLICITUD_MANIFIESTO_OBSERVACION,
            MANIFIESTO_UNIDAD: unidadOptions[0].value,
            MANIFIESTO_TIPO_SERVICIO: tipoServicioOptions[1].value,
            MANIFIESTO_CANTIDAD: selTipoVehiculo.SOLICITUD_PESO,
          }}
          mutators={{
            onMANIFIESTO_UNIDAD,
            onMANIFIESTO_TIPO_SERVICIO,
          }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <FormStateToRedux form="frmManifiesto" />

                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/3">
                      <Field name="VEHICULO_PLACA">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Vehículo <VehiculoLabel data={selTipoVehiculo} />
                            </p>
                            <input
                              {...input}
                              readOnly
                              type="text"
                              placeholder="Peso"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      {hasTrailer && (
                        <Field name="TRAILER_PLACA">
                          {({ input, meta }) => (
                            <>
                              <p className="text-sm text-gray-800">
                                Trailer <TrailerLabel data={selTipoVehiculo} />
                              </p>
                              <input
                                {...input}
                                type="text"
                                readOnly
                                placeholder="Peso"
                                className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                              />
                            </>
                          )}
                        </Field>
                      )}
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="SOLICITUD_PESO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Peso</p>
                            <input
                              {...input}
                              type="text"
                              readOnly
                              placeholder="Peso"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/3">
                      <Field name="CONDUCTOR_NOMBRE">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Conductor / Operador <ConductorLabel data={selTipoVehiculo} />
                            </p>
                            <input
                              {...input}
                              type="text"
                              readOnly
                              placeholder="Peso"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="CONDUCTOR_VALOR">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Costo Servicio <span className=" text-green-500"> {conductorPercent}%</span>
                            </p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Costo Servicio"
                              readOnly
                              onChange={form.mutators.setCONDUCTOR_VALOR}
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="CONDUCTOR_ANTICIPO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Anticipo <span className=" text-green-500"> {conductorStartPercent}% </span>
                            </p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Anticipo"
                              readOnly
                              onChange={form.mutators.setCONDUCTOR_ANTICIPO}
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  {hasAparejador && (
                    <>
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/3">
                          <Field name="APAREJADOR_NOMBRE">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">
                                  Aparejador / Auxiliar <AparejadorLabel data={selTipoVehiculo} />
                                </p>
                                <input
                                  {...input}
                                  type="text"
                                  readOnly
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/3">
                          <Field name="APAREJADOR_VALOR">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">
                                  Costo Servicio <span className=" text-green-500">{aparejadorPercent}%</span>
                                </p>
                                <input
                                  {...input}
                                  type="text"
                                  readOnly
                                  placeholder="Costo Servicio"
                                  onChange={form.mutators.setAPAREJADOR_VALOR}
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/3">
                          <Field name="APAREJADOR_ANTICIPO">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">
                                  Anticipo <span className=" text-green-500">{aparejadorStartPercent} %</span>
                                </p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Anticipo"
                                  readOnly
                                  onChange={form.mutators.setAPAREJADOR_ANTICIPO}
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="flex  space-x-4">
                    <div className="mt-1 w-full">
                      <Field name="SOLICITUD_MANIFIESTO_OBSERVACION">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Nota</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Nota"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  {manifiestoEstado !== 0 && (
                    <>
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/3">
                          <Field name="MANIFIESTO_CONSECUTIVO">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Manifiesto consecutivo NO.</p>
                                <input
                                  {...input}
                                  // readOnly
                                  type="text"
                                  placeholder="MANIFIESTO CONSECUTIVO NO."
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/3">
                          <Field name="MANIFIESTO_REMESA">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Remesa NO.</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="REMESA NO."
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/3">
                          <Field name="MANIFIESTO_RNDC">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Nro. Acept. Remesa</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Nro. Acept. Remesa"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/3">
                          <Field name="MANIFIESTO_CANTIDAD">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Cantidad Transportada</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="CANTIDAD TRANSPORTADA"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/3">
                          <p className="mt-3 text-sm text-gray-800">Unidad de Medida</p>
                          <Field name="MANIFIESTO_UNIDAD">
                            {({ input, meta }) => (
                              <>
                                {unidadOptions.length > 0 && (
                                  <Select
                                    options={unidadOptions}
                                    defaultValue={unidadOptions[0]}
                                    name="selectUnidad"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: meta.error && meta.touched ? 'red' : null,
                                      }),
                                    }}
                                    onChange={form.mutators.onMANIFIESTO_UNIDAD}
                                  />
                                )}
                                <input
                                  {...input}
                                  type="hidden"
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/3">
                          <p className="mt-3 text-sm text-gray-800">Tipo de servicio.</p>
                          <Field name="MANIFIESTO_TIPO_SERVICIO">
                            {({ input, meta }) => (
                              <>
                                {tipoServicioOptions.length > 0 && (
                                  <Select
                                    options={tipoServicioOptions}
                                    defaultValue={tipoServicioOptions[1]}
                                    name="selectUnidad"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: meta.error && meta.touched ? 'red' : null,
                                      }),
                                    }}
                                    onChange={form.mutators.onMANIFIESTO_TIPO_SERVICIO}
                                  />
                                )}
                                <input
                                  {...input}
                                  type="hidden"
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalManifiesto(false)}>Cancelar</ButtonTable>
                  {/* <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable> */}

                  {submitting ? (
                    <ButtonTable
                      type="button"
                      disabled>
                      Enviando...
                    </ButtonTable>
                  ) : (
                    <ButtonTable
                      type="submit"
                      disabled={submitting || pristine}>
                      Enviar
                    </ButtonTable>
                  )}
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    roles: state.roles,
    tipoVehiculo: state.tipoVehiculo,
    Solicitud: state.Solicitud,
    auth: state.auth,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ModalManifiesto);
