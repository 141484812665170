import React from 'react';
import { connect } from 'react-redux';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { fixOnlyDateForTable } from '../../../../utils/format';
const TbodyTableParametroGeneral = (props) => {
  const { parametroData } = props.data;

  const { handleEdit } = props.actions;

  return (
    <tbody className="">
      {parametroData.map((item) => (
        <tr
          key={item.PARAMETRO_ID}
          className="border-b">
          <td className="px-1 text-left">{item.PARAMETRO_ID}</td>
          <td className=" px-1 text-left">{item.PARAMETRO_DESCRIPCION}</td>
          <td className=" px-1 text-left">{item.PARAMETRO_VALOR}</td>
          <td className=" px-1 text-left">{item.PARAMETRO_CLIENTE}</td>
          <td className=" px-1 text-left">{item.PARAMETRO_TIPO}</td>

          <td className=" px-1 text-left">{fixOnlyDateForTable(item.UPDATED_AT)}</td>
          <td className=" px-1 text-left">{fixOnlyDateForTable(item.CREATED_AT)}</td>
          <td className=" ">
            <PencilIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleEdit(item)}
            />
          </td>
          <td className=" "></td>
        </tr>
      ))}
    </tbody>
  );
};

export default TbodyTableParametroGeneral;
