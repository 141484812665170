import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ViewLandingPage from './ViewLandingPage';
import { useApiClient } from '../../clients/apiAuth';

import { store } from '../../store';
import { authInit, setTerceroInit } from '../../actions';
import { connect } from 'react-redux';
import { authLogin } from '../../actions';
import { validateSuccess2xx } from '../../clients/apiAuth';
import { OkToast, ErrorToast } from '../../components/specific/Toast';
import { useParams } from 'react-router-dom';

const mapToRoute = (route, history) => {
  history.push('/dashboard');
};
const LandingPage = (props) => {
  const { post } = useApiClient();
  const history = useHistory();

  const [loadingBoard, setLoadingBoard] = useState(false);

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const { tokenData } = useParams();

  useEffect(() => {
    store.dispatch(authInit());
    store.dispatch(setTerceroInit());

    // const package_json = require('../../../package.json');
  }, []);

  const login = async () => {
    setLoadingBoard(true);
    try {
      if (!form.email || !form.password) {
        ErrorToast('', 'E-Mail o contraseña incorrecta!');
        return;
      }
      const result = await post(
        '/login',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );
      if (!validateSuccess2xx(result)) {
        ErrorToast('', 'E-Mail o contraseña incorrecta!');
        setLoadingBoard(false);
        return;
      }
      store.dispatch(authLogin(result));
      OkToast({ result, message: 'Iniciando Sesion...' });

      mapToRoute(tokenData, history);
    } catch (error) {}
    setLoadingBoard(false);
  };

  return (
    <ViewLandingPage
      form={form}
      setForm={setForm}
      login={login}
      history={history}
      loadingBoard={loadingBoard}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    companies: state.companies,
  };
};

export default connect(mapStateToProps)(LandingPage);
