import React from 'react';
const TheadTableBono = () => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Bono</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Rol</th>
        <th className="px-4 py-3 text-right text-sm font-semibold uppercase">Valor</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">{''}</th>
      </tr>
    </thead>
  );
};
export default TheadTableBono;
