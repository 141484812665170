import React from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Persona } from '../../../assets/svg/Persona.svg';
import { setSelRoles } from '../../../../actions';
import { store } from '../../../../store';
import { fixOnlyDateForTable } from '../../../../utils/format';

const TbodyTableRoles = (props) => {
  const infoRoles = props.roles.roles;

  const { setModalAgregarRolPersonas } = props;
  const { getTeceroRol } = props.endPoint;
  const { setTercerosRol } = props.actions;

  const handleRol = (item) => {
    setTercerosRol([]);
    getTeceroRol(item.ROL_ID);
    setModalAgregarRolPersonas(true);
    store.dispatch(setSelRoles(item));
  };

  return (
    <tbody className="text-gray-700">
      {infoRoles.map((item) => (
        <tr
          key={item.ROL_ID}
          className="even:bg-gray-50">
          <td className="px-1 text-left">{item.ROL_ID}</td>
          <td className="px-1 text-left">{item.ROL_NOMBRE}</td>
          <td className="px-1 text-left">
            <a className="hover:text-blue-500">{fixOnlyDateForTable(item.UPDATED_AT)}</a>
          </td>
          <td className="px-1 text-left">{fixOnlyDateForTable(item.CREATED_AT)}</td>
          <td className=" ">
            <Persona
              className="hover:fill-blue-500 mt-1 w-8 transform cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleRol(item)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableRoles);
