import React from 'react';
import TableAnticipoTbody from './TableAnticipoTbody';
import TableAnticipoThead from './TableAnticipoThead';

const TableAnticipo = (props) => {
  const { handleEdit } = props;
  const { loadingTables } = props.data;

  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TableAnticipoThead data={props.data} />
          <TableAnticipoTbody
            endPoint={props.endPoint}
            data={props.data}
            modals={props.modals}
            handleEdit={handleEdit}
          />
        </table>
      )}
    </div>
  );
};
export default TableAnticipo;
