import React from 'react';
import TbodyTableRoles from './tbody';
import TheadTableRoles from './thead';
const TableRoles = (props) => {
  const { setModalAgregarRolPersonas } = props;
  const { loadingTables } = props.actions;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTableRoles />
          <TbodyTableRoles
            setModalAgregarRolPersonas={setModalAgregarRolPersonas}
            endPoint={props.endPoint}
            actions={props.actions}
          />
        </table>
      )}
    </div>
  );
};
export default TableRoles;
