import React from 'react';
import Nav from '../../../components/specific/Navigation';
//import Filter from '../../../components/specific/Regional/Filter';
import { ModalAgregarRegional } from '../../../components/specific/Regional/Modales/agregarRegional.js';
import TableRegional from '../../../components/specific/Regional/Table';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

const ViewRegional = (props) => {
  const { modalAgregarRegional, setModalModalAgregarRegional, createRegional, validate } = props.modal;
  const { loadingTables } = props.actions;

  const { getRoles } = props;
  return (
    <div>
      <Nav />
      <Toaster />
      <div className="md:px-20 w-full py-8">
        <div>
          <h2 className="... mx-8 text-2xl font-semibold leading-tight">Regionales</h2>
        </div>
        {/*<Filter setModalModalAgregarRegional={setModalModalAgregarRegional} />*/}
        <div className="... mx-8 my-8 overflow-hidden rounded border-b border-gray-200 shadow">
          {loadingTables ? <LoadingTables /> : <TableRegional actions={props.actions} />}
        </div>
      </div>
      {modalAgregarRegional ? (
        <ModalAgregarRegional
          endPoint={props.endPoint}
          agregarRegional={{ createRegional, validate }}
          setModalModalAgregarRegional={setModalModalAgregarRegional}
          getRoles={getRoles}
        />
      ) : null}
    </div>
  );
};

export default ViewRegional;
