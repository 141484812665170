import React, { useState, useEffect } from 'react';
import ViewRegional from './ViewRegional';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setRegional, setSelRegional, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Regional = (props) => {
  const { get, post, put, DELETE } = useApiClient();
  const [loadingTables, setLoadingTables] = useState(false);
  const [modalAgregarRegional, setModalModalAgregarRegional] = useState(false);

  const getRegional = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/regional`);

      store.dispatch(setRegional(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const createRegional = async (form) => {
    try {
      const result = await post(
        '/Regional',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarRegional(false);
        OkToast({ result, message: 'Regional Agregada!' });
        getRegional();
      }
    } catch (error) {}
  };

  const updateRegional = async (form) => {
    try {
      const result = await put(
        `/Regional/${form.Regional_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarRegional(false);
        OkToast({ result, message: 'Regional Actualizada!' });
        getRegional();
      }
    } catch (error) {}
  };

  function handleEdit(obj) {
    console.log('handleEdit: ', obj);

    store.dispatch(setSelRegional(obj));
    setModalModalAgregarRegional(true);
  }
  const deleteRegional = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/Regional/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Regional Eliminada!' });
        getRegional();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };

  function handleDeleteRegional(rowID) {
    toast((t) => (
      <span>
        <b>¿Está seguro de querer eliminar Regional?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteRegional(rowID);
          }}>
          Sí
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  }

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['Regional_DESCRIPCION'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  useEffect(() => {
    getRegional();
  }, []);

  return (
    <ViewRegional
      endPoint={{ getRegional, createRegional, updateRegional }}
      modal={{
        modalAgregarRegional,
        setModalModalAgregarRegional,
        validate,
        createRegional,
      }}
      actions={{ loadingTables, handleDeleteRegional, handleEdit }}
    />
  );
};

const mapStateToProps = function (state) {
  return { Regional: state.Regional };
};

//export default Projects;
export default connect(mapStateToProps)(Regional);
