import { useApiClient } from '../../../clients/apiAuth';

const Repository = () => {
  const { get, DELETE } = useApiClient();

  const getFilter = async (filter) => await get(`/zona?${filter}`);
  const remove = async (recordID) => await DELETE(`/zona/${recordID}`);
  const getAll = async () => await get(`/zona`);

  return {
    getFilter,
    getAll,
    remove,
  };
};

export const ApiZonaRepository = {
  Repository,
};
