import React from 'react';
import { setSelBonoInit } from '../../../../actions';
import { store } from '../../../../store';
import { ButtonTable } from '../../../global/Styles/buttons';

const Filter = (props) => {
  const { setModalAgregarBono } = props.modal;

  const handleClick = () => {
    console.info('handleClick Bono!!!');
    store.dispatch(setSelBonoInit());
    setModalAgregarBono(true);
  };

  return (
    <div className="mx-10 -mt-8 flex py-2">
      <div className=" flex-none">
        <button className="flex justify-between space-x-4 text-gray-800"></button>
      </div>
      <div className=" flex-grow"></div>
      <div className="flex-none space-x-1">
        <ButtonTable
          className="py-2"
          onClick={handleClick}>
          Agregar
        </ButtonTable>
      </div>
    </div>
  );
};
export default Filter;
