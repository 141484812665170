import React from 'react';
const TheadTableTarifas = () => {
  return (
    <thead className="  sticky top-0 z-10   bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className=" px-1 text-left text-sm font-semibold uppercase">Consecutivo</th>
        <th className=" px-1 text-left text-sm font-semibold uppercase">Contrato</th>
        <th className=" px-1 text-left text-sm font-semibold uppercase">Categoría</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Grupo</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Unidad</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Item</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Nombre</th>
        {/* <th className="px-1 text-right text-sm font-semibold uppercase">Moneda</th> */}
        <th className="px-1 text-right text-sm font-semibold uppercase">Valor</th>
        <th className="px-1 text-right text-sm font-semibold uppercase">SIESA ITEM</th>
        <th className="px-1 text-right text-sm font-semibold uppercase">SIESA U.N.</th>
        <th
          scope="col"
          className="w-2"></th>
        <th
          scope="col"
          className="w-2"></th>
      </tr>
    </thead>
  );
};
export default TheadTableTarifas;
