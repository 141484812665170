import React from 'react';
import { setSelMarcaInit } from '../../../../actions';
import { store } from '../../../../store';
import { ButtonTable } from '../../../global/Styles/buttons';

const Filter = (props) => {
  const { setModalModalAgregarMarca } = props;

  const handleClick = () => {
    store.dispatch(setSelMarcaInit());
    setModalModalAgregarMarca(true);
  };
  return (
    <div className="sm:flex-row mt-5 flex flex-col justify-between">
      <div className="sm:mb-0 flex flex-row space-x-4"></div>
      <div>
        <ButtonTable
          className="py-2"
          onClick={handleClick}>
          Agregar
        </ButtonTable>
      </div>
    </div>
  );
};
export default Filter;
