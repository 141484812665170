import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MenuMaker = (props) => {
  const history = useHistory();

  const data = props.data;
  const text = props.text;

  const firstItem = data[0];
  const lastItem = data[data.length - 1];
  data.shift();
  data.pop();

  return (
    <div className="dropdown relative z-20 inline-block">
      <button className="  cursor-pointer text-gray-200 hover:text-gray-500">{text}</button>
      <ul className="dropdown-menu absolute hidden w-56 text-gray-100">
        {/* INICIO  */}
        <li className="">
          <div
            className="whitespace-no-wrap block cursor-pointer rounded-t bg-gray-600  px-2 py-1 text-sm hover:bg-gray-400 hover:text-gray-700"
            onClick={() => history.push(firstItem.url)}>
            {firstItem.name}
          </div>
        </li>

        {data.map((row, index) => (
          <li
            className=""
            key={index}>
            <div
              onClick={() => history.push(row.url)}
              className="whitespace-no-wrap  block bg-gray-600 px-2 py-1 text-sm hover:bg-gray-400 hover:text-gray-700"
              href="#">
              <span className="mr-1"> {row.name}</span>
            </div>
          </li>
        ))}

        {/* FIN  */}
        <li className="">
          <div
            className=" whitespace-no-wrap block cursor-pointer rounded-b bg-gray-600 px-2 py-1 text-sm hover:bg-gray-400 hover:text-gray-700"
            onClick={() => history.push(lastItem.url)}>
            {lastItem.name}
          </div>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(MenuMaker);
