import React from 'react';
import TbodyTableManifiesto from './tbody';
import TheadTableManifiesto from './thead';
const TableManifiesto = (props) => {
  return (
    <table className="mx-4  mt-4 table-auto text-sm">
      <TheadTableManifiesto />
      <TbodyTableManifiesto />
    </table>
  );
};
export default TableManifiesto;
