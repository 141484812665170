import React from 'react';
import Select from 'react-select';

const TbodyTableParametroCliente = (props) => {
  const data = props.data;

  const { handleEdit, handleDelete } = props.actions;

  const setSelectedOption = (e, item) => {
    item.PARAMETRO_CLI_VALUE = e.value;
  };

  const setTextOption = (value, item) => {
    item.PARAMETRO_CLI_VALUE = value;
  };

  return (
    <tbody className="text-gray-700">
      {data.map((item) => (
        <tr key={item.PARAMETRO_CLI_ID}>
          <td>{item.PARAMETRO_DESCRIPCION}</td>
          <td> {select(item, setSelectedOption, setTextOption)}</td>
        </tr>
      ))}
    </tbody>
  );
};

const select = (items, setSelectedOption, setTextOption) => {
  if (items.PARAMETRO_VALOR != null) {
    let item = JSON.parse(items.PARAMETRO_VALOR);

    return (
      <Select
        defaultValue={item.options.find((i) => i.value.toString() === items.PARAMETRO_CLI_VALUE)}
        options={item.options}
        name="selectRol"
        className=""
        onChange={(e) => setSelectedOption(e, items)}
      />
    );
  } else {
    return (
      <textarea
        name={items.PARAMETRO_CLI_ID}
        defaultValue={items.PARAMETRO_CLI_VALUE}
        onBlur={(e) => setTextOption(e.target.value, items)}
        rows={6}
        cols={40}
        className="focus:outline-none mt-2 h-10 w-full rounded border bg-gray-200 px-3 py-4 text-gray-800 focus:border-blue-500"
      />
    );
  }
};

//export default Projects;
export default TbodyTableParametroCliente;
