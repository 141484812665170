const initialState = {};

export const environment = (state = initialState, action) => {
  switch (action.type) {
    case 'setEnvironment': {
      const stateData = { ...action.value };
      return { ...state, ...stateData };
    }
    case 'setEnvironmentInit': {
      return initialState;
    }
    default:
      //console.log("LOG_default->" + action.type);
      return state;
  }
};
