import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { ReactComponent as Persona } from '../../../assets/svg/Persona.svg';
import { emptyNull } from '../../../../utils/format';

const TbodyTableCliente = (props) => {
  const data = props.data?.Cliente?.data;

  const { handleEdit, handleDelete, handleAddUsers } = props.actions;

  return <tbody className="text-gray-700">{data && generateRows(data, handleEdit, handleDelete, handleAddUsers)}</tbody>;
};

const generateRows = (data, handleEdit, handleDelete, handleAddUsers) => {
  return data?.tercero?.map((item) => (
    <tr key={item.pivot.TERCERO_ROL_ID}>
      <td className="px-1 text-left">{item.TERCERO_ID}</td>
      <td className="px-1 text-left">{item.TERCERO_DOCUMENTO}</td>
      <td className="px-1 text-left">{`${emptyNull(item.TERCERO_NOMBRE1)} ${emptyNull(item.TERCERO_NOMBRE2)}`}</td>
      <td className="px-1 text-left">{`${emptyNull(item.TERCERO_APELLIDO1)} ${emptyNull(item.TERCERO_APELLIDO2)}`}</td>
      <td className="px-1 text-left">{item.TERCERO_RAZON_SOCIAL}</td>
      <td className="px-1 text-left">{item.TERCERO_MAIL}</td>
      <td className="px-1 text-left">{item.TERCERO_CEL}</td>
      <td className="flex space-x-2 py-3 text-left">
        <Edit
          className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
          onClick={() => handleEdit(item)}
        />
        <Persona
          className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
          onClick={() => handleAddUsers(item)}
        />
        <Trash
          className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
          onClick={() => handleDelete(item.TERCERO_ID)}
        />
      </td>
    </tr>
  ));
};

export default TbodyTableCliente;
