//Login
export const authLogin = (value) => ({ type: 'LOG_IN', value });
export const authLoginRefresh = (value) => ({
  type: 'authLoginRefresh',
  value,
});
export const authInit = (value) => ({ type: 'init', value });
export const authLogout = (value) => ({ type: 'LOG_OUT', value });
export const authSetSelCliente = (value) => ({ type: 'authSetSelCliente', value });

export const setRoles = (value) => ({ type: 'setRoles', value });
export const setRolesInit = (value) => ({ type: 'setRolesInit', value });
export const setSelRoles = (value) => ({ type: 'setSelRoles', value });
export const setSelRolesInit = (value) => ({ type: 'setSelRolesInit', value });

export const setContrato = (value) => ({ type: 'setContrato', value });
export const setContratoInit = (value) => ({ type: 'setContratoInit', value });
export const setSelContrato = (value) => ({ type: 'setSelContrato', value });
export const setSelContratoInit = (value) => ({
  type: 'setSelContratoInit',
  value,
});

export const setTercero = (value) => ({ type: 'setTercero', value });
export const setTerceroInit = (value) => ({ type: 'setTerceroInit', value });
export const setSelTercero = (value) => ({ type: 'setSelTercero', value });
export const setSelTerceroInit = (value) => ({
  type: 'setSelTerceroInit',
  value,
});

export const setTipoVehiculo = (value) => ({ type: 'setTipoVehiculo', value });
export const setTipoVehiculoInit = (value) => ({
  type: 'setTipoVehiculoInit',
  value,
});
export const setSelTipoVehiculo = (value) => ({
  type: 'setSelTipoVehiculo',
  value,
});
export const setSelTipoVehiculoInit = (value) => ({
  type: 'setSelTipoVehiculoInit',
  value,
});

export const setVehiculo = (value) => ({ type: 'setVehiculo', value });
export const setVehiculoInit = (value) => ({ type: 'setVehiculoInit', value });
export const setSelVehiculo = (value) => ({ type: 'setSelVehiculo', value });
export const setSelVehiculoInit = (value) => ({
  type: 'setSelVehiculoInit',
  value,
});

export const setMarca = (value) => ({ type: 'setMarca', value });
export const setMarcaInit = (value) => ({ type: 'setMarcaInit', value });
export const setSelMarca = (value) => ({ type: 'setSelMarca', value });
export const setSelMarcaInit = (value) => ({ type: 'setSelMarcaInit', value });

export const setZona = (value) => ({ type: 'setZona', value });
export const setZonaInit = (value) => ({ type: 'setZonaInit', value });
export const setSelZona = (value) => ({ type: 'setSelZona', value });
export const setSelZonaInit = (value) => ({ type: 'setSelZonaInit', value });

export const setTrailer = (value) => ({ type: 'setTrailer', value });
export const setTrailerInit = (value) => ({ type: 'setTrailerInit', value });
export const setSelTrailer = (value) => ({ type: 'setSelTrailer', value });
export const setSelTrailerInit = (value) => ({
  type: 'setSelTrailerInit',
  value,
});

export const setTarifa = (value) => ({ type: 'setTarifa', value });
export const setTarifaInit = (value) => ({ type: 'setTarifaInit', value });
export const setSelTarifa = (value) => ({ type: 'setSelTarifa', value });
export const setSelTarifaInit = (value) => ({
  type: 'setSelTarifaInit',
  value,
});

export const setTipoDocumento = (value) => ({
  type: 'setTipoDocumento',
  value,
});
export const setTipoDocumentoInit = (value) => ({
  type: 'setTipoDocumentoInit',
  value,
});
export const setSelTipoDocumento = (value) => ({
  type: 'setSelTipoDocumento',
  value,
});
export const setSelTipoDocumentoInit = (value) => ({
  type: 'setSelTipoDocumentoInit',
  value,
});

export const setEnvironment = (value) => ({ type: 'setEnvironment', value });

export const setSolicitud = (value) => ({ type: 'setSolicitud', value });
export const setNuevaSolicitud = (value) => ({ type: 'setNuevaSolicitud', value });
export const setSolicitudInit = (value) => ({ type: 'setSolicitudInit', value });
export const setSelSolicitud = (value) => ({ type: 'setSelSolicitud', value });
export const setSelSolicitudLog = (value) => ({ type: 'setSelSolicitudLog', value });
export const setSelSolicitudInit = (value) => ({ type: 'setSelSolicitudInit', value });

//loading animation
export const showLoadingAnimation = (value) => ({ type: 'show' });
export const hideLoadingAnimation = (value) => ({ type: 'hide' });

export const setBono = (value) => ({ type: 'setBono', value });
export const setBonoInit = (value) => ({ type: 'setBonoInit', value });
export const setSelBono = (value) => ({ type: 'setSelBono', value });
export const setSelBonoInit = (value) => ({
  type: 'setSelBonoInit',
  value,
});

export const setCliente = (value) => ({ type: 'setCliente', value });
export const setClienteInit = (value) => ({ type: 'setClienteInit', value });
export const setSelCliente = (value) => ({ type: 'setSelCliente', value });
export const setSelClienteInit = (value) => ({
  type: 'setSelClienteInit',
  value,
});

export const setAsignarBono = (value) => ({ type: 'setAsignarBono', value });
export const setAsignarBonoInit = (value) => ({ type: 'setAsignarBonoInit', value });
export const setSelAsignarBono = (value) => ({ type: 'setSelAsignarBono', value });
export const setAsignarSelBonoInit = (value) => ({
  type: 'setSelAsignarBonoInit',
  value,
});

export const setCentroOperacion = (value) => ({ type: 'setCentroOperacion', value });
export const setCentroOperacionInit = (value) => ({ type: 'setCentroOperacionInit', value });
export const setSelCentroOperacion = (value) => ({ type: 'setSelCentroOperacion', value });
export const setSelCentroOperacionInit = (value) => ({ type: 'setSelCentroOperacionInit', value });

export const setUnidadNegocio = (value) => ({ type: 'setUnidadNegocio', value });
export const setUnidadNegocioInit = (value) => ({ type: 'setUnidadNegocioInit', value });
export const setSelUnidadNegocio = (value) => ({ type: 'setSelUnidadNegocio', value });
export const setSelUnidadNegocioInit = (value) => ({ type: 'setSelUnidadNegocioInit', value });

export const setRegional = (value) => ({ type: 'setRegional', value });
export const setRegionalInit = (value) => ({ type: 'setRegionalInit', value });
export const setSelRegional = (value) => ({ type: 'setSelRegional', value });
export const setSelRegionalInit = (value) => ({ type: 'setSelRegionalInit', value });

export const setLugar = (value) => ({ type: 'setLugar', value });
export const setLugarInit = (value) => ({ type: 'setLugarInit', value });
export const setSelLugar = (value) => ({ type: 'setSelLugar', value });
export const setSelLugarInit = (value) => ({ type: 'setSelLugarInit', value });

export const setImportarTarifa = (value) => ({ type: 'setImportarTarifa', value });
export const setImportarTarifaInit = (value) => ({ type: 'setImportarTarifaInit', value });
export const setSelImportarTarifa = (value) => ({ type: 'setSelImportarTarifa', value });
export const setSelImportarTarifaInit = (value) => ({ type: 'setSelImportarTarifaInit', value });
