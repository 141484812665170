import React from 'react';
const TheadTableZona = () => {
  return (
    <thead className="  sticky top-0 z-10   bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className="px-1 text-left text-sm font-semibold uppercase">Id</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Descripción</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Prefijo</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Consecutivo</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Cliente</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Contrato</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Rev.</th>

        <th
          scope="col"
          className="w-2"></th>
        <th
          scope="col"
          className="w-2"></th>
      </tr>
    </thead>
  );
};
export default TheadTableZona;
