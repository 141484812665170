import React from 'react';
const TheadTableRoles = () => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Id</th>
        <th className="px-4 py-3 text-right text-sm font-semibold uppercase">Numero de contrato</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Operación modo</th>
        <th className="px-4 py-3 text-right text-sm font-semibold uppercase">Presupuesto</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Inicio</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Fin</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Creación</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Actualización</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Estado</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">{''}</th>
      </tr>
    </thead>
  );
};
export default TheadTableRoles;
