const initialState = {
  isData: false,
  data: [],
  selAsignarBono: null,
  pagination: null,
};

const initialAsignarBonoState = {
  selAsignarBono: null,
};

export const AsignarBono = (state = initialState, action) => {
  switch (action.type) {
    case 'setAsignarBono': {
      if (action.value.status === 200) {
        const stateData = {
          data: action.value.data,
          isData: true,
          pagination: action.value.pagination,
        };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setAsignarBonoInit': {
      return initialState;
    }
    case 'setSelAsignarBono': {
      const obj = {
        ...action.value,
      };

      const stateData = { selAsignarBono: obj };

      return { ...state, ...stateData };
    }
    case 'setSelAsignarBonoInit': {
      return { ...state, ...initialAsignarBonoState };
    }

    default:
      //
      return state;
  }
};
