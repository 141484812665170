import React from 'react';

import { store } from '../../../../store';
import { setSelSolicitud, setSelTipoVehiculo } from '../../../../actions';
import { CheckPermission, CheckParameter } from '../../../../utils/security';
import ReactTooltip from 'react-tooltip';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { ToolTipWrap } from './ToolTipWrap';
import { ConfirmarIcon } from './ConfirmarIcon';
import { AnticipoIcon } from './AnticipoIcon';
import { NuevoAnticipoIcon } from './NuevoAnticipoIcon';

import { ManifiestoIcon } from './ManifiestoIcon';
import { solicitudEstados } from './solicitudEstados';
import moment from 'moment';

import { WrenchScrewdriverIcon, ClipboardDocumentListIcon, DocumentIcon, EllipsisVerticalIcon, CodeBracketSquareIcon } from '@heroicons/react/24/outline';

import { ErrorToast } from '../../Toast';
import { ReporteFinalIcon } from './ReporteFinalIcon';

const SiesaLogIcon = ({ row, resendSiesaFD, handleShowSiesaLog }) => {
  if (row['SOLICITUD_APROBADA'] < 6) return;

  const isAdmin = CheckPermission('Coordinador') || CheckPermission('Administrador');

  if (!isAdmin) return;

  if (!row.SIESA_ESTADO) {
    console.log('ERROR row: ', row);

    const responseCode = 1;
    const responseMensaje = 'No enviado...';

    const color = 'text-red-500';

    return (
      <ToolTipWrap
        itemID={row.SOLICITUD_ID}
        componentPrefix={'tooltipSiesaLog'}
        message={responseMensaje}>
        <CodeBracketSquareIcon
          onClick={() => {
            handleSiesaClick(row, resendSiesaFD, responseCode !== 0, handleShowSiesaLog);
          }}
          data-tip
          data-for={`tooltipSiesaLog${row.SOLICITUD_ID}`}
          className={`hover:fill-blue-500 h-5 w-5 transform cursor-pointer ${color} transition duration-300 hover:scale-110`}
        />
      </ToolTipWrap>
    );
  }

  if (!moment(row.SIESA_ESTADO_FECHA).isValid()) {
    console.log('row: ', row);
    return;
  }

  const responseCode = JSON.parse(row.SIESA_ESTADO).codigo;
  const responseMensaje = JSON.parse(row.SIESA_ESTADO).mensaje;

  const color = responseCode === 0 ? 'text-green-500' : 'text-red-500';

  return (
    <ToolTipWrap
      itemID={row.SOLICITUD_ID}
      componentPrefix={'tooltipSiesaLog'}
      message={responseMensaje}>
      <CodeBracketSquareIcon
        onClick={() => {
          handleSiesaClick(row, resendSiesaFD, responseCode !== 0, handleShowSiesaLog);
        }}
        data-tip
        data-for={`tooltipSiesaLog${row.SOLICITUD_ID}`}
        className={`hover:fill-blue-500 h-5 w-5 transform cursor-pointer ${color} transition duration-300 hover:scale-110`}
      />
    </ToolTipWrap>
  );
};
const SiesaLogRNDCIcon = ({ row, resendSiesaRNDC, handleShowSiesaLogRNDC }) => {
  if (row['SOLICITUD_APROBADA'] < 6) return;

  const isAdmin = CheckPermission('Coordinador') || CheckPermission('Administrador');

  if (!isAdmin) return;

  if (!moment(row.SIESA_RNDC_ESTADO_FECHA).isValid()) {
    return (
      <CodeBracketSquareIcon
        onClick={() => {
          handleSiesaClick(row, resendSiesaRNDC, true, handleShowSiesaLogRNDC);
        }}
        className={`hover:fill-blue-500 h-5 w-5 transform cursor-pointer  transition duration-300 hover:scale-110`}
      />
    );
  }

  const responseCode = JSON.parse(row.SIESA_RNDC_ESTADO).codigo;

  const color = responseCode === 0 ? 'text-green-500' : 'text-red-500';

  return (
    <ToolTipWrap
      itemID={row.SOLICITUD_ID}
      componentPrefix={'tooltipSiesaLogRNDC'}
      message={responseCode}>
      <CodeBracketSquareIcon
        onClick={() => {
          handleSiesaClick(row, resendSiesaRNDC, responseCode !== 0, handleShowSiesaLogRNDC);
        }}
        data-tip
        data-for={`tooltipSiesaLogRNDC${row.SOLICITUD_ID}`}
        className={`hover:fill-blue-500 h-5 w-5 transform cursor-pointer ${color} transition duration-300 hover:scale-110`}
      />
    </ToolTipWrap>
  );
};

const handleSiesaClick = (row, resendSiesaFD, isSendFail, handleShowSiesaLog) => {
  handleShowSiesaLog(row, isSendFail, resendSiesaFD);
};

const getReporteTrabajo = (row) => {
  if (Number(row.ORDEN_OPERACION_CONSECUTIVO) > 0) {
    return ` (${row.ORDEN_OPERACION_CONSECUTIVO})`;
  }
  return '';
};
const getPrefactura = (row) => {
  if (Number(row.PREFACTURA_CONSECUTIVO) > 0) {
    return ` (${row.PREFACTURA_CONSECUTIVO})`;
  }
  return '';
};

const ServiciosTbody = (props) => {
  const { aprobarSolicitud, getServicioLogs, getDocumentoPdf, finalizarSolicitud, getDocumentoFinalPdf, resendSiesaRNDC, resendSiesaFD, sinAnticipo } =
    props.endPoint;
  const { estadoAprobado } = props.data;
  const { actions } = props;
  const {
    setModalSeleccionarManifestoOReporte,
    setModalConfirmar,
    setModalEjecucion,
    setModalLogs,
    setIsModalEvaluacionVisible,
    setModalReporte,
    setModalManifiesto,
    setIsModalNuevoAnticipoVisible,
    setModalRechazarSolicitud,
  } = props.modals;
  const { handleEdit, handleReject } = props.actions;

  const { solicitud, auth } = props.data;

  const TERCERO_ID = auth.data.user.TERCERO_ID;

  const handleConfirmarTipoVehiculo = (objTipoVehiculo, objSolicitud) => {
    if (estadoAprobado !== 1) return;
    store.dispatch(setSelTipoVehiculo(objTipoVehiculo));
    store.dispatch(setSelSolicitud(objSolicitud));
    setModalConfirmar(true);
  };
  const handleNuevoAnticipo = (objTipoVehiculo, objSolicitud) => {
    // if (estadoAprobado !== 1) return;

    store.dispatch(setSelTipoVehiculo(objTipoVehiculo));
    store.dispatch(setSelSolicitud(objSolicitud));
    setIsModalNuevoAnticipoVisible(true);
  };
  const handleReporteTrabajoTipoVehiculo = (objTipoVehiculo, objSolicitud) => {
    if (estadoAprobado < 4) return;

    const hasSolicitudReporte = objSolicitud.SOLICITUD_REPORTE_ESTADO === 1;
    const hasVehiculoReporte = objTipoVehiculo.SOLICITUD_VEHICULO_REPORTE_ESTADO === 1;

    if (hasVehiculoReporte && hasSolicitudReporte) {
      getDocumentoPdf(objSolicitud);
      return;
    }

    if (hasVehiculoReporte && !hasSolicitudReporte) {
      ErrorToast('Error: ', 'Faltan vehículos por confirmar');
      return;
    }

    store.dispatch(setSelTipoVehiculo(objTipoVehiculo));
    store.dispatch(setSelSolicitud(objSolicitud));

    setModalReporte(true);
  };
  const handleReporteFinal = (objTipoVehiculo, objSolicitud) => {
    if (estadoAprobado < 6) return;

    getDocumentoFinalPdf(objSolicitud);
  };
  const handleSelTipoVehiculoRf = (objTipoVehiculo, objSolicitud) => {
    store.dispatch(setSelTipoVehiculo(objTipoVehiculo));
    store.dispatch(setSelSolicitud(objSolicitud));
    if (estadoAprobado === 1) return setModalConfirmar(true);
    setModalSeleccionarManifestoOReporte(true);
  };

  const handleSelTipoVehiculoEjecucion = (objSolicitud) => {
    store.dispatch(setSelSolicitud(objSolicitud));
    setModalEjecucion(true);
  };

  const handleModalHistorico = (objSolicitud) => {
    getServicioLogs(objSolicitud);
    store.dispatch(setSelSolicitud(objSolicitud));
    setModalLogs(true);
  };

  const handleNoAnticipo = (itemData) => {
    const isTieneAnticipo = Number(itemData.SOLICITUD_ANTICIPO_ESTADO) !== 0;

    const isTieneReporte = Number(itemData.SOLICITUD_REPORTE_ESTADO) === 1;

    if (isTieneAnticipo) {
      ErrorToast('Error: ', 'Ya tiene anticipo...');
      return;
    }

    if (!isTieneReporte) {
      ErrorToast('Error: ', 'Sin reporte...');
      return;
    }

    sinAnticipo(itemData);
  };

  const handleEvaluacion = (objSolicitud) => {
    store.dispatch(setSelSolicitud(objSolicitud));
    setIsModalEvaluacionVisible(true);
  };

  const handleManifiesto = (objTipoVehiculo, objSolicitud, action) => {
    //ErrorToast({ title: 'Error', message: error });

    if (objTipoVehiculo.SOLICITUD_VEHICULO_REPORTE_ESTADO === 0) {
      ErrorToast('Error: ', 'Sin reporte de trabajo...');
      return;
    }

    const withAction = {
      ...objTipoVehiculo,
      MANIFIESTO_ESTADO_ACC_ID: action.MANIFIESTO_ESTADO_ACC_ID,
      MANIFIESTO_ESTADO_ACC_FIN: action.MANIFIESTO_ESTADO_ACC_FIN,
      MANIFIESTO_ESTADO_ACC_DESC: action.MANIFIESTO_ESTADO_ACC_DESC,
    };

    store.dispatch(setSelTipoVehiculo(withAction));
    store.dispatch(setSelSolicitud(objSolicitud));

    setModalManifiesto(true);
  };

  const ReporteTrabajoIcon = ({ itemData, solicitudEstado, solicitud }) => {
    if (solicitudEstado < solicitudEstados['Confirmada']) return;
    // console.log('itemData.SOLICITUD_VEHICULO_REPORTE_ESTADO: ', itemData.SOLICITUD_VEHICULO_REPORTE_ESTADO);

    if (itemData.SOLICITUD_VEHICULO_REPORTE_ESTADO === 0)
      return (
        <ToolTipWrap
          itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
          componentPrefix={'tooltipReporteTrabajo1'}
          message={itemData.SOLICITUD_OT_ESTADO_DESCRIPCION}>
          <WrenchScrewdriverIcon
            data-tip
            data-for={`tooltipReporteTrabajo1${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
            className="hover:fill-blue-500 h-5 w-5 transform cursor-pointer  text-yellow-500 transition  duration-200 hover:scale-110"
            onClick={() => handleReporteTrabajoTipoVehiculo(itemData, solicitud)}
          />
        </ToolTipWrap>
      );

    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'tooltipReporteTrabajo'}
        message={'Ver Orden de operación...'}>
        <ClipboardDocumentListIcon
          data-tip
          data-for={`tooltipReporteTrabajo${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
          className="hover:fill-blue-500 h-5 w-5 transform cursor-pointer text-green-500 transition  duration-200 hover:scale-110"
          onClick={() => handleReporteTrabajoTipoVehiculo(itemData, solicitud)}
        />
      </ToolTipWrap>
    );
  };

  const canStartExecution = (obj) => {
    if (obj.SOLICITUD_REPORTE_ESTADO === 0) {
      ErrorToast('Error: ', 'Sin reporte de trabajo...');
      return false;
    }
    if (obj.SOLICITUD_ANTICIPO_ESTADO === 0) {
      ErrorToast('Error: ', 'Sin anticipo...');
      return false;
    }

    return true;
  };

  const onClickMenu = (obj, e) => {
    //Seguimiento
    console.log('e.SOLICITUD_ESTADO_ACC_ID: ', e.SOLICITUD_ESTADO_ACC_ID);

    //EVALUACION DE LA SOLICITUD.
    if (e.SOLICITUD_ESTADO_ACC_ID === 6) {
      handleEvaluacion(obj);
    }

    if (e.SOLICITUD_ESTADO_ACC_ID === 7) {
      handleSelTipoVehiculoEjecucion(obj);
      return;
    }

    //Iniciar ejecución
    if (e.SOLICITUD_ESTADO_ACC_ID === 4) {
      if (canStartExecution(obj)) {
        aprobarSolicitud(obj.SOLICITUD_ID, 5);
        return;
      }
    }

    // Finalizar ejecución
    if (e.SOLICITUD_ESTADO_ACC_ID === 5) {
      finalizarSolicitud(obj.SOLICITUD_ID, 6);
    }
  };

  const TipoVehiculoCell = ({ itemData: obj }) => {
    if (obj.solicitud_vehiculo_tipo === undefined) {
      return <td className={``}>...</td>;
    }
    const isAdmin = CheckPermission('Coordinador') || CheckPermission('Administrador');

    return (
      <td className="whitespace-nowrap">
        {obj.solicitud_vehiculo_tipo.map((tipo) => (
          <div
            className="flex gap-2"
            key={tipo.SOLICITUD_VEHICULO_TIPO_ID}>
            <AnticipoIcon
              itemData={tipo}
              solicitudEstado={estadoAprobado}
              solicitud={obj}
              handleConfirmarTipoVehiculo={handleConfirmarTipoVehiculo}
            />
            <NuevoAnticipoIcon
              itemData={tipo}
              solicitudEstado={estadoAprobado}
              solicitud={obj}
              handleNuevoAnticipo={handleNuevoAnticipo}
            />
            {isAdmin && (
              <ConfirmarIcon
                itemData={tipo}
                solicitudEstado={estadoAprobado}
                solicitud={obj}
                handleConfirmarTipoVehiculo={handleConfirmarTipoVehiculo}
              />
            )}
            {isAdmin && (
              <ReporteTrabajoIcon
                itemData={tipo}
                solicitudEstado={estadoAprobado}
                solicitud={obj}
              />
            )}
            {isAdmin && (
              <ReporteFinalIcon
                itemData={tipo}
                solicitudEstado={estadoAprobado}
                solicitud={obj}
                callback={handleReporteFinal}
              />
            )}
            {isAdmin && (estadoAprobado === 5 || estadoAprobado === 4) && (
              <div>
                <Menu
                  menuButton={
                    <MenuButton>
                      <span>
                        <ManifiestoIcon
                          itemData={tipo}
                          solicitudEstado={estadoAprobado}
                          solicitud={obj}
                        />
                      </span>
                    </MenuButton>
                  }
                  transition>
                  {tipo.manifiesto_menu.length === 0 ? (
                    <MenuItem
                      key={`mi_m_${tipo.SOLICITUD_VEHICULO_TIPO_ID}_na`}
                      disabled>
                      Sin Acciones...
                    </MenuItem>
                  ) : (
                    tipo.manifiesto_menu.map((b) => (
                      <MenuItem
                        key={`mi_m_${tipo.SOLICITUD_VEHICULO_TIPO_ID}${b.MANIFIESTO_ESTADO_ACC_ID}`}
                        onClick={() => handleManifiesto(tipo, obj, b)}>
                        {b.MANIFIESTO_ESTADO_ACC_DESC}
                      </MenuItem>
                    ))
                  )}
                </Menu>
              </div>
            )}
            {isAdmin && estadoAprobado === 6 && (
              <div>
                <ManifiestoIcon
                  itemData={tipo}
                  solicitudEstado={estadoAprobado}
                  solicitud={obj}
                />
              </div>
            )}
            {tipo.VEHICULO_TIPO_DESCRIPCION}
            <p className=" text-green-500">{tipo.VEHICULO_PLACA}</p>
            <p className=" ">
              <strong>{tipo.V_VEHICULO_PROPIO === 0 ? '(Ext)' : ''} </strong>
            </p>
          </div>
        ))}
      </td>
    );
  };

  const { handleShowSiesaLog, handleShowSiesaLogRNDC } = actions;

  return (
    <tbody className="">
      {solicitud.data?.map((obj) => (
        <tr
          key={obj.SOLICITUD_ID}
          className="border-b even:bg-gray-50">
          <td className="w-2 ">
            <Menu
              menuButton={
                <MenuButton>
                  <EllipsisVerticalIcon className="h-5 w-5 cursor-pointer"></EllipsisVerticalIcon>
                </MenuButton>
              }
              transition>
              <MenuItem>Cambios del Servicio</MenuItem>
              <MenuItem onClick={() => handleEdit(obj)}>Clonar</MenuItem>
              <MenuItem onClick={() => handleReject(obj)}>Rechazar</MenuItem>
              <MenuItem onClick={() => handleModalHistorico(obj)}>Ver Historico</MenuItem>
              <MenuDivider />
              {obj.SOLICITUD_APROBADA === 4 ? (
                <>
                  <MenuItem onClick={() => handleNoAnticipo(obj)}>No Req. Anticipo</MenuItem>
                  <MenuDivider />
                </>
              ) : (
                ''
              )}

              {obj.solicitud_menu.map((e) => (
                <MenuItem
                  key={`mi_sm_${e.SOLICITUD_ID}${e.SOLICITUD_ESTADO_ACC_DESC}`}
                  onClick={() => onClickMenu(obj, e)}>
                  {e.SOLICITUD_ESTADO_ACC_DESC}
                </MenuItem>
              ))}
            </Menu>
          </td>
          <td className="  whitespace-nowrap  text-left">
            {obj.SOLICITUD_CONSECUTIVO_INT}
            <strong>
              {getReporteTrabajo(obj)}
              {getPrefactura(obj)}
            </strong>
          </td>

          <td className=" px-4  text-left">{obj.SOLICITUD_CONSECUTIVO}</td>

          <td className="  text-left">{obj.SOLICITUD_CC}</td>
          {/* <td className="px-4 py-3 text-left">{obj.SOLICITUD_AFE}</td> */}

          {/* <td className="px-4 py-3 text-left">{`${obj.SOLICITUD_IZAJE === 1 ? 'Izaje' : ''} ${obj.SOLICITUD_TRANSPORTE === 1 ? '- Transporte' : ''}`}</td> */}
          <td className=" ">{obj.unidad_negocio[0]?.NEGOCIO_NOMBRE}</td>
          <TipoVehiculoCell itemData={obj} />
          <td className=" ">
            {obj.ORIGEN} - {obj.DESTINO}
          </td>
          {/* <td className=" ">{obj.DESTINO}</td> */}
          <td className=" whitespace-nowrap ">{obj.SOLICITUD_FECHA}</td>
          <td className="whitespace-nowrap  px-4 ">{obj.CREATED_AT}</td>
          <td className=" ">{obj.CONTRATO_ZONA}</td>
          {CheckParameter('PARAMETRO_ID', 2) ? <td className="">{obj.APRUEBA}</td> : null}
          <td className=" ">
            <DocumentIcon
              data-tip
              data-for={`tooltipDescripcion${obj.SOLICITUD_ID}`}
              className="hover:fill-blue-500 h-5 w-5 transform  cursor-pointer transition  duration-200 hover:scale-110"
              // onClick={() => handleSelTipoVehiculo(obj, solicitud)}
            />
            <ReactTooltip
              className="margin-10"
              id={`tooltipDescripcion${obj.SOLICITUD_ID}`}
              type="dark">
              <div>
                <p>{obj.SOLICITUD_DESCRIPCION}</p>
              </div>
            </ReactTooltip>
          </td>
          <td>
            <SiesaLogIcon
              row={obj}
              resendSiesaFD={resendSiesaFD}
              handleShowSiesaLog={handleShowSiesaLog}
            />
          </td>
          <td>
            <SiesaLogRNDCIcon
              row={obj}
              resendSiesaRNDC={resendSiesaRNDC}
              handleShowSiesaLogRNDC={handleShowSiesaLogRNDC}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default ServiciosTbody;
