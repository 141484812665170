import React from 'react';
import TbodyTableZona from './tbody';
import TheadTableZona from './thead';
const TableZona = (props) => {
  const { loadingTables } = props.data;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTableZona />
          <TbodyTableZona actions={props.actions} />
        </table>
      )}
    </div>
  );
};
export default TableZona;
