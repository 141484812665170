import React, { useState, useEffect } from 'react';
import { ButtonTable } from '../../../../global/Styles/buttons';

import { connect } from 'react-redux';

import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast, OkToast } from '../../../Toast';

import { ModalTitle } from '../../../../global/Styles/titles';
import toast from 'react-hot-toast';

import IndicatorServicioEstado from './IndicatorServicioEstado';

import TableManifiesto from '../TableManifiesto';
import { TableVehiculos } from './TableVehiculos';
import { PanelVehiculo } from './PanelVehiculo';

const ModalEjecucion = (props) => {
  const { setModalEjecucion } = props;

  const { getAsignarBono, getSolicitudDetalle } = props.endPoint;
  const { handleEditBonoAsignado, handleDeleteBonoAsignado, setBonoAsignado, handleChangeTarifaUnidad, createSolicitudDetalle, handleDeleteDetalle } =
    props.actions;

  // const [activeTab, setActiveTab] = useState('novedad');

  const [getEstadoData, setGetEstadoData] = useState({});
  const [vehiculoGPS, setVehiculoGPS] = useState({});

  const [vehiculoUsuarioOptions, setVehiculoUsuarioOptions] = useState({});

  const [itemUsuario, setItemUsuario] = useState({});

  const [selSolicitudVehiculoTipo, setSelSolicitudVehiculoTipo] = useState('');

  const { post, get, put } = useApiClient();

  const getEstado = async () => {
    const response = await get(`/solicitud/${props.Solicitud?.selSolicitud?.SOLICITUD_ID}`);

    setGetEstadoData(response.data);
  };

  const getVehiculoGPS = async (placa) => {
    const response = await get(`/vehiculoGPS/${placa}`);

    if (!validateSuccess2xx(response)) return false;

    window.open(response.link, '_blank');

    setVehiculoGPS(response);
  };

  const estado = async (id) => {
    try {
      const result = await put(
        `/solicitud/${props.Solicitud?.selSolicitud?.SOLICITUD_ID}?EDITAR_SOLO_ESTADO=1`,
        {
          json: JSON.stringify({
            ESTADO_ID: id,
          }),
        },
        'application/json'
      );
      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'solicitud Agregado!' });
        getEstado();
      }
    } catch (error) {}
  };

  const handleVehiculoSelect = (vehiculoTipo) => {
    loadTipoVehiculoUsuarioOptions(vehiculoTipo);
    setSelSolicitudVehiculoTipo(vehiculoTipo);
  };

  const handleClickEstado = (id) => {
    toast((t) => (
      <span>
        <b>Seguro desea cambiar el estado?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            estado(id);
          }}>
          Sí
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  };

  const handleClick = () => {
    setBonoAsignado([]);
    setModalEjecucion(false);
  };
  const handleGPS = (placa) => {
    getVehiculoGPS(placa);
  };

  const handleAddTarifa = (SOLICITUD_VEHICULO_TIPO_ID, TARIFA_ID, SOLICITUD_DETALLE_CANTIDAD) => {
    const frm = {
      SOLICITUD_VEHICULO_TIPO_ID: SOLICITUD_VEHICULO_TIPO_ID,
      TARIFA_ID: TARIFA_ID,
      SOLICITUD_DETALLE_CANTIDAD: SOLICITUD_DETALLE_CANTIDAD,
    };

    createSolicitudDetalle(frm);
  };

  const setNOVEDAD_TIPO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['NOVEDAD_TIPO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const loadTipoVehiculoUsuarioOptions = (vehiculoTipo) => {
    const items = [
      ...(vehiculoTipo.CONDUCTOR_TERCERO_ID
        ? [
            {
              label: vehiculoTipo.CONDUCTOR_NOMBRE,
              value: vehiculoTipo.CONDUCTOR_TERCERO_ID,
              rolID: 7,
            },
          ]
        : []),
      ...(vehiculoTipo.APAREJADOR_TERCERO_ID
        ? [
            {
              label: vehiculoTipo.APAREJADOR_NOMBRE,
              value: vehiculoTipo.APAREJADOR_TERCERO_ID,
              rolID: 14,
            },
          ]
        : []),
    ];

    setVehiculoUsuarioOptions(items);
  };

  const onSelectUser = (e) => {
    setItemUsuario(e);
    getAsignarBono(e.value, props.data.solicitud.selSolicitud.SOLICITUD_ID);
  };

  useEffect(() => {
    getEstado();
    // usuarios();
  }, []);

  const onTarifaTap = () => {
    console.log('onTarifaTap: ', onTarifaTap);
    getSolicitudDetalle(props.tipoVehiculo.selTipoVehiculo?.pivot?.SOLICITUD_VEHICULO_TIPO_ID);
    // setActiveTab('tarifa');
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>SEGUIMIENTO - SERVICIO</ModalTitle>
        <TableManifiesto />

        <div className="w-full">
          <IndicatorServicioEstado
            endPoint={{ handleClickEstado }}
            data={{ getEstadoData }}
          />
        </div>

        <div className="w-full">
          <TableVehiculos
            actions={{ handleGPS, setItemUsuario, handleVehiculoSelect }}
            data={{
              solicitudVehiculoTipos: props.Solicitud.selSolicitud.solicitud_vehiculo_tipo,
              selSolicitudVehiculoTipo,
            }}
          />
        </div>
        {selSolicitudVehiculoTipo !== '' && (
          <PanelVehiculo
            handleAddTarifa={handleAddTarifa}
            handleDeleteBonoAsignado={handleDeleteBonoAsignado}
            handleEditBonoAsignado={handleEditBonoAsignado}
            onSelectUser={onSelectUser}
            usuario={setVehiculoUsuarioOptions}
            onTarifaTap={onTarifaTap}
            setNOVEDAD_TIPO_ID={setNOVEDAD_TIPO_ID}
            handleChangeTarifaUnidad={handleChangeTarifaUnidad}
            handleDeleteDetalle={handleDeleteDetalle}
            data={{ ...props.data, selSolicitudVehiculoTipo, vehiculoUsuarioOptions }}
          />
        )}

        <div className="w-full space-x-4">
          <div className="md:block mx-16 mt-2 space-x-4 text-center">
            <ButtonTable onClick={handleClick}>Cerrar</ButtonTable>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    Solicitud: state.Solicitud,
    tipoVehiculo: state.tipoVehiculo,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ModalEjecucion);
