import React from 'react';
import TbodyTableVehiculo from './tbody';
import { TheadTableVehiculo } from './thead';

const TableVehiculo = (props) => {
  const { loadingTables } = props.data;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTableVehiculo />
          <TbodyTableVehiculo actions={props.actions} />
        </table>
      )}
    </div>
  );
};
export default TableVehiculo;
