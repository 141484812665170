import moment from 'moment';
import { DateTime } from 'luxon';

export const removeNonNumber = (string = '') => String(string).replace(/[^\d.-]/g, '');

export const formatMoney = (value, format = 'en-US') => {
  const cleanedValue = removeNonNumber(value);
  if (cleanedValue === undefined || cleanedValue === '') {
    return formatMoney('0');
  }
  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const formatMoneyNDS = (value) => formatMoney(value).replace('$', '');

export const formatCurrency = (value, format = 'en-US') => {
  if (value === '' || value === undefined) {
    return value;
  }

  const stringValue = String(value);

  if (!stringValue.match(/\d/g)) {
    return '';
  }
  const hasDecimal = stringValue.match(/\./);
  const [int, decimals] = stringValue.split('.');

  const formattedInt = new Intl.NumberFormat(format).format(int || stringValue);

  if (!int || !hasDecimal) {
    return formattedInt;
  }
  return decimals ? new Intl.NumberFormat(format, { maximumFractionDigits: 2 }).format(`${int || stringValue}.${decimals}`) : `${formattedInt}.`;
};

export const setDecimals = (value, numDecimals) => {
  if (numDecimals === undefined || value === '' || value === undefined) {
    return value;
  }

  if (!value.match(/\d/g)) {
    return '';
  }

  const [int, decimals] = value.split('.');

  if (numDecimals === 0) {
    return int;
  }

  let newValue = decimals || '';

  if (newValue.length < numDecimals) {
    while (newValue.length < numDecimals) {
      newValue += '0';
    }
  } else {
    newValue = newValue.slice(0, numDecimals);
  }

  return `${int}.${newValue}`;
};

export const round = (num) => Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;

export const roundWithDecimals = (num, decimals) => parseFloat(round(num)).toFixed(decimals);

export const formatDate = (dateString) =>
  dateString && typeof dateString === 'string'
    ? new Intl.DateTimeFormat('en-US', {
        dateStyle: 'long',
      }).format(DateTime.fromSQL(dateString))
    : dateString;

export const formatFloat = (val) => parseFloat(val) || 0;
export const formatMoment = (val) => (typeof val === 'string' && val ? moment(val) : val);

const checkForObject = (val) => typeof val === 'object' && !Array.isArray(val) && val !== null;

export const parseObj = (objToParse, parsers) => {
  const parserFunctions = {
    toFloat: formatFloat,
    toMoment: formatMoment,
  };
  if (Array.isArray(objToParse)) {
    return objToParse.map((objItem) => parseObj(objItem, parsers));
  }
  return Object.fromEntries(
    Object.entries(objToParse).map(([key, val]) => {
      let fieldValue = val;
      if (checkForObject(fieldValue)) {
        fieldValue = parseObj(fieldValue, parsers);
        return [key, fieldValue];
      }
      if (Array.isArray(fieldValue) && checkForObject(fieldValue[0])) {
        fieldValue = fieldValue.map((fieltItem) => parseObj(fieltItem, parsers));
        return [key, fieldValue];
      }
      const parser = Object.keys(parsers).find((parserKey) => parsers[parserKey].includes(key));
      if (parser) {
        if (Array.isArray(fieldValue)) {
          return [key, fieldValue.map((fieldItem) => parserFunctions[parser](fieldItem))];
        }
        fieldValue = parserFunctions[parser](fieldValue);
      }

      return [key, fieldValue];
    })
  );
};

export const toTitleCase = (s) => s.substr(0, 1).toUpperCase() + s.substr(1);

// to send date for backend.
export const fixDateForBE = (dateToFix) => {
  const date = new moment(dateToFix);
  return date.format('YYYY-MM-DD hh:mm:ss');
};

export const fixOnlyDate = (dateToFix) => {
  const date = new moment(dateToFix);
  return date.format('YYYY-MM-DD');
};

export const fixCurrentDate = (dateToFix = new Date()) => {
  const date = new moment(dateToFix);
  return date.format('YYYY-MM-DD');
};

export const moneyFormat = (value) => {
  return Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value);
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email));
};
export const validateEmails = (emails = '') => {
  emails = emails.split(/[;,]/).map((x) => x.trim().toLocaleLowerCase());
  if (emails.length !== new Set(emails).size) return false;
  return emails.every(validateEmail);
};

export const emptyNull = (val) => {
  if (val == null) {
    return '';
  }
  return val;
};
function decodeHtmlCharCodes(str) {
  return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
    return String.fromCharCode(charCode);
  });
}

export const fixOnlyDateForTable = (dateToFix) => {
  if (dateToFix === null) {
    return '...';
  }

  const date = new moment(dateToFix);

  //non-breaking hyphen
  return decodeHtmlCharCodes(date.format('YYYY-MM-DD').replaceAll('-', '&#8209;'));
};

const matched = (x) => ({
  on: () => matched(x),
  otherwise: () => x,
});

export const functionalSwitch = (x) => ({
  on: (pred, fn) => (pred(x) ? matched(fn(x)) : functionalSwitch(x)),
  otherwise: (fn) => fn(x),
});

export const undefinedToEmptyString = (obj) => {
  return obj === undefined ? '' : obj;
};
export const formatterPeso = (obj) => {
  const formatCOP = (value) =>
    new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
    }).format(value);

  const r = Number.parseFloat(obj).toFixed(2);
  if (isNaN(r)) {
    return formatCOP(0);
  }

  return formatCOP(r);
};

export const getFormatedNumberFromInput = (value) => {
  const firstChar = value.slice(0, 1);

  const lastChar = value.slice(-1);

  if (firstChar === '$' && lastChar === '.') {
    const hasComma = value.indexOf(',') > -1;

    if (hasComma) return value.slice(0, -1);

    return value.slice(0, -1) + ',';
  }
  if (firstChar === '$' && lastChar === ',') return value.slice(0, -1);

  if (firstChar === '$') {
    const withOutFormat = removeMoneyFormat(value);
    return formatterPeso(withOutFormat);
  }

  return formatterPeso(value);
};

export const removeMoneyFormat = (value) => {
  return Number(('' + value).replace(/[$. ]/g, '').replace(',', '.'));
};
