const initialState = {
  isData: false,
  data: [],
  selSolicitud: null,
  selSolicitudLog: null,
  pagination: null,
};

const initialSolicitudState = {
  selSolicitud: null,
  selSolicitudLog: null,
};

export const Solicitud = (state = initialState, action) => {
  switch (action.type) {
    case 'setSolicitud': {
      // Check for good results!

      if (action.value.status === 200) {
        const stateData = { data: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setNuevaSolicitud': {
      // Check for good results!

      const nuevo = state.data;
      nuevo.push(action.value);

      const stateData = { data: nuevo, isData: true, pagination: action.value.pagination };
      return { ...state, ...stateData };
    }
    case 'setSolicitudInit': {
      return initialState;
    }

    case 'setSelSolicitud': {
      const obj = {
        ...action.value,
        // start_at: fixOnlyDate(action.value.start_at),
        // end_at: fixOnlyDate(action.value.end_at)
      };

      const stateData = { selSolicitud: obj };

      return { ...state, ...stateData };
    }
    case 'setSelSolicitudLog': {
      const stateData = { selSolicitudLog: action.value };

      return { ...state, ...stateData };
    }

    case 'setSelSolicitudInit': {
      return { ...state, ...initialSolicitudState };
    }

    default:
      //
      return state;
  }
};
