import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { moneyFormat } from '../../../../utils/format';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
const TbodyTableTarifas = (props) => {
  const infoTarifa = props.tarifa.tarifa;
  const { handleEdit, handleDelete } = props.actions;

  useEffect(() => {}, []);

  return (
    <tbody className="text-gray-700">
      {infoTarifa.map((obj, index) => (
        <tr key={index}>
          <td className=" px-1 text-left">{obj.TARIFA_CONSECUTIVO}</td>
          <td className=" px-1 text-left">{obj.CONTRATO_ID}</td>
          <td className=" px-1 text-left">{obj.TARIFA_CATEGORIA}</td>
          <td className=" px-1 text-left">{obj.TARIFA_GRUPO}</td>
          <td className=" px-1 text-left">{obj.TARIFA_UNIDAD}</td>
          <td className=" px-1 text-left">{obj.TARIFA_ITEM}</td>
          <td className=" px-1 text-left">{obj.TARIFA_NOMBRE}</td>
          {/* <td className=" px-1 text-right">{obj.TARIFA_MONEDA}</td> */}
          <td className=" px-1 text-right">{moneyFormat(obj.TARIFA_VALOR)} </td>
          <td className=" px-1 text-right">{obj.F470_ID_ITEM} </td>
          <td className=" px-1 text-right">{obj.F470_ID_UN_MOVTO} </td>

          <td className=" ">
            <PencilIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleEdit(obj)}
            />
          </td>
          <td className=" ">
            <TrashIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleDelete(obj.TARIFA_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { tarifa: state.tarifa };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableTarifas);
