import React, { useState } from 'react';

import ViewParametroGeneral from './ViewParametroGeneral';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { authSetSelCliente } from '../../actions';
import { store } from '../../store';
import { useApiClient, validateSuccess2xx } from '../../clients/apiAuth';
import { CustomToast, OkToast } from '../../components/specific/Toast';

const ParametroGeneral = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalAgregarParametroGeneralVisible, setIsModalAgregarParametroGeneralVisible] = useState(false);

  const [parametroData, setParametroData] = useState([]);
  const [selectedParametroData, setSelectedParametroData] = useState({});

  const { get, post, put, DELETE } = useApiClient();

  const handleEdit = (item) => {
    setSelectedParametroData(item);
    setIsModalAgregarParametroGeneralVisible(true);
  };

  const updateParametroGeneral = async (form) => {
    const result = await put(
      `/parametro/${form.PARAMETRO_ID}`,
      {
        json: JSON.stringify(form),
      },
      'application/json'
    );

    if (!validateSuccess2xx(result)) return CustomToast({ result });

    setIsModalAgregarParametroGeneralVisible(false);

    OkToast({ result, message: 'Parametro actualizado!' });

    getParametros();
  };

  const getParametros = async () => {
    setIsLoading(true);
    const response = await get(`/parametro?PARAMETRO_CLIENTE=0`);
    console.log('response: ', response);

    setParametroData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getParametros();
  }, []);

  return (
    <ViewParametroGeneral
      data={{ parametroData, isLoading, selectedParametroData }}
      modal={{ isModalAgregarParametroGeneralVisible, setIsModalAgregarParametroGeneralVisible }}
      actions={{ handleEdit }}
      endPoint={{ updateParametroGeneral }}
    />
  );
};

const mapStateToProps = function (state) {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(ParametroGeneral);
