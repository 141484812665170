import React from 'react';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Lugar/Filter';
import TableLugar from '../../../components/specific/Lugar/Table';
import PageHeading from '../../../components/global/PageHeading';
import { ButtonTable } from '../../../components/global/Styles/buttons';
import { TitlesTables } from '../../../components/global/Styles/titles';

const ViewLugar = (props) => {
  const { loadingTables, handleDepurar } = props.actions;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'bg-gray-500 p-2 rounded-md text-white font-bold text-sm ',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>LUGARES - TARIFAS</TitlesTables>
            <div className="flex-auto"></div>
            <Filter
              endPoint={props.endPoint}
              data={props.data}
            />
            <ButtonTable onClick={handleDepurar}>Depurar</ButtonTable>
          </div>

          {loadingTables && <LoadingTables />}
          <TableLugar
            actions={props.actions}
            data={props.data}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewLugar;
