import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import Select from 'react-select';

const Filter = (props) => {
  const { contrato, setFilterContrato } = props.data;

  const handleSubmmit = () => {};

  const setCONTRATO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONTRATO_ID'];
    field.change(args[0].value);

    state.formState.submitFailed = true;

    //CAMBIA EL FILTRO.
    setFilterContrato(args[0].value);
  };

  const contratoOptions = contrato.contrato.map((row) => ({
    label: `${row.CONTRATO_NUMERO}`,
    value: row.CONTRATO_ID,
  }));

  return (
    <Form
      onSubmit={handleSubmmit}
      initialValues={{}}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ setCONTRATO_ID }}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <FormStateToRedux form="frmTaskFilters" />

          <div className="pr-2">
            <div className="flex items-center gap-2 ">
              <h1 className="">Contrato</h1>

              <Field name="CONTRATO_ID">
                {({ input, meta }) => (
                  <>
                    {contratoOptions.length > 0 && (
                      <Select
                        options={contratoOptions}
                        defaultValue={contratoOptions[0]}
                        name="selectNovedadTipoOptions"
                        onChange={form.mutators.setCONTRATO_ID}
                        menuPortalTarget={document.body}
                        styles={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                            ...styles,
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: '20',
                          }),
                        }}
                      />
                    )}
                    <input
                      {...input}
                      type="hidden"
                    />
                  </>
                )}
              </Field>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
export default Filter;
