import { useCallback, useEffect, useState } from 'react';
import { validateSuccess2xx } from '../../../clients/apiAuth';
import { ApiZonaRepository } from '../infrastructure/ApiZonaRepository';

export const useZones = ({ filters, body }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const repository = ApiZonaRepository.Repository();

  const getData = useCallback(async () => {
    setIsLoading(true);
    setData({});

    const result = await repository.getFilter(filters);

    if (validateSuccess2xx(result)) setData(result);

    setIsLoading(false);
  }, [filters, repository]);

  const remove = async (recordID) => {
    setIsLoading(true);

    const result = await repository.remove(recordID);

    if (validateSuccess2xx(result)) {
      getData();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (filters.length > 0) {
      getData();
    }
  }, [filters]);

  return { data, isLoading, reload: getData, remove };
};
