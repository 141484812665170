import React from 'react';
import { ReactComponent as StepOne } from '../../../../../components/assets/svg/stepOne.svg';
import { ReactComponent as StepTwo } from '../../../../../components/assets/svg/stepTwo.svg';

const trackStatus = {
  1: 'EN ALISTAMIENTO',
  2: 'CAMINO AL CLIENTE',
  3: 'EN LA BASE DEL CLIENTE',
  4: 'EN CARGUE',
  5: 'EN VIAJE',
  6: 'LLEGÓ AL DESTINO',
  7: 'DESCARGUE',
};
const IndicatorIcon = ({ estadoInfo, handleClickEstado, controlID }) => {
  const getFecha = {
    1: estadoInfo.SOLICITUD_EST_FEC1,
    4: estadoInfo.SOLICITUD_EST_FEC4,
    5: estadoInfo.SOLICITUD_EST_FEC5,
    6: estadoInfo.SOLICITUD_EST_FEC6,
    7: estadoInfo.SOLICITUD_EST_FEC7,
  };

  return (
    <div>
      <div className="relative mb-2">
        <div
          className={
            estadoInfo.estado_id >= controlID
              ? 'mx-auto flex h-10 w-10 items-center rounded-full bg-gray-800 text-lg text-white'
              : 'mx-auto flex h-10 w-10 cursor-pointer items-center rounded-full bg-white text-lg text-white'
          }
          onClick={() => handleClickEstado(controlID)}>
          <span className={estadoInfo.estado_id >= controlID ? 'w-full text-center text-white' : 'w-full text-center text-gray-600'}>
            <StepOne className="mx-2 h-6 w-6 fill-current" />
          </span>
        </div>
      </div>

      <div>
        <h1 className="md:text-base text-center text-xs">{trackStatus[controlID]} </h1>
        <h2 className="text-center text-xs ">{getFecha[controlID] ? getFecha[controlID] : ''} </h2>
      </div>
    </div>
  );
};

const IndicatorSeparator = ({ estadoInfo, controlID }) => {
  const color = estadoInfo.estado_id > controlID ? 'border-gray-500 bg-gray-500' : 'border-gray-200 bg-gray-200';
  return (
    <div className="flex flex-1 items-center justify-center px-2">
      <div className={`h-2 w-full rounded border-2 ${color}`}></div>
    </div>
  );
};
const IndicatorServicioEstado = (props) => {
  const { handleClickEstado } = props.endPoint;
  const { getEstadoData } = props.data;

  let estadoInfo = getEstadoData.estado_id;

  return (
    <div className="w-full  px-4 py-6">
      <div className="flex h-24 justify-around  ">
        <IndicatorIcon
          estadoInfo={getEstadoData}
          handleClickEstado={handleClickEstado}
          controlID={1}
        />
        <IndicatorSeparator
          estadoInfo={getEstadoData}
          controlID={1}
        />
        <IndicatorIcon
          estadoInfo={getEstadoData}
          handleClickEstado={handleClickEstado}
          controlID={4}
        />
        <IndicatorSeparator
          estadoInfo={getEstadoData}
          controlID={4}
        />
        <IndicatorIcon
          estadoInfo={getEstadoData}
          handleClickEstado={handleClickEstado}
          controlID={5}
        />
        <IndicatorSeparator
          estadoInfo={getEstadoData}
          controlID={5}
        />
        <IndicatorIcon
          estadoInfo={getEstadoData}
          handleClickEstado={handleClickEstado}
          controlID={6}
        />
        <IndicatorSeparator
          estadoInfo={getEstadoData}
          controlID={6}
        />
        <IndicatorIcon
          estadoInfo={getEstadoData}
          handleClickEstado={handleClickEstado}
          controlID={7}
        />
      </div>
    </div>
  );
};
export default IndicatorServicioEstado;
