import { parseObj } from '../utils/format';

import { store } from '../store';
import { CheckUnauthenticated } from '../utils/security';
import { checkAPI_URL } from '../utils/environment';

export const useApiClient = () => {
  const req = async (method, route, body, type = 'application/json', parsers = {}, hardToken = '') => {
    const storeState = store.getState();
    const token = storeState.auth.data === undefined ? '' : storeState.auth.data.token;

    const requestConfig = {
      method,
      headers: {
        Authorization: `Bearer ${hardToken !== '' ? hardToken : token}`,
      },
    };

    if (type === 'application/json') {
      requestConfig.headers['Content-Type'] = type;
      requestConfig.headers['Accept'] = type;
    }

    if (body && type === 'application/json') {
      requestConfig.body = JSON.stringify(body);
    } else if (body) {
      requestConfig.body = body;
    }
    // Routes will carry leading slash by convention to match Express declaration in API:
    const resp = await fetch(`${checkAPI_URL()}${route}`, requestConfig);
    let respBody;
    if (type === 'application/pdf') {
      respBody = await resp.blob();
    } else {
      respBody = await resp.json();
    }

    if (Object.keys(parsers).length === 0) {
      CheckUnauthenticated(respBody);
      return respBody;
    }

    const parsedResponse = parseObj(respBody, parsers);

    return parsedResponse;
  };

  const client = {
    get: async (route, type, body, parsers) => await req('GET', route, body, type, parsers),

    getToken: async (route, type, body, parsers, hardToken) => await req('GET', route, body, type, parsers, hardToken),

    post: async (route, body, type, parsers) => await req('POST', route, body, type, parsers),

    put: async (route, body) => req('PUT', route, body),

    patch: async (route, body) => req('PATCH', route, body),

    DELETE: async (route, body) => req('DELETE', route, body),
  };

  return client;
};

export const validateSuccess2xx = (result) => String(result.status).startsWith('2');

//Adds a change listener
// store.subscribe(useApiClient);
